import requested from 'assets/requested.svg';
import accepted from 'assets/accepted.svg';
import cancelled from 'assets/cancelled.svg';
import delivered from 'assets/delivered.svg';
import revision from 'assets/revision.svg';


export default [
  {
    name: 'En revisión',
    titleHex: '#3AA39C',
    bgHex: '#E7F8F7',
    icon: revision,
    key: 'revisión',
  },
  {
    name: 'Solicitadas',
    titleHex: '#DCB97A',
    bgHex: '#FBF9F2',
    icon: requested,
    key: 'solicitada',
  },
  {
    name: 'Aceptadas',
    titleHex: '#89B5CF',
    bgHex: '#F5FAFC',
    icon: accepted,
    key: 'aceptada',
  },
  {
    name: 'Entregadas',
    titleHex: '#6E9C7F',
    bgHex: '#F7FAF6',
    icon: delivered,
    key: 'entregada',
  },
  {
    name: 'Canceladas',
    titleHex: '#E99890',
    bgHex: '#FEF8F7',
    icon: cancelled,
    key: 'cancelada',
  },
 
];
