import React, { useEffect } from 'react';
import InputTag from 'components/Input';
import styles from './StyleRegister/step1.module.scss';
import SwipeDots from 'components/SwipeDots';

const Step1 = ({
  nextStep,
  restaurantName,
  restaurantIdentifier,
  handleRegisterChange,
  registerError,
  disableRegister1,
  validateRegister1,
  registerStep,
  toLogin,
  setRegisterStep,
}) => {
  useEffect(validateRegister1, [restaurantName, restaurantIdentifier]);

  return (
    <form className={styles.infoContainer} onSubmit={(e) => nextStep(e)}>
      <div className={styles.styleInfoContainer}>
        <div className={styles.headerContainer}>
          <p className={styles.authHeader}>Registro</p>
          <p className={styles.authText}>
            Por favor ingresa los datos de tu comercio.
          </p>
        </div>
        <div className={styles.inputContainer}>
          <div
            className={styles.individualInputContainer}
            data-error={registerError ? registerError.restaurantName : null}
          >
            <InputTag
              type="text"
              value={restaurantName}
              name="restaurantName"
              placeholder="Nombre Comercial"
              onChange={(e) => handleRegisterChange(e)}
              className={styles.input}
              required
            />
          </div>
          <div
            className={styles.individualInputContainer}
            data-error={registerError ? registerError.restaurantIdentifier : null}
          >
            <InputTag
              type="text"
              value={restaurantIdentifier}
              name="restaurantIdentifier"
              placeholder="RUC"
              onChange={(e) => handleRegisterChange(e)}
              className={styles.input}
              required
            />
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <SwipeDots registerStep={registerStep} />
        <input
          type="submit"
          value="Continuar"
          className={styles.submitAuth}
          disabled={disableRegister1}
        ></input>
        <div>
          <label className={styles.texttoLogin}>¿Ya tienes una cuenta?</label> &nbsp;
          <a className={styles.toLogin} onClick={(e) => toLogin(e)}>
            Iniciar Sesión
          </a>
        </div>
      </div>
    </form>
  );
};

export default Step1;
