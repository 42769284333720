import React, { useState } from 'react';
import { login, register, verify, logout, requestPass, confirmPass } from 'api/auth';
import { getUser } from 'api/user';
import { useNavigate } from 'react-router-dom';
import routes from '../router/routeList';
import toast from 'react-hot-toast';
import useStore from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { clearRestaurant, clearRestaurants, setRestaurantsC } from '../redux/slices/restaurantSlice';
import { auth, meStart } from '../redux/slices/authSlice/thunks';

import { useEffect } from 'react';
import { clearProducts } from '../redux/slices/productsSlice';
import { clearSuppliers } from '../redux/slices/suppliersSlice';
import { clearReports } from '../redux/slices/reportsSlice';
import { clearPrices } from '../redux/slices/preciosSlice';
import { clearParamsO } from '../redux/slices/paramsSlice';
import { clearOrders } from '../redux/slices/orderSlice';
import { clearDocs } from '../redux/slices/documentsSlice';
import { me } from '../redux/slices/authSlice/thunksV2';
import { clearAuthV2 } from '../redux/slices/authSlice/authSliceV2';



const useAuth = () => {
  const { token, user, restaurant } = useSelector((state) => state.auth)
  const store = useStore((state) => state);



  const dispatch = useDispatch()

  const [loginData, setLogin] = useState({ phone: '', password: '' });

  const [isLoading, setIsLoading] = useState(false);

  const [registerStep, setRegisterStep] = useState(1);

  const [registerCode, setRegisterCode] = useState({ 0: '', 1: '', 2: '', 3: '' });

  const [registerData, setRegister] = useState({
    userName: '',
    email: '',
    phone: '',
    password: '',
    restaurantName: '',
    restaurantIdentifier: '',
  });

  const [recoverPasswordState, setRecoverPasswordState] = useState({
    phone: '',
    newPassword: '',
  });

  const [recoveryStep, setRecoveryStep] = useState(1);

  const [recoveryCode, setRecoveryCode] = useState({ 0: '', 1: '', 2: '', 3: '' });

  const [loginError, setLoginError] = useState(null);

  const [registerError, setRegisterError] = useState(null);

  const [verifyRegisterError, setVerifyRegisterError] = useState(null);

  const [recoverError, setRecoverError] = useState(null);

  const [verifyRecoveryError, setVerifyRecoveryError] = useState(null);

  const [error, setError] = useState(null);

  const [step2RegisterError, setStep2RegisterError] = useState(false);

  const [disableLogin, setDisableLogin] = useState(true);


  useEffect(() => {
    // starLogin()
  }, [])


  const starLogin = () => {


    if (!token) {
      dispatch(clearAuthV2())

    } else {
      dispatch(me(restaurant, token, user.phone))
    }


  }


  const validateLogin = () => {
    if (loginData.phone === '' || loginData.password === '') return setDisableLogin(true);
    setDisableLogin(false);
  };



  const [disableRegister1, setDisableRegister1] = useState(true);

  const validateRegister1 = () => {
    if (registerData.restaurantName === '' || registerData.restaurantIdentifier === '') {
      return setDisableRegister1(true);
    }

    setDisableRegister1(false);
  };

  const [disableRegister2, setDisableRegister2] = useState(true);

  const validateRegister2 = () => {
    if (
      registerData.userName === '' ||
      registerData.email === '' ||
      registerData.phone === '' ||
      registerData.password === ''
    ) {
      return setDisableRegister2(true);
    }

    setDisableRegister2(false);
  };

  const [disableRegister3, setDisableRegister3] = useState(true);

  const validateRegister3 = () => {
    if (
      registerCode[0] === '' ||
      registerCode[1] === '' ||
      registerCode[2] === '' ||
      registerCode[3] === ''
    ) {
      return setDisableRegister3(true);
    }

    setDisableRegister3(false);
  };

  const [disablePassRecovery, setDisablePassRecovery] = useState(true);

  const validateRecoverPassword = () => {
    if (recoverPasswordState.phone === '' || recoverPasswordState.newPassword === '') {
      return setDisablePassRecovery(true);
    }

    setDisablePassRecovery(false);
  };

  const [disableRecoveryCode, setDisableRecoveryCode] = useState(true);

  const validateRecoveryCode = () => {
    if (
      recoveryCode[0] === '' ||
      recoveryCode[1] === '' ||
      recoveryCode[2] === '' ||
      recoveryCode[3] === ''
    ) {
      return setDisableRecoveryCode(true);
    }

    setDisableRecoveryCode(false);
  };

  const navigate = useNavigate();

  const handleLoginChange = (e) => {
    let { name, value } = e.target;

    setLogin((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLoginPhoneChange = (e) => {
    setLogin((prev) => ({
      ...prev,
      phone: e,
    }));
  };

  const handleRegisterChange = (e) => {
    let { name, value } = e.target;

    setRegister((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRegisterPhoneChange = (e) => {
    setRegister((prev) => ({
      ...prev,
      phone: e,
    }));
  };

  const handleRecoverChange = (e) => {
    let { name, value } = e.target;

    setRecoverPasswordState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRecoverPhoneChange = (e) => {
    setRecoverPasswordState((prev) => ({
      ...prev,
      phone: e,
    }));
  };

  const handleRegisterCode = (e) => {
    let { value, name } = e.target;
    setRegisterCode((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleRecoveryCode = (e) => {
    let { value } = e.target;
    setRecoveryCode(value);
  };

  const handleRegisterCodeChange = (e) => {
    const key = e.key;

    const { maxLength, value, name, nextSibling, previousSibling } = e.target;

    setRegisterCode((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (value.length >= maxLength) {
      if (nextSibling !== null) {
        nextSibling.focus();
      }
    }

    if (key === 'Backspace') {
      if (previousSibling !== null) {
        setRegisterCode((prev) => ({
          ...prev,
          [name]: '',
        }));
        previousSibling.focus();
      } else {
        setRegisterCode((prev) => ({
          ...prev,
          [name]: '',
        }));
      }
    }
  };

  const handleRecoveryCodeChange = (e) => {
    const key = e.key;

    const { maxLength, value, name, nextSibling, previousSibling } = e.target;

    setRecoveryCode((prev) => ({
      ...prev,
      [name]: value,
    }));

    if (value.length >= maxLength) {
      if (nextSibling !== null) {
        nextSibling.focus();
      }
    }

    if (key === 'Backspace') {
      if (previousSibling !== null) {
        setRecoveryCode((prev) => ({
          ...prev,
          [name]: '',
        }));
        previousSibling.focus();
      } else {
        setRecoveryCode((prev) => ({
          ...prev,
          [name]: '',
        }));
      }
    }
  };

  const nextStep = (e) => {
    e.preventDefault();
    setRegisterStep(registerStep + 1);
  };

  const goBack = (e) => {
    e.preventDefault();
    setRegisterStep(registerStep - 1);
  };

  const goBackRecovery = (e) => {
    e.preventDefault();
    setRecoveryStep(recoveryStep - 1);
  };

  const loginUser = async (e, data) => {
    store.setDataLogin(data);
    e.preventDefault();
    dispatch(auth(data))


  }

  // const loginUser = async (e, data) => {


  //   e.preventDefault();
  //   try {
  //     store.setDataLogin(data);
  //     await login(data);
  //     const user = await getUser(); // searching for a match and return user

  //     if (user) {
  //       dispatch(setRestaurantsC(user.restaurants))
  //     }
  //     store.setUser(user);
  //     if (!user.complete) {
  //       navigate(routes.resetPassword);
  //     } else {
  //       navigate(routes.home, { replace: true });
  //     }
  //   } catch (error) {

  //     setLoginError(error.response.data);
  //   }
  // };

  const registerUser = async (e, data) => {
    e.preventDefault();
    try {
      await register(data);
      setRegisterStep(registerStep + 1);
    } catch (error) {
      setRegisterError(error.response.data);
      if (
        error.response.data.restaurantName ||
        error.response.data.restaurantIdentifier
      ) {
        setRegisterStep(1);
      }
      if (
        error.response.data.name &&
        error.response.data.email &&
        error.response.data.phone &&
        error.response.data.password
      ) {
        setStep2RegisterError(true);
      }
    }
  };

  const verifyUserRegister = async (e, data, code) => {
    e.preventDefault();
    let formatedCode = code[0].concat(code[1], code[2], code[3]);
    let verifyObject = { code: formatedCode, phone: data.phone };
    try {
      toast.promise(Promise.resolve(await verify(verifyObject)), {
        loading: 'Verificando Código. Perfil creado!',
        success: <b>Código verificado!</b>,
        error: <b>El código de verificación falló.</b>,
      });
      setRegisterStep(registerStep + 1);
    } catch (error) {
      setVerifyRegisterError(error.response.data);
    }
  };

  const recoverPassword = async (e, data) => {
    e.preventDefault();
    try {
      await requestPass(data);
      setRecoveryStep(recoveryStep + 1);
    } catch (error) {
      setRecoverError(error.response.data);
    }
  };

  const verifyRecoveryCode = async (e, data, code) => {
    e.preventDefault();
    let formatedCode = code[0].concat(code[1], code[2], code[3]);
    let verifyObject = {
      phone: data.phone,
      newPassword: data.newPassword,
      code: formatedCode,
    };
    try {
      toast.promise(Promise.resolve(await confirmPass(verifyObject)), {
        loading: 'Verificando Código...',
        success: <b>Código verificado!</b>,
        error: <b>El código de verificación falló.</b>,
      });
      setRecoveryStep(recoveryStep + 1);
    } catch (error) {
      setVerifyRecoveryError(error.response.data);
    }
  };

  const completeRegister = (e) => {
    e.preventDefault();
    navigate(routes.login, { replace: true });
  };

  const toRecover = (e) => {
    e.preventDefault();
    navigate(routes.recoverPassword);
  };

  const toRegister = (e) => {
    e.preventDefault();
    navigate(routes.registration);
  };
  const toLogin = (e) => {
    e.preventDefault();
    navigate(routes.login);
  };

  const logoutUser = async () => {


    dispatch(clearAuthV2())



  };
  // const logoutUser = async () => {
  //   store.setSubscription(null);
  //   store.setUser(null);
  //   dispatch(clearSubs())
  //   localStorage.removeItem('restaurant');
  //   localStorage.removeItem('state');
  //   dispatch(clearRestaurant())
  //   dispatch(clearRestaurants())

  //   try {
  //     setTimeout(async () => {
  //       await logout();

  //     }, 1500);


  //   } catch (error) {
  //     handleError(error);
  //   } finally {
  //     store.setUser(null);
  //     navigate(routes.login);
  //   }
  // };

  const handleError = (error) => {
    setIsLoading(false);
    setError(error.response?.data);
  };

  return {
    starLogin,
    store,
    loginData,
    registerData,
    registerStep,
    registerCode,
    recoverPasswordState,
    recoveryStep,
    loginError,
    registerError,
    verifyRegisterError,
    recoverError,
    verifyRecoveryError,
    error,
    step2RegisterError,
    isLoading,
    disableLogin,
    disableRegister1,
    disableRegister2,
    disableRegister3,
    disablePassRecovery,
    disableRecoveryCode,
    recoveryCode,
    setRegisterStep,
    nextStep,
    goBack,
    goBackRecovery,
    handleLoginChange,
    handleLoginPhoneChange,
    handleRegisterChange,
    handleRegisterPhoneChange,
    handleRegisterCode,
    handleRegisterCodeChange,
    handleRecoveryCodeChange,
    loginUser,
    verifyUserRegister,
    registerUser,
    completeRegister,
    toRecover,
    logoutUser,
    validateLogin,
    validateRegister1,
    validateRegister2,
    validateRegister3,
    validateRecoverPassword,
    validateRecoveryCode,
    toRegister,
    handleRecoverChange,
    handleRecoverPhoneChange,
    recoverPassword,
    toLogin,
    verifyRecoveryCode,
    handleRecoveryCode,
  };
};

export default useAuth;
