import { useState } from 'react';

const useApi = (defaultData, initialLoading) => {
  const [isLoading, setIsLoading] = useState(initialLoading || false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(defaultData);

  const callApi = async (fn, info) => {

    setError(null);
    setIsLoading(true);

    try {
      const response = await fn(info);
      setData(response);
    } catch (error) {
      setError(error.response?.data);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    data,
    error,
    isLoading,
    callApi,
  };
};

export default useApi;
