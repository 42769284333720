import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getDayOfTheWeek } from 'utils/dates';
import Loading from 'components/Loading';

import styles from '../../home.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderList } from '../../../../redux/slices/orderSlice';
import { Typography } from '@mui/material';

const days = {
  0: 'Lunes',
  1: 'Martes',
  2: 'Miércoles',
  3: 'Jueves',
  4: 'Viernes',
  5: 'Sábado',
  6: 'Domingo',
};

const OrderList = ({ isloa, ordersByWeek, data, setOrdersByWeek, isLoading, refObj }) => {
  const { user, restaurant } = useSelector((state) => state.auth)
  const { dataO } = useSelector((state) => state.orderReducer)

  const dispatch = useDispatch()
  const navigate = useNavigate();



  useEffect(() => {
    setData()
  }, [dataO]);

  const setData = () => {

    if (data) {

      populateOrders(data.orders);
    }

  }

  const populateOrders = (orders) => {


    const newOrdersByWeek = [...ordersByWeek];
    for (let i = 0; i < orders.length; i++) {
      const deliveryDate = orders[i].deliveryDate;
      const scheduleDate = orders[i].scheduleDate;
      const dateToUse = deliveryDate || scheduleDate;
      if (getDayOfTheWeek(dateToUse) === 'Monday') {
        newOrdersByWeek[0].orders.push(orders[i]);
      } else if (getDayOfTheWeek(dateToUse) === 'Tuesday') {
        newOrdersByWeek[1].orders.push(orders[i]);
      } else if (getDayOfTheWeek(dateToUse) === 'Wednesday') {
        newOrdersByWeek[2].orders.push(orders[i]);
      } else if (getDayOfTheWeek(dateToUse) === 'Thursday') {
        newOrdersByWeek[3].orders.push(orders[i]);
      } else if (getDayOfTheWeek(dateToUse) === 'Friday') {
        newOrdersByWeek[4].orders.push(orders[i]);
      } else if (getDayOfTheWeek(dateToUse) === 'Saturday') {
        newOrdersByWeek[5].orders.push(orders[i]);
      } else {
        newOrdersByWeek[6].orders.push(orders[i]);
      }
    }
    setOrdersByWeek(newOrdersByWeek);
  };



  return (



    ordersByWeek.length === 0 ? <Loading /> :

      <div className={styles.overflow}>
        <div className={styles.ordersGrid}>
          {ordersByWeek.map((day, i) => {
            return (
              <div key={`${i}_${day.date}`} className={styles.dayWrapper} ref={refObj[i]}>
                <p className={styles.dayOfWeek}>{days[i]}</p>
                <div className={styles.dayContainer}>
                  {!isLoading &&
                    day.orders.map((order, i) => {
                      return (
                        <div
                          key={`${i}_${order.id}`}
                          className={styles.order}
                          onClick={() => {
                            navigate(
                              `/ordenes/detalle/${order.code}/${parseInt(restaurant)}`,
                            );
                          }}
                        >
                          <div className={styles.supplierContainer}>
                            {order.supplier.logo ? (
                              <img
                                src={order.supplier.logo}
                                alt="supplier logo"
                                className={styles.supplierLogo}
                              />
                            ) : (
                              <div className={`${styles.supplierLogo} ${styles.noLogo}`}>
                                {order.supplier.name[0].toUpperCase()}
                              </div>
                            )}
                            <p className={styles.supplier}>{order.supplier.name}</p>
                          </div>
                          <label className={`${styles.label} ${styles[order.status]}`}>
                            {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
                          </label>
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
  );
};
OrderList.propTypes = {
  ordersByWeek: PropTypes.array.isRequired,
  data: PropTypes.object,
  setOrdersByWeek: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  refObj: PropTypes.object.isRequired,
};

export default OrderList;
