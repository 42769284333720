/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';
import { addUser, getUser } from '../../../../../../api/user'
import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';

import styles from './userRole.module.scss';
import CTA from '../../../../../CTA';

import useStore from 'store';
import { useSelector } from 'react-redux';

const UserRole = ({ changeStep, userInfo }) => {
    const { user, token } = useSelector((state) => state.auth)
    const locationsAux = useStore((state) => state.locations);
    const [role, setRole] = useState('2');
    const [restaurant, setRestaurant] = useState([]);
    const [restaurantList, setRestaurantList] = useState([]);




    useEffect(async () => {
        const userData = await getUser(token, user?.phone);
        const { restaurants } = userData;

        setRestaurantList(restaurants);
    }, []);

    const handleAddUser = async () => {
        try {
            const result = await addUser({
                name: userInfo.name,
                email: userInfo.email,
                phone: userInfo.wsp,
                role: role,
                restaurants: restaurant,
            }, user?.id, token);

            if (result.status === 200) {
                changeStep('next');
            }

            // window.location.reload();
        } catch (error) {
            if (error.response && error.response.status !== 401) {
                toast.error(error.response.data);
            }
        }
    };

    return (
        <div className={styles.contentLocal}>
            <div className={styles.header}>
                <Back className={styles.back} onClick={() => changeStep('prev')} />
                <h1 className={styles.title}>Asignar Rol</h1>
            </div>
            <div className={styles.section} style={{ marginBottom: '30px' }}>
                <h5>Usuario</h5>
                <div>{userInfo.name}</div>
                <h5>Rol</h5>
                <label htmlFor="rol_admin">
                    <input
                        type='radio'
                        id="rol_admin"
                        name="fav_language"
                        value="2"
                        onChange={(e) => {
                            setRole(e.target.value)
                            setRestaurant([]);
                        }}
                        style={{ marginRight: '10px' }}
                        checked={role === '2' ? true : false}
                    />
                    Admin
                </label>
                <label htmlFor="rol_staff">
                    <input
                        type='radio'
                        id="rol_staff"
                        name="fav_language"
                        value="3"
                        onChange={(e) => {
                            setRole(e.target.value);
                            setRestaurant([]);
                        }}
                        style={{ marginRight: '10px' }}
                        checked={role === '3' ? true : false}
                    />
                    Staff
                </label>
                <h5>Local</h5>
                {role === '2' &&
                    <form style={{ display: 'grid', gap: '15px' }}>
                        {restaurantList?.map((val, i) => {
                            return (
                                <label key={i} htmlFor={i}>
                                    <input
                                        type='checkbox'
                                        id={i}
                                        name="fav_language"
                                        value={val.restaurantId}
                                        onChange={(e) => {
                                            let findElement = restaurant.find(val => val === e.target.value);
                                            if (!findElement) {
                                                let restaurantList = [...restaurant, e.target.value]
                                                setRestaurant(restaurantList)
                                            } else {
                                                let removeElement = restaurant.filter(val => val !== e.target.value);
                                                setRestaurant(removeElement);
                                            }
                                        }}
                                        style={{ marginRight: '10px' }}
                                    />
                                    {val.restaurant}
                                </label>
                            );
                        })}
                    </form>
                }
                {role === '3' &&
                    <form style={{ display: 'grid', gap: '10px' }}>
                        {restaurantList?.map((val, i) => {
                            return (
                                <label key={i} htmlFor={i}>
                                    <input
                                        type='radio'
                                        id={i}
                                        name="fav_language"
                                        value={val.restaurantId}
                                        onChange={(e) => setRestaurant([e.target.value])}
                                        style={{ marginRight: '10px' }}
                                    />
                                    {val.restaurant}
                                </label>
                            );
                        })}
                    </form>
                }
            </div>
            <CTA text="Agregar" onClick={handleAddUser} />
        </div>
    );
};

UserRole.propTypes = {
    changeStep: PropTypes.func.isRequired,
    setState: PropTypes.func.isRequired,
    userInfo: PropTypes.object,
    locations: PropTypes.array,
};

export default UserRole;
