import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import useApi from 'hooks/useApi';
import { getSummary } from 'api/orders';
import OrderList from './components/OrderList';
import { toast } from 'react-hot-toast';

import printIcon from 'assets/print.svg';
import DatePicker from 'components/DatePicker';
import CTA from 'components/CTA';

import MobileDateHeader from './components/MobileDateHeader';
import PrintComponent from 'components/PrintComponent';
import Loading from 'components/Loading';

import useStore from 'store';
import { getCurrentWeekFromDate } from 'utils/dates';

import details from './details';
import styles from './home.module.scss';

import { useReactToPrint } from 'react-to-print';
import { useDispatch, useSelector } from 'react-redux';

import { setOrdersRd } from '../../redux/slices/thunks';
import { Box, Chip, Typography } from '@mui/material';
import { clearList, setOrderList } from '../../redux/slices/orderSlice';
import { getSubscription } from '../../api/user';
import Banner from '../../components/Banner/Banner';
import { HomeLayouts } from '../../components/layouts/HomeLayouts';
import useAuth from '../../hooks/useAuth';
import { clearParamsO } from '../../redux/slices/paramsSlice';
import BannerFree from '../../components/Banner/BannerFree';
import { usePopUp } from '../../hooks/usePopUp';
import { formatNumber } from '../../utils/numbers';
import moment from 'moment';
import { gatTotalOrdersBymonth } from '../../api/chart';



const Home = ({ restaurantr }) => {
  const navigate = useNavigate();

  const { user, restaurant, subscription, token } = useSelector((state) => state.auth)
  const { orders_allowed, dataO, isLoadingO, errorO, orderList } = useSelector((state) => state.orderReducer)
  const componentRef = useRef();
  const dispatch = useDispatch()
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const refObj = {
    0: useRef(null),
    1: useRef(null),
    2: useRef(null),
    3: useRef(null),
    4: useRef(null),
    5: useRef(null),
    6: useRef(null),
  };



  //const user = useStore((state) => state.user);
  const { data, error, isLoading, callApi } = useApi(null, true);

  const [date, setDate] = useState(new Date());
  const [ordersByMonth, setordersByMonth] = useState(0);

  const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } =
    getCurrentWeekFromDate(date);

  const [ordersByWeek, setOrdersByWeek] = useState([]);


  useEffect(() => {
    totalOrdersMounth()
    dataSEr()
    dispatch(clearParamsO())

    dispatch(setOrderList([
      {
        date: monday,
        orders: [],
      },
      {
        date: tuesday,
        orders: [],
      },
      {
        date: wednesday,
        orders: [],
      },
      {
        date: thursday,
        orders: [],
      },
      {
        date: friday,
        orders: [],
      },
      {
        date: saturday,
        orders: [],
      },
      {
        date: sunday,
        orders: [],
      },
    ]))
    setOrdersByWeek([
      {
        date: monday,
        orders: [],
      },
      {
        date: tuesday,
        orders: [],
      },
      {
        date: wednesday,
        orders: [],
      },
      {
        date: thursday,
        orders: [],
      },
      {
        date: friday,
        orders: [],
      },
      {
        date: saturday,
        orders: [],
      },
      {
        date: sunday,
        orders: [],
      },
    ]);



  }, [date, restaurant]);

  if (error) {
    toast.error(errorO);
  }


  const dataSEr = () => {

    if (dataO != null) {

      dispatch(clearList())
    } else {

    }

    if (restaurant) {


      dispatch(setOrdersRd(getSummary, {
        from: monday,
        to: sunday,
        offset: new Date().getTimezoneOffset(),
        id_user: user.id,
        id_restaurant: parseInt(restaurant) || restaurantr,
      }, token))
    }
  }

  const totalOrdersMounth = async () => {

    const totalO = await gatTotalOrdersBymonth(restaurant, moment().format('MM'), token)
    setordersByMonth(totalO)


  }

  return (

    <HomeLayouts>
      <div>
        {
          subscription && subscription.status === 'cancelada' ? <Banner />
            : null
        }

        {
          orders_allowed?.status === true && orders_allowed.number <= 0 ? <BannerFree />
            : null
        }

        <div className={styles.content}>
          <div className={styles.headerContainer}>
            <Box

              sx={{ width: { md: 350, xs: '100%' } }}
            >
              <h1 className={styles.header}>Hola, {user?.name.split(' ')[0]}</h1>
              <>
                <Box

                  sx={
                    {
                      boxShadow: 'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
                      marginTop: { md: 1, xs: 1 }
                    }}
                >
                  <Box

                    sx={{ borderTopLeftRadius: 5, borderTopRightRadius: 5 }}
                    mt={1}
                    backgroundColor='#050624'
                    paddingY={0.5}
                    paddingLeft={1}
                  >
                    <p style={{ color: 'white' }} className={`${styles.contentText}`}>
                      Órdenes hechas este mes
                    </p>
                  </Box>
                  {
                    ordersByWeek && ordersByWeek.length === 0 ? null :
                      <Box

                        borderRadius={5}
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                        borderColor={'#E9E7E2'}
                      >
                        <Box
                          padding={1}
                        >
                          <Typography fontWeight={'bold'} >Órdenes</Typography>
                          <span > {ordersByMonth} </span>
                        </Box>
                        <Box
                          padding={1}
                        >
                          <Typography fontWeight={'bold'} >Total gastos del mes</Typography>
                          <span > ${dataO?.totalcost.total === null ? 0 : formatNumber(dataO?.totalcost.total)} </span>
                        </Box>
                      </Box>
                  }
                </Box>








                {/* <p className={`${styles.contentText}`}>
                  Este es tu resumen de la semana
                </p> */}



                {/* <div>
                  {
                    user?.roles[0].name === 'Staff' ? null :
                      <p className={styles.total} >
                        Total ordenes del mes: <span > {ordersByMonth} </span>
                      </p>
                  }
                </div> */}
                {/* <div>
                  {
                    user?.roles[0].name === 'Staff' ? null :
                      <p className={styles.total} >
                        Total gastos del mes: <span > ${dataO?.totalcost.total === null ? 0 : formatNumber(dataO?.totalcost.total)} </span>
                      </p>
                  }
                </div> */}
              </>
            </Box>

            <Box  >
              {subscription?.status !== "cancelada" && user?.roles[0].name !== 'Staff' && (
                <CTA
                  isDisabled={orders_allowed?.status === true && orders_allowed.number <= 0}
                  view="tablet"
                  text="Nueva Orden"
                  onClick={() => {
                    localStorage.setItem('step', 1);
                    localStorage.setItem('state', JSON.stringify({}));
                    navigate('/ordenes/nueva');
                  }}
                  size="small"
                />
              )}
            </Box>

          </div>
          <MobileDateHeader

            monday={monday}
            tuesday={tuesday}
            wednesday={wednesday}
            thursday={thursday}
            friday={friday}
            saturday={saturday}
            sunday={sunday}
            date={date}
            setDate={setDate}
            refObj={refObj}
          />
          <div className={styles.detailsContainer}>
            <div className={styles.flexHeader}>
              <h2 className={styles.subHeader}>Detalles</h2>
              <DatePicker
                restaurant={restaurant}
                startDate={monday}
                endDate={sunday}
                date={date}
                setDate={setDate}
              />
            </div>
            <div className={styles.detailsGrid}>
              {details.map((detail, i) => {

                if (user?.approval === false && detail.name === 'En revisión') {
                  return null
                } else {
                  return (
                    <div
                      key={`${i}_${detail.name}`}
                      className={styles.detail}
                      style={{ backgroundColor: detail.bgHex }}
                    >
                      <img src={detail.icon} alt="icon" className={styles.icon} />
                      <div>
                        <h2 className={styles.subHeader}>
                          {dataO ? dataO.statistics[detail.key] : '0'}
                        </h2>
                        <h3 style={{ color: detail.titleHex }}>{detail.name}</h3>
                      </div>
                    </div>
                  );
                }


              })}
            </div>
          </div>
          <div className={styles.scroll}>
            <div className={styles.flexHeader}>
              <h2 className={styles.subHeader}>Órdenes</h2>
              <button className={styles.printButton} onClick={handlePrint}>
                <img src={printIcon} alt="printIcon" />
                <span>Imprimir</span>
              </button>
            </div>

            {
              ordersByWeek && ordersByWeek.length === 0 ? <Loading /> :
                <OrderList
                  ordersByWeek={ordersByWeek}
                  data={dataO}
                  setOrdersByWeek={setOrdersByWeek}
                  isloa={isLoading}
                  isLoading={isLoadingO}
                  refObj={refObj}
                />
            }
          </div>
        </div>
        <PrintComponent orders={ordersByWeek} ref={componentRef} />
      </div>
    </HomeLayouts>


  );
};

export default Home;
