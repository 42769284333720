import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import storeS from './redux/store';
import App from './App';
import './styles/app.scss'; // Load app styles
import { ThemeConfig } from './config/theme.config';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

const root = document.getElementById('root');
let persistor = persistStore(storeS)

ReactDOM.render(

    <Provider store={storeS} >
        <ThemeConfig>
            <PersistGate persistor={persistor} >
                <App />
            </PersistGate>

        </ThemeConfig>
    </Provider>

    , root);
