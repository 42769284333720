



import { createSlice } from "@reduxjs/toolkit";




const initialState = {

    paramsO:null,
    restaurantP:null,

//    paramsO :{ page: 1,
//                 limit: 5,
//                 status: 'solicitada,aceptada',
//                 query: '',
//             } ,
    tap:0,
   
} 





 export const  paramsSlice = createSlice({

    name:'paramsO',
    initialState:initialState,
    reducers:{
      
        setParamsO: (state, action )=>{

                    
            state.paramsO =   { 
                page: action.payload.page,
                limit: action.payload.limit,
                status: action.payload.status,
                query: '',}            
            state.tap= action.payload.tap
            state.restaurantP=action.payload.rest
            
           

        },
      
    clearParamsO: (state )=>{

        state.paramsO = null
        state.restaurantP = null;
        // state.paramsO =  { 
        //     page: 1,
        //     limit: 5,
        //     status: 'solicitada,aceptada',
        //     query: '',} 
          
        state.tap= 0
}
        
    }


})

export const { setParamsO, clearParamsO } = paramsSlice.actions


