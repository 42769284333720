
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import SearchBar from 'components/SearchBar';

import { getDisplayDate } from 'utils/dates';

import { AiOutlineLeft as Left } from '@react-icons/all-files/ai/AiOutlineLeft';
import { AiOutlineRight as Right } from '@react-icons/all-files/ai/AiOutlineRight';
import styles from './reports.module.scss'
import useStore from 'store';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Box } from '@mui/material';



const ReportsTable = ({ data, loading }) => {


    const columns = [


        { field: 'name', headerName: 'Producto', width: 200, headerAlign: 'center', align: 'center' },
        { field: 'supplier', headerName: 'Prov', width: 200, headerAlign: 'center', align: 'center' },
        { field: 'measurementUnit', headerName: 'Udm', width: 100, headerAlign: 'center', align: 'center' },
        { field: 'requestedQuantity', headerName: 'Ct. Sol', width: 100, headerAlign: 'center', align: 'center' },
        { field: 'confirmedQuantity', headerName: 'Ct. Acep', width: 100, headerAlign: 'center', align: 'center' },
        { field: 'deliveredQuantity', headerName: 'Ct. Ent', width: 100, headerAlign: 'center', align: 'center' },


    ]


    const columnsM = [


        { field: 'name', headerName: 'Producto', width: 200, headerAlign: 'center', align: 'center' },

        { field: 'measurementUnit', headerName: 'Udm', width: 67, headerAlign: 'center', align: 'center' },
        { field: 'requestedQuantity', headerName: 'Ct. Sol', width: 67, headerAlign: 'center', align: 'center' },
        { field: 'confirmedQuantity', headerName: 'Ct. Acep', width: 67, headerAlign: 'center', align: 'center' },
        { field: 'deliveredQuantity', headerName: 'Ct. Ent', width: 67, headerAlign: 'center', align: 'center' },


    ]



    return (
        <>

            <Box sx={{
                display: { xs: 'none', md: 'block' },
                height: 500, width: '100%',

                '& .super-app-theme--header': {
                    backgroundColor: '#EEEEEE',
                    fontWeight: 'bold',
                    fontSize: 10
                },

            }}>
                <DataGrid

                    rows={data}
                    columns={columns}
                    pageSize={5}
                    components={{ Toolbar: GridToolbar }}
                    localeText={{
                        noRowsLabel: "",
                        noResultsOverlayLabel: "No se ha encontrado ningún resultado",
                        toolbarColumns: "Columnas",
                        toolbarColumnsLabel: "Seleccionar columnas",
                        toolbarFilters: "Filtros",
                        toolbarFiltersLabel: "Ver filtros",
                        toolbarFiltersTooltipHide: "Quitar filtros",
                        toolbarFiltersTooltipShow: "Ver filtros",
                        toolbarExportCSV: 'Descargar a CSV',
                        toolbarExportPrint: 'Imprimir',
                        toolbarExport: 'Exportar',
                        toolbarDensity: 'Densidad'
                    }}

                />
            </Box>
            <Box sx={{
                display: { xs: 'block', md: 'none' },
                width: '100%',

                '& .super-app-theme--header': {
                    backgroundColor: '#EEEEEE',
                    fontWeight: 'bold',
                    fontSize: 10
                },

            }}>
                <DataGrid

                    rows={data}
                    columns={columnsM}
                    pageSize={5}
                    components={{ Toolbar: GridToolbar }}
                    localeText={{
                        noRowsLabel: "",
                        noResultsOverlayLabel: "No se ha encontrado ningún resultado",
                        toolbarColumns: "Columnas",
                        toolbarColumnsLabel: "Seleccionar columnas",
                        toolbarFilters: "Filtros",
                        toolbarFiltersLabel: "Ver filtros",
                        toolbarFiltersTooltipHide: "Quitar filtros",
                        toolbarFiltersTooltipShow: "Ver filtros",
                        toolbarExportCSV: 'Descargar a CSV',
                        toolbarExportPrint: 'Imprimir',
                        toolbarExport: 'Exportar',
                        toolbarDensity: 'Densidad'
                    }}

                />
            </Box>


            {/* <div className={styles.content_table}  >


            </div> */}
        </>
    );
};



export default ReportsTable;
