import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import useStore from 'store';
import PopUp from '../../components/PopUp';
import ProfileInfo from '../../components/PopUp/components/ProfileInfo';


import { getUsersByOwner } from '../../api/user';

import { toast } from 'react-hot-toast';

import { getArrayOfErrors } from '../../utils/errors';

import styles from './profile.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { HomeLayouts } from '../../components/layouts/HomeLayouts';

import Loading from '../../components/Loading/Loading';
import { clearParamsO } from '../../redux/slices/paramsSlice';
import { setHoraV2 } from '../../redux/slices/authSlice/authSliceV2';
import { usePopUp } from '../../hooks/usePopUp';

const Profile = () => {

  const locations = useStore((state) => state.locations);
  const { restaurantList, user, subscription, restaurant, hora, isLoadin, token } = useSelector((state) => state.auth)
  const { show, setShow } = usePopUp()



  const dispatch = useDispatch()
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [clickedCard, setClickedCard] = useState(null);
  const [setRestaurant] = useState(null);
  const [roles, setRoles] = useState({});
  const { restaurants } = user;



  useEffect(() => {
    findResta(restaurant)
    dispatch(clearParamsO())
  }, [restaurant])

  useEffect(() => {

    findResta(restaurant)

  }, [restaurant, user])

  useEffect(async () => {



    try {
      const userListByOwner = await getUsersByOwner(user.id, token);
      localStorage.removeItem('step');
      localStorage.removeItem('nameRestaurant');
      localStorage.removeItem('rucRestaurant');
      localStorage.removeItem('aliasRestaurant');

      const adminList = userListByOwner.filter((val) => val.role === 'Administrator');
      const ownerList = userListByOwner.filter((val) => val.role === 'Owner');
      setRoles({ admin: adminList.length, owner: ownerList.length });
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {
          toast.error(errors[i]);
        }
      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    }
  }, []);




  const findResta = (res) => {


    const restHo = user.restaurants.filter(rest => rest.restaurantId === parseInt(res))



    dispatch(setHoraV2({
      hourEnd: restHo[0].hourEnd,
      hourStart: restHo[0].hourStart


    }))



  }

  return (

    <HomeLayouts>

      {
        isLoadin ? <Loading /> :
          <div>
            <div className={styles.content}>
              <PopUp style={clickedCard === 'local' ? true : false} showModal={showModal} setShowModal={setShowModal}>
                <ProfileInfo
                  token={token}
                  clickedCard={clickedCard}
                  user={user}
                  setRestaurant={setRestaurant}
                  subscription={subscription}
                  setShowModal={setShowModal}
                  local={restaurantList}
                />
              </PopUp>
              <div className={styles.desktopHeader}>Perfil</div>
              <div className={styles.cardsContainer}>
                <div
                  className={styles.card}
                  onClick={() => {
                    setClickedCard('user');
                    setShowModal(true);
                  }}
                >
                  <div className={styles.cardTitle}>Datos del Usuario</div>
                  <div className={styles.cardField}>
                    <div className={styles.label}>Nombre: </div>
                    <div className={styles.value}>{user.name}</div>
                  </div>
                  <div className={styles.cardField}>
                    <div className={styles.label}>Teléfono: </div>
                    <div className={styles.value}>{user.phone}</div>
                  </div>
                </div>

                <div
                  className={styles.card}
                  onClick={
                    // () => {
                    //   navigate(`/locales`);
                    // }
                    () => {
                      setClickedCard('local');
                      setShowModal(true);
                    }

                  }
                  style={{
                    display: user.roles[0].name === 'Staff' ? 'none' : null,
                  }}
                >
                  <div className={styles.cardTitle}>Locales</div>
                  <div className={styles.label}>Cantidad: {restaurants.length} </div>

                </div>

                <div
                  className={styles.card}
                  onClick={() => {
                    setClickedCard('subscription');
                    setShowModal(true);
                  }}
                  style={{
                    display: user.roles[0].name === 'Staff' ? 'none' : null,
                  }}
                >
                  <div className={styles.cardTitle}>Suscripción</div>
                  <div className={styles.cardField}>
                    <div className={styles.label}>Facturación: </div>
                    <div className={styles.value}>
                      {subscription
                        ? subscription?.plan?.interval.charAt(0).toUpperCase() +
                        subscription?.plan?.interval.slice(1)
                        : 'N/A'}
                    </div>
                  </div>
                  <div className={styles.cardField}>
                    <div className={styles.label}>Estado: </div>
                    {subscription?.status === 'activa' ? (
                      <div className={`${styles.statusLabel} ${styles.active}`}>Activo</div>
                    ) : (
                      <div className={`${styles.statusLabel} ${styles.inactive}`}>Inactivo</div>
                    )}
                  </div>
                </div>

                <div
                  className={styles.card}
                  onClick={() => {
                    navigate(`/users`);
                  }}
                  style={{
                    display: user.roles[0].name === 'Staff' ? 'none' : null,
                  }}
                >
                  <div className={styles.cardTitle}>Usuarios</div>
                  <div className={styles.cardField}>
                    <div className={styles.label}>Owner: </div>
                    {roles.owner && <div className={styles.value}>{roles.owner}</div>}
                  </div>
                  <div className={styles.cardField}>
                    <div className={styles.label}>Admin: </div>
                    {roles.admin && <div className={styles.value}>{roles.admin}</div>}
                  </div>
                </div>

                <div
                  className={styles.card}
                  onClick={() => {
                    setClickedCard('restaurantHours');
                    setShowModal(true);
                  }}
                  style={{
                    display: user.roles[0].name === 'Staff' ? 'none' : null,
                  }}

                >
                  <div className={styles.cardTitle}>Horarios de entrega</div>
                  <div className={styles.cardField}>
                    <div className={styles.label}>Hora inicial: </div>
                    <div className={styles.value}> {hora === null ? '-' : hora.hourStart} </div>
                  </div>
                  <div className={styles.cardField}>
                    <div className={styles.label}>Hora final: </div>
                    <div className={styles.value}> {hora === null ? '-' : hora.hourEnd} </div>

                  </div>
                </div>





                <div
                  className={styles.card}
                  onClick={() => {
                    setClickedCard('approvals');
                    setShowModal(true);
                  }}
                  style={{
                    display: user.roles[0].name === 'Staff' || user.roles[0].name === 'Administrator' || user.roles[0].name === 'Super Admin' ? 'none' : null,
                  }}
                >
                  <div className={styles.cardTitle}>Aprobaciones</div>
                  <div className={styles.cardField}>

                  </div>
                  <div className={styles.cardField}>
                    <div className={styles.label}>Estado: </div>
                    {user?.approval === true ? (
                      <div className={`${styles.statusLabel} ${styles.active}`}>Activo</div>
                    ) : (
                      <div className={`${styles.statusLabel} ${styles.inactive}`}>Inactivo</div>
                    )}
                  </div>
                </div>


              </div>
            </div>
          </div>
      }
    </HomeLayouts>


  );
};

export default Profile;
