

import { createSlice } from "@reduxjs/toolkit";




const initialState = {

    user:null,
    subscription:null,
    restaurantsSelect:null,
    restaurantList:null,
    isLoadin:false,
    error:null,
    restaurant:null,
    hora:null

} 





 export const  authSlice = createSlice({

    name:'auth',
    initialState:initialState,
    reducers:{
        startAuth:(state)=>{
            state.isLoadin = true
        },
        setUser: (state, action )=>{
                            
            state.user=action.payload.user,
            state.subscription=null,
            state.restaurantsSelect=action.payload.restaurantSelect,
            state.restaurantList=action.payload.restaurantList,
            state.isLoadin=false,
            state.error=null,
            state.restaurant=action.payload.restaurant
        },
        setSubscription: (state, action )=>{
                          
            state.subscription = action.payload;
            
        },
        setRestaurant: (state, action )=>{
                          
            state.restaurant = action.payload;
           
        },

        setError: (state, action )=>{

            
            state.user=null,
            state.subscription=null,
            state.isLoadin=false,
            state.error=action.payload,
            state.restaurant=null
        },
        clearAuth: (state )=>{

            state.user=null,
            state.subscription=null,
            state.isLoadin=false,
            state.restaurantList=null,
            state.restaurantsSelect=null,
            state.error=null,
            state.restaurant=null
            state.hora=null
        },
        setHora:(state, action )=> {

            state.hora = action.payload
    
        },
        
    }


})

export const {startAuth,  setUser,setSubscription,  clearAuth, setError, setRestaurant, setHora } = authSlice.actions


