import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from './select.module.scss';

const InputTag = ({ onChange, options, restaurant }) => {
  const location = useLocation();


  const disable = () => {
    if (
      location.pathname.includes('/proveedores/detalle/') ||
      location.pathname.includes('/ordenes/nueva') ||
      location.pathname.includes('/ordenes/detalle/') ||
      location.pathname.includes('/productos/producto/') ||
      location.pathname.includes('/ordenes/recibir/') ||
      location.pathname.includes('/ordenes/confirmacion/') ||
      location.pathname.includes('/cotizaciones/detalle_cotizacion')

    )
      return true;
    return false;
  };

  return (
    <select
      onChange={onChange}
      className={styles.select}
      value={restaurant}
      disabled={disable()}
    >
      {options.map((val, i) => {
        return (
          <option value={val.value} key={i}>
            {val.name}
          </option>
        );
      })}
    </select>
  );
};

export default InputTag;
