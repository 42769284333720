import React, { useEffect, useState } from 'react'
import { HomeLayouts } from '../../../components/layouts/HomeLayouts'
import { Box, Grid, Typography } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';
import { useSelector } from 'react-redux';
import moment from 'moment';
import CTA from '../../../components/CTA';
import CTARed from '../../../components/CTARed';
import { quoteForId } from '../../../api/quotes';
import Loading from '../../../components/Loading';
import PopUp from '../../../components/PopUp';

export const QuotesDetail = () => {
    const { restaurant, user, subscription, token, restaurantsSelect } = useSelector((state) => state.auth)
    const { id } = useParams();
    const [order, setorder] = useState(null)
    const [loading, setloading] = useState(false)
    const [showModal, setshowModal] = useState(false)

    //const { order } = state
    const navigate = useNavigate();




    const filt = () => {

        if (restaurantsSelect.length > 0) {
            const rest = restaurantsSelect.filter((resta) => {
                return resta.id === parseInt(restaurant)
            })

            return rest[0].name
        }

    }


    useEffect(() => {

        setQuote()
    }, [])



    const setQuote = async () => {

        setloading(true)

        const resp = await quoteForId(token, id)

        if (resp.ok === true) {
            console.log(resp.data.status)
            setorder(resp.data)
            setloading(false)
        } else {
            setorder(null)
            setloading(false)
        }


    }




    return (

        <HomeLayouts>

            <PopUp
                showModal={showModal}
            >
                <Box>
                    <Typography
                        fontSize={19}
                        fontWeight={'bold'}
                    >
                        Aceptación
                    </Typography>
                    <Typography
                        fontSize={16}

                    >
                        Una vez sea confirmada la aceptación de la cotización, el proveedor sera agregado a su lista de proveedores y el mismo podra ver sus datos
                    </Typography>

                    <Box
                        mt={3}
                        display={'flex'}
                        flexDirection={'row'}
                        padding={1}
                        alignItems={'center'}
                        justifyContent={'flex-end'}

                    >
                        <CTARed
                            onClick={() => setshowModal(false)}
                            size={'small'}
                            text='Cancelar'
                        />
                        <CTA

                            size={'small'}
                            text='Confirmar'
                        />
                    </Box>

                </Box>
            </PopUp>

            {order === null ? Loading :

                <Grid
                    container
                    minHeight={'100%'}
                    display={'flex'}
                    flexDirection={'row'}
                >
                    <Grid
                        md={12}
                        xs={12}
                        lg={6}
                        item

                        paddingTop={5}
                    //bgcolor={'white'}

                    >
                        <Box
                            display='flex'
                            flexDirection='row'
                            alignItems={'center'}
                            sx={{ paddingLeft: { xs: 3, sm: 5, md: 10, lg: 8, xl: 14 } }}


                        >
                            <Back style={{ fontSize: 30, marginTop: 5, marginRight: 5 }} onClick={() => navigate('/cotizaciones')} />

                            <Typography
                                fontWeight={'bold'}
                                fontSize={28}
                            >
                                Detalle cotización
                            </Typography>
                        </Box>

                        <Box
                            mt={5}
                            padding={1}
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'center'}

                        >
                            <Grid
                                container
                                sx={{ width: { xs: '94%', sm: '88%', md: '76%', lg: '74%' } }}
                                width={'100%'}
                                padding={2}
                                border={1}
                                borderRadius={3}
                                borderColor={'#EEEEEE'}
                            >

                                <Grid
                                    display={'flex'}
                                    flexDirection={'row'}
                                    container

                                    justifyContent={'space-between'}
                                >
                                    <Grid
                                        xs={4}
                                        md={6}
                                        item
                                    >
                                        <Typography fontWeight={'bold'} >Producto</Typography>
                                    </Grid>
                                    <Grid
                                        xs={4}
                                        md={4}
                                        item

                                    >
                                        <Typography textAlign={'center'} fontWeight={'bold'} >C. mensual</Typography>
                                    </Grid>
                                    {
                                        order && order.status != "Solicitada" ?
                                            <Grid
                                                xs={4}
                                                md={2}
                                                item
                                            >
                                                <Typography
                                                    textAlign={'center'}
                                                    fontWeight={'bold'} > {`Precio por ${order.products[0].unitOfMeasure}`}   </Typography>
                                            </Grid> : null
                                    }


                                </Grid>
                                {
                                    order && order.products.map((product) => {

                                        return (
                                            <Grid
                                                key={product.quoteId}
                                                mt={1}
                                                display={'flex'}
                                                flexDirection={'row'}
                                                container

                                                justifyContent={'space-between'}
                                            >
                                                <Grid
                                                    md={6}
                                                    xs={4}
                                                    item
                                                >
                                                    <Typography

                                                        sx={{
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            display: "-webkit-box",
                                                            WebkitLineClamp: "2",
                                                            WebkitBoxOrient: "vertical",
                                                        }}
                                                    >{product.name} </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={4}
                                                    md={4}
                                                >
                                                    <Typography
                                                        textAlign={'center'}
                                                    > {product.quantity} </Typography>
                                                </Grid>
                                                {
                                                    order.status != "Solicitada" ?
                                                        <Grid
                                                            md={2}
                                                            xs={4}
                                                            item
                                                        >
                                                            <Typography
                                                                textAlign={'center'}
                                                            > { } </Typography>
                                                        </Grid> : null
                                                }


                                            </Grid>

                                        )
                                    })
                                }

                            </Grid>
                        </Box>

                        <Box
                            mt={3}
                            padding={1}
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'center'}

                        >

                            <Box
                                sx={{ width: { xs: '94%', sm: '88%', md: '76%', lg: '74%' } }}
                                padding={1}

                            >
                                <Typography> Notas </Typography>
                            </Box>
                            <Grid
                                mb={2}
                                height={80}
                                container
                                sx={{ width: { xs: '94%', sm: '88%', md: '76%', lg: '74%' } }}
                                width={'100%'}
                                padding={1}
                                border={1}
                                borderRadius={3}
                                borderColor={'#EEEEEE'}
                            >
                                <Typography
                                    color={'#686D76'}
                                >
                                    {order && order.note}
                                </Typography>
                            </Grid>
                        </Box>

                    </Grid>
                    <Grid
                        md={12}
                        xs={12}
                        lg={6}
                        item
                        padding={3}
                        bgcolor={'#f5f5f5'}
                    >


                        <Box
                            sx={{ marginTop: { xs: 2, lg: 11 } }}

                            padding={1}
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'center'}
                        >
                            <Grid
                                container
                                sx={{ width: { xs: '94%', sm: '88%', md: '76%', lg: '74%' } }}
                                width={'100%'}
                                padding={2}
                                border={1}
                                borderRadius={3}
                                bgcolor={'white'}
                                borderColor={'#EEEEEE'}
                                display={'flex'}
                                flexDirection={'column'}
                            >
                                <Box
                                    display={'flex'}
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                >
                                    <Typography color={'#666666'} fontWeight={'bold'} > Estado:  </Typography>
                                    <Box ml={1} paddingBottom={0.1} paddingLeft={1} paddingRight={1} borderRadius={3} bgcolor={order.status === 'Solicitada' ? '#FAECCC' :
                                        order.status === 'aceptada' ? '#D6E4EE' : order.status === 'entregada' ? '#DEECDC' : '#FAE3DE'} >
                                        <Typography fontSize={13} > {order.status} </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    mt={1}
                                    display={'flex'}
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                >
                                    <Typography color={'#666666'} fontWeight={'bold'} > Cotización:  </Typography>
                                    <Box ml={1} paddingBottom={0.1} paddingLeft={1} paddingRight={1} borderRadius={3} >
                                        <Typography fontSize={16} > {order.id} </Typography>
                                    </Box>
                                </Box>
                                <Box
                                    mt={1}
                                    display={'flex'}
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                >
                                    <Typography color={'#666666'} fontWeight={'bold'} > Proveedor:  </Typography>
                                    <Box ml={1} paddingBottom={0.1} paddingLeft={1} paddingRight={1} borderRadius={3} >
                                        <Typography fontSize={16}  >{order.nameSupplier}</Typography>

                                    </Box>
                                </Box>



                            </Grid>
                        </Box>
                        <Box
                            mt={1}
                            padding={1}
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'center'}
                        >
                            <Grid
                                container
                                sx={{ width: { xs: '94%', sm: '88%', md: '76%', lg: '74%' } }}
                                width={'100%'}
                                padding={2}
                                border={1}
                                borderRadius={3}
                                bgcolor={'white'}
                                borderColor={'#EEEEEE'}
                                display={'flex'}
                                flexDirection={'column'}
                            >
                                <Box
                                    display={'flex'}
                                    flexDirection={'row'}
                                    alignItems={'center'}
                                >
                                    <Typography color={'#666666'} fontWeight={'bold'} > Fecha de solicitud:  </Typography>
                                    <Box ml={1} paddingBottom={0.1} paddingLeft={1} paddingRight={1} borderRadius={3}>
                                        <Typography fontSize={16} > {moment(order.createdAt).format('DD-MM-YYYY')} </Typography>
                                    </Box>
                                </Box>

                            </Grid>
                        </Box>


                        <Box
                            sx={{ marginTop: { xs: -3, md: 0.5, sm: 0.5, lg: 1 } }}
                            padding={1}
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'center'}
                        >
                            <Box
                                sx={{ width: { xs: '94%', sm: '88%', md: '76%', lg: '74%' } }}
                                mt={2}
                            >
                                <CTA
                                    isDisabled={order.status === 'Solicitada' ? true : false}
                                    onClick={() => setshowModal(true)}
                                    text='Aceptar cotización'

                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{ marginTop: { xs: -3, md: 0.5, sm: 0.5, lg: 1 } }}
                            padding={1}
                            display={'flex'}
                            flexDirection={'row'}
                            justifyContent={'center'}
                        >
                            <Box
                                sx={{ width: { xs: '94%', sm: '88%', md: '76%', lg: '74%' } }}
                                mt={1}
                            >
                                <CTARed
                                    onClick={() => { }}
                                    text='Rechazar cotización'
                                />
                            </Box>
                        </Box>



                    </Grid>
                </Grid>
            }



        </HomeLayouts>
    )
}


