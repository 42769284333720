import axios from "axios";
import { getSummary } from "../../api/orders";
import { detailProduct, fiterForProduct, fiterForSupplier, getListOfFavoriteSuppliers, getListSuppliersAll, getProductForRestaurant, getProducts, getReport } from "../../api/restaurants";
import { getSubscription, getUser } from "../../api/user";
import { setErrorO, setOrdersAllowed, setOrdersR, startOrders } from "./orderSlice";
import { startReport, setReports, setErrorsReport, clearReports } from "./reportsSlice";
import { setRestaurantsC, startRestaurant } from "./restaurantSlice";
import { startSuppliers, setSuppliersList, setSuppliersListAll } from "./suppliersSlice";
import { setErrorsProducts, setProduct, setProducts, startProducts } from "./productsSlice";
import { setDocuments, setErrordoc, startDocuments } from "./documentsSlice";
import { getInvoices } from "../../api/documents";
import { setError, setSubscription } from "./authSlice/authSlice";
import { Typography } from "@mui/material";


 
export const setOrdersRd =  (fn, info, token) => {


    return async (dispatch) => {

        dispatch(startOrders());
        try {
            const response = await fn(info, token);
            dispatch(setOrdersAllowed(response.orders_allowed)
                )
            dispatch(setOrdersR(response))
          } catch (error) {
            dispatch(setErrorO(error.response?.data))
          

            
          } finally {
            
          }
       
       
    }


}

export const setSuppliersR =  ( token, id_restaurant) => {

    return async (dispatch) => {

        dispatch(startSuppliers());

            const suppliersResult = await getListOfFavoriteSuppliers(token, {
            limit: 20,
            page: 1,
            query: '',
            idRestaurant: id_restaurant,
        });

 
            const supplierOptions = suppliersResult.suppliers.map((val) => {
            return {
                value: val.id,
                label: val.name,
            };
        });

                    dispatch( setSuppliersList({supplierBy:supplierOptions, listAll:suppliersResult.suppliers}) )
                  

    }


}


















































export const setSuppliersDocs =  (id_restaurant, token) => {


    return async (dispatch) => {

        dispatch(startSuppliers());

            const suppliersResult = await getListSuppliersAll({
              idRestaurant: id_restaurant,
        },token);

        
            const supplierOptions = suppliersResult.map((val) => {
            return {
                value: val.id,
                label: val.name,
            };
        });


        const listSup = supplierOptions.sort(function (a, b) {
            if (a.label > b.label) {
              return 1;
            }
            if (a.label < b.label) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });


 
                    dispatch( setSuppliersListAll(listSup) )
                  

    }


}

export const setReport =  (idRestaurant, fromDate, toDate, suppliers, token) => {



    const data = {
        restaurantId:idRestaurant,
        fromDate:fromDate,
        toDate:toDate,
        suppliers: suppliers
    }


    return async (dispatch) => {

        dispatch(startReport());
        const reports = await getProducts(data, token)
  
        dispatch( setReports(reports))
        

    }

}

export const clearR =  () => {



    return async (dispatch) => {

        dispatch(startReport());
        dispatch( clearReports())
        

    }

}

export const setRestaurantList =  (lista) => {
   

    return async (dispatch) => {

 dispatch(startRestaurant());

        const user = await getUser(); // searching for a match and return user

          

        if (user.restaurants !== undefined ) {
            let restaurantList = user.restaurants.map((val) => {
                return {
                    id: val.restaurantId,
                    name: val.restaurant,
                    value: val.restaurantId,
                    address: val.address,
                    city: val.city,
                    state: val.state,
                  };
      });
      dispatch( setRestaurantsC(restaurantList))
        } 
    }
}



export const setProductsT =  (params, token) => {
   
    return async (dispatch) => {
        
        dispatch(startProducts())

        const response = await getProductForRestaurant(params, token);
                    
                 dispatch(setProducts(response))
              
    }

 
}
export const setProductCa =  (restaurant, id_supplier, id_product, monthC, year, token) => {
   
    return async (dispatch) => {

         dispatch(startProducts())

        const response = await detailProduct(
            {
                restaurant_id: restaurant,
                supplier_id: id_supplier,
                product_id: id_product,
                month: monthC,
                year:year
            }, token);

                    
                 dispatch(setProduct(response))
       
       
    }

 
}

export const setProductsP =  (params, token) => {
   
        return async (dispatch) => {
          

            try {
               
                if (params.restaurant !== null) {
             
                    const response = await getProductForRestaurant(params, token);
               
                    dispatch(setProducts(response))
                   
                }else{
                    null
                }
              } catch (error) {
              
                dispatch(setErrorsProducts(error.response?.data))
              } finally {
                
              }
            
                // const response = await fn(info);
                // dispatch(setOrdersR(response))
           
        }
    
    
    }


    export const setDoc =  (data, token) => {
   
        return async (dispatch) => {
            dispatch(startDocuments())
            try {
                                     
                    const response = await getInvoices(data, token);
                  
                        dispatch(setDocuments(response))
                                 
              } catch (error) {
              
                dispatch(setErrordoc(error.response?.data))
              } finally {
                
              }
                  }
       }




       export const setDocRe =  (data, token) => {
   
        return async (dispatch) => {
          
            try {
                                     
                    const response = await getInvoices(data, token);
                   
                        dispatch(setDocuments(response))
                                 
              } catch (error) {
              
                dispatch(setErrordoc(error.response?.data))
              } finally {
                
              }
                  }
       }

       export const setSubscriptionCa =  (id_restaurant) => {
        
      

        return async (dispatch) => {
          
            try {
                                     
                    const response = await getSubscription(id_restaurant);
             
                        dispatch(setSubscription(response))
                                 
              } catch (error) {
              
                dispatch(setError(error.response?.data))
              } finally {
                
              }
                  }
       }





//
