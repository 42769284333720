import { Box, Collapse, List } from '@mui/material';
import React from 'react'
import styles from './hamburgerMenu.module.scss';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

export const LinkMenu = ({ icon, name, links, restaurant }) => {

    const [isOpen, setIsOpen] = useState(false);

    const handleIsOpen = () => {
        setIsOpen((prev) => !prev);
    };


    useEffect(() => {

        setIsOpen(false)

    }, [restaurant])



    return (

        <List>
            <Link
                onClick={handleIsOpen}
                to={'#'}
                className={`${styles.link}`}
            >
                <img src={icon} className={styles.icons} />
                {name}
            </Link>
            {
                links.map((v, i) => (

                    <Collapse
                        key={i}
                        sx={{
                            height: 0,
                            margin: 0,
                            padding: 0,

                        }}
                        in={isOpen}>
                        <Box

                            display='flex'

                        >

                            <Box ml={2} display={'flex'} alignItems={'center'}  >
                                <Box
                                    ml={3}
                                    mr={1}
                                    borderRadius={50}
                                    bgcolor={'black'}
                                    height={6}
                                    width={6}
                                    mt={0.3}>
                                </Box>
                                <Box
                                    width={190}

                                >
                                    <Link

                                        to={v.to}
                                        key={`${i}_${v.to}`}
                                        className={`${styles.link} `}
                                    >
                                        {v.name}
                                    </Link>
                                </Box>

                            </Box>
                        </Box>
                    </Collapse>

                ))
            }
        </List>

    )
}
