import React from 'react';
import PropTypes from 'prop-types';

import { toast } from 'react-hot-toast';

import AutoComplete from 'components/AutoComplete';
import CTA from 'components/CTA';

import styles from '../../../pop-up.module.scss';

const Step1 = ({
  suppliers,
  state,
  setState,
  formData,
  handleInputChange,
  setStep,
  step,
  setQuery,
  setFormData,
}) => {
  return (
    <div className={styles.content}>
      <h1 className={styles.title}>Añadir Proveedor</h1>
      <h2 className={styles.subtitle}>Por favor ingresa los datos de tu proveedor.</h2>
      <AutoComplete
        suggestions={suppliers}
        placeholder="Razón Social"
        state={state}
        setState={setState}
        setQuery={setQuery}
        formData={formData}
        setFormData={setFormData}
      />
      <input
        className={styles.input}
        type="text"
        placeholder="RUC"
        name="ruc"
        value={formData.ruc}
        onChange={handleInputChange}
        inputMode="numeric"
        autoComplete="number"
        autoCorrect="off"
      />
      <div className={styles.bottomContainer}>
        <div className={styles.stepsContainer}>
          <div className={`${styles.dot} ${step === 1 && styles.current}`}></div>
          <div className={`${styles.dot} ${step === 2 && styles.current}`}></div>
        </div>
        <CTA
          text="Continuar"
          isDisabled={formData.ruc === ''}
          onClick={() => {
            if (formData.ruc.length !== 13) {
              toast.error('El RUC debe tener 13 dígitos');
            } else {
              setStep(2);
            }
          }}
        />
      </div>
    </div>
  );
};

Step1.propTypes = {
  suppliers: PropTypes.array,
  state: PropTypes.object,
  setState: PropTypes.func,
  formData: PropTypes.object,
  handleInputChange: PropTypes.func,
  setStep: PropTypes.func,
  step: PropTypes.number,
  setQuery: PropTypes.func,
  setFormData: PropTypes.func,
};

export default Step1;
