import React from 'react';
import { Routes, Route } from 'react-router-dom';


import NotFound from 'router/components/NotFound';

import { Products } from './Products';
import { Product } from './Product';



const ProductsRouters = ({ restaurant }) => {

  return (
    <>
      <Routes>
        <Route path={'/'} element={<Products restaurant={restaurant} />} />
        <Route path={'/producto/:restaurant/:id_supplier/:id_product'} element={<Product restaurant={restaurant} />} />     
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default ProductsRouters;
