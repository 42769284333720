import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import CTA from '../../../CTA';
import CTARed from '../../../CTARed';

import { changePassword } from 'api/user';
import { toast } from 'react-hot-toast';

import { getArrayOfErrors } from 'utils/errors';
import { updateRestaurant } from 'api/restaurants';

import { getDisplayDate } from 'utils/dates';

import useForm from 'hooks/useForm';
import whatsappIcon from 'assets/whatsapp.svg';
import commonStyles from '../../pop-up.module.scss';
import styles from './profileInfo.module.scss';
import RestaurantList from './RestaurantCreation/RestaurantList/RestaurantList';
import RestaurantForm from './RestaurantCreation/RestaurantForm/RestaurantForm';
import RestaurantAddress from './RestaurantCreation/RestaurantAddress/RestaurantAddress';
import { deleteUser, updateStateApproval, updateUserRole } from '../../../../api/user';

import UserForm from './Users/UsersForm/UserForm';
import UserRole from './Users/UsersRole/UserRole';
import UserConfirmation from './Users/UserConfirmation/UserConfirmation';
import { updateHoursRestaurant } from '../../../../api/restaurants';
import { useDispatch, useSelector } from 'react-redux';

import { Stack, Switch, Typography } from '@mui/material';
import { me } from '../../../../redux/slices/authSlice/thunksV2';
import { getSites } from '../../../../api/sites';


const ProfileInfo = ({
  clickedCard,
  user,
  setShowModal,
  subscription,
  restaurant,
  setRestaurant,
  local,
  row,
  token
}) => {
  const components = {
    restaurant: (
      <RestaurantInfo
        restaurant={restaurant}
        setRestaurant={setRestaurant}
        setShowModal={setShowModal}
      />
    ),
    user: <UserInfo token={token} user={user} setShowModal={setShowModal} />,
    subscription: <SubscriptionInfo subscription={subscription} />,
    local: <RestaurantsInfo local={local} token={token} />,
    usersByOwner: <UsersManagement setShowModal={setShowModal} />,
    userDelete: <UserDeleteConfirmation row={row} setShowModal={setShowModal} />,
    restaurantHours: <RestaurantHours restaurant={restaurant} setShowModal={setShowModal} />,
    approvals: <Approvals setShowModal={setShowModal} />,
    updateUser: <UserUpdateConfirmation row={row} setShowModal={setShowModal} />
  };
  return components[clickedCard];
};



const RestaurantInfo = ({ restaurant, setRestaurant, setShowModal }) => {
  const { formData, handleInputChange } = useForm({
    businessName: restaurant.businessName,
    address: restaurant.address,
    city: restaurant.city,
    state: restaurant.state,
  });

  const handleCancel = async () => {
    try {
      const response = await updateRestaurant(formData);
      setShowModal(false);
      await setRestaurant(response);
      toast.success('Datos actualizados con éxito');
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {
          toast.error(errors[i]);
        }
      } else {
        toast.error('Error interno. Por favor intente nuevamente');
      }
    }
  };

  return (
    <div className={styles.content}>
      <h1 className={commonStyles.title}>Datos del Comercio</h1>
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Tus Datos</h2>
        <input
          disabled={true}
          value={restaurant.name}
          className={`${styles.input} ${styles.disabled}`}
        />
        <input
          disabled={true}
          value={restaurant.identifier}
          className={`${styles.input} ${styles.disabled}`}
        />
      </div>
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Datos Adicionales</h2>
        <input
          name="businessName"
          value={formData.businessName}
          className={styles.input}
          onChange={handleInputChange}
          placeholder="Razón Social"
        />
        <input
          name="address"
          value={formData.address}
          className={styles.input}
          onChange={handleInputChange}
          placeholder="Dirección"
        />
        <input
          name="city"
          value={formData.city}
          className={styles.input}
          onChange={handleInputChange}
          placeholder="Ciudad"
        />
        <input
          name="state"
          value={formData.state}
          className={styles.input}
          onChange={handleInputChange}
          placeholder="Provincia"
        />
        <CTA text="Editar Campos" onClick={handleCancel} />
      </div>
    </div>
  );
};

const UserInfo = ({ user, setShowModal, token }) => {



  const { formData, handleInputChange, isDisabled } = useForm({
    currentPassword: '',
    newPassword: '',
  });



  const handlePasswordChange = async () => {
    try {
      await changePassword(formData, user?.id, token);
      setShowModal(false);
      toast.success('Contraseña cambiada con éxito');
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {
          toast.error(errors[i]);
        }
      } else {
        toast.error('Error al cambiar contraseña. Por favor intente nuevamente');
      }
    }
  };

  return (
    <div className={styles.content}>
      <h1 className={commonStyles.title}>Datos del Usuario</h1>
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Tus Datos</h2>
        <input
          disabled={true}
          value={user.name}
          className={`${styles.input} ${styles.disabled}`}
        />
        <input
          disabled={true}
          value={user.phone}
          className={`${styles.input} ${styles.disabled}`}
        />
        <input
          disabled={true}
          value={user.email}
          className={`${styles.input} ${styles.disabled}`}
        />
      </div>
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Cambiar Contraseña</h2>
        <input
          name="currentPassword"
          value={formData.currentPassword}
          className={styles.input}
          onChange={handleInputChange}
          placeholder="Contraseña Actual"
        />
        <input
          name="newPassword"
          value={formData.newPassword}
          className={styles.input}
          onChange={handleInputChange}
          placeholder="Nueva Contraseña"
        />
        <CTA
          text="Cambiar Contraseña"
          onClick={() => handlePasswordChange()}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
};

const SubscriptionInfo = ({ subscription }) => {
  const navigate = useNavigate();

  const buttonContent = (
    <>
      <img src={whatsappIcon} alt="whatsapp icon" className={styles.icon} />
      Renovar Suscripción
    </>
  );

  return (
    <div className={styles.content}>
      <h1 className={commonStyles.title}>Suscripción</h1>
      <div className={styles.body}>
        <div className={styles.card}>
          <div className={styles.cardField}>
            <div className={styles.label}>Fecha: </div>
            <div className={styles.value}>
              {subscription ? getDisplayDate(subscription.endDate) : 'N/A'}
            </div>
          </div>
          <div className={styles.cardField}>
            <div className={styles.label}>Facturación: </div>
            <div className={styles.value}>
              {subscription
                ? subscription?.plan?.interval.charAt(0).toUpperCase() +
                subscription?.plan?.interval.slice(1)
                : 'N/A'}
            </div>
          </div>
          <div className={styles.cardField}>
            <div className={styles.label}>Precio: </div>
            <div className={styles.value}>
              {subscription ? `$${subscription.plan.price}` : 'N/A'}
            </div>
          </div>
          <div className={styles.cardField}>
            <div className={styles.label}>Estado: </div>
            {subscription?.status === 'activa' ? (
              <div className={`${styles.statusLabel} ${styles.active}`}>Activo</div>
            ) : (
              <div className={`${styles.statusLabel} ${styles.inactive}`}>Inactivo</div>
            )}
          </div>
        </div>
        {subscription?.status === 'inactive' && (
          <CTA
            text={buttonContent}
            onClick={() => {
              navigate(
                'https://api.whatsapp.com/send/?phone=15409984347&text=¡Hola! Quiero renovar mi suscripción',
              );
            }}
          />
        )}
      </div>
    </div>
  );
};

const RestaurantsInfo = ({ local, token }) => {
  const handleInitialStep = () => {
    const storageStep = JSON.parse(localStorage.getItem('step')) || null;
    const storageState = JSON.parse(window.localStorage.getItem('state'));

    if (
      !storageStep ||
      typeof storageStep !== 'number' ||
      isNaN(storageStep) ||
      storageStep > 4 ||
      storageStep < 1
    ) {
      return 1;
    }
    if (storageStep === 1) {
      return 1;
    }
    if (storageState?.supplierId) {
      if (storageStep === 2) return 2;
      if (storageState?.products.length > 0) {
        if (storageStep === 3) return 3;
      }
    }
    if (storageStep === 4 && storageState?.orderId) {
      return 4;
    }
    return 1;
  };

  const [state, setState] = useState({
    supplierId: JSON.parse(localStorage.getItem('state'))?.supplierId || null,
    products: JSON.parse(localStorage.getItem('state'))?.products || [],
    note: JSON.parse(localStorage.getItem('state'))?.note || '',
    orderId: JSON.parse(localStorage.getItem('state'))?.orderId || null,
  });
  const [restaurantInfo, setRestaurantInfo] = useState(null);
  const [cities, setcities] = useState(null)
  const [step, setStep] = useState(handleInitialStep());

  const changeStep = (direction) => {
    if (direction === 'next') {
      setStep(step + 1);
    }
    if (direction === 'prev') {
      setStep(step - 1);
    }
  };

  // const resetProcess = () => {
  //   window.localStorage.removeItem('state');
  //   setState({
  //     supplierId: null,
  //     products: [],
  //     note: '',
  //     orderId: null,
  //   });
  //   setStep(1);
  // };



  useEffect(() => {
    window.localStorage.setItem('state', JSON.stringify(state));
  }, [state]);

  useEffect(() => {
    window.localStorage.setItem('step', JSON.stringify(step));
  }, [step]);

  switch (step) {
    case 2:
      return (
        <RestaurantForm
          changeStep={changeStep}
          setState={setState}
          setRestaurantInfo={setRestaurantInfo}
        />
      );
    case 3:
      return (
        <RestaurantAddress
          changeStep={changeStep}
          setState={setState}
          restaurantInfo={restaurantInfo}
        />
      );
    default:
      return <RestaurantList local={local} changeStep={changeStep} setState={setState} />;
  }
};

const UsersManagement = ({ setShowModal }) => {
  const handleInitialStep = () => {
    const storageStep = JSON.parse(localStorage.getItem('step')) || null;
    const storageState = JSON.parse(window.localStorage.getItem('state'));

    if (
      !storageStep ||
      typeof storageStep !== 'number' ||
      isNaN(storageStep) ||
      storageStep > 4 ||
      storageStep < 1
    ) {
      return 1;
    }
    if (storageStep === 1) {
      return 1;
    }
    if (storageState?.supplierId) {
      if (storageStep === 2) return 2;
      if (storageState?.products.length > 0) {
        if (storageStep === 3) return 3;
      }
    }
    if (storageStep === 4 && storageState?.orderId) {
      return 4;
    }
    return 1;
  };

  const [state, setState] = useState({
    supplierId: JSON.parse(localStorage.getItem('state'))?.supplierId || null,
    products: JSON.parse(localStorage.getItem('state'))?.products || [],
    note: JSON.parse(localStorage.getItem('state'))?.note || '',
    orderId: JSON.parse(localStorage.getItem('state'))?.orderId || null,
  });
  const [userInfo, setUserInfo] = useState(null);

  const [step, setStep] = useState(handleInitialStep());

  const changeStep = (direction) => {
    if (direction === 'next') {
      setStep(step + 1);
    }
    if (direction === 'prev') {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    window.localStorage.setItem('state', JSON.stringify(state));
  }, [state]);

  useEffect(() => {
    window.localStorage.setItem('step', JSON.stringify(step));
  }, [step]);

  switch (step) {
    case 2:
      return <UserRole changeStep={changeStep} setState={setState} userInfo={userInfo} />;
    case 3:
      return <UserConfirmation setShowModal={setShowModal} />;
    default:
      return (
        <UserForm changeStep={changeStep} setState={setState} setUserInfo={setUserInfo} />
      );
  }
};

const UserDeleteConfirmation = ({ row, setShowModal }) => {
  const { user, token } = useSelector((state) => state.auth)


  const handleDeleteUser = async () => {
    try {
      const response = await deleteUser(row.id, user?.id, token);
      toast.success('Usuario eliminado correctamente');
      setTimeout(() => {
        window.location.reload();
      }, 800);


    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {
          toast.error(errors[i]);
        }
      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    }
  };

  return (
    <div className={styles.content}>
      <h1 className={commonStyles.title}>Eliminar Usuario</h1>
      <div className={styles.section}>
        <h2 className={styles.titleUser}>Usuario</h2>
        <div className={styles.section}>{row.name}</div>
        <h2 className={styles.titleUser}>Número de teléfono</h2>
        <div className={styles.section}>{row.phone}</div>
        <h2 className={styles.titleUser}>Rol</h2>
        <div className={styles.section}>{row.role}</div>
        <CTARed text="Eliminar" onClick={() => handleDeleteUser()} />
      </div>
    </div>
  );
};

const UserUpdateConfirmation = ({ row, setShowModal, approval }) => {
  const [loading, setLoading] = useState(false)
  const { user, token } = useSelector((state) => state.auth)


  const role = row.role === 'Administrator' ? 'Super Admin' : 'Administrador'


  const handleUpdateUser = async () => {

    setLoading(true)
    const data = {
      id_user: row.id,
      role_id: row.role === 'Administrator' ? 5 : 2
    }

    try {
      const response = await updateUserRole(user?.id, data, token);

      if (response === 'User has been updated') {

        toast.success('Usuario actuliazado');


        setShowModal(false)

        setTimeout(() => {
          setLoading(false)
          window.location.reload();

        }, 1000);
      }
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {
          toast.error(errors[i]);
        }
      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    }
  };

  return (
    <div className={styles.content}>
      <h1 className={commonStyles.title}>Actualizar usuario </h1>
      <div className={styles.section}>
        <h2 className={styles.titleUser}>Usuario</h2>
        <div className={styles.section}>{row.name}</div>
        <h2 className={styles.titleUser}>Número de teléfono</h2>
        <div className={styles.section}>{row.phone}</div>
        <h2 className={styles.titleUser}>Rol</h2>
        <div className={styles.section}>{row.role}</div>
        <CTA isDisabled={loading} text={`Actualizar a ${role}  `} onClick={() => handleUpdateUser()} />
      </div>
    </div>
  );
};

const RestaurantHours = ({ setShowModal }) => {

  const { restaurant, hora, token, user } = useSelector((state) => state.auth)

  const dispatch = useDispatch()
  const { formData, handleInputChange, isDisabled, setIsDisabled } = useForm({
    hour_start: "",
    hour_end: ""
  });



  const handlscheduleChange = async () => {

    try {
      await updateHoursRestaurant(restaurant, formData, token);
      setShowModal(false);
      dispatch(me(restaurant, token, user?.phone))
      toast.success('El horario se cambiado con éxito');
      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {
          toast.error(errors[i]);
        }
      } else {
        toast.error('Error al cambiar el horario. Por favor intente nuevamente');
      }
    }

  }


  return (

    <div className={styles.content}>
      <h1 className={commonStyles.title}>Horario recepción </h1>
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Horario actual</h2>
        <label> Hora Inicial: {hora != null ? hora.hourStart
          : '-'} </label>
        <label> Hora final: {hora != null ? hora.hourEnd : '-'} </label>
      </div>
      <div className={styles.section}>
        <h2 className={styles.sectionTitle}>Cambiar Horarios</h2>
        <label> Hora inicial </label>
        <input

          type='time'
          name="hour_start"
          value={formData.hour_start}
          className={styles.input}
          onChange={handleInputChange}
          placeholder="Contraseña Horario"
        />
        <label> Hora final </label>
        <input
          type='time'
          name="hour_end"
          value={formData.hour_end}
          className={styles.input}
          onChange={handleInputChange}
          placeholder="Nueva Contraseña"
        />
        <CTA
          text="Cambiar Horario"
          onClick={() => handlscheduleChange()}
          isDisabled={isDisabled}
        />
      </div>
    </div>

  );
};



const Approvals = ({ setShowModal }) => {
  const { user, restaurant, token } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [checked, setChecked] = useState(false);

  useEffect(() => {

    setChecked(user.approval)

  }, [])

  const handleChange = (e) => {

    setChecked(e.target.checked);

  }
  const handlscheduleChange = async () => {

    setLoading(true)

    const data = {
      approval: checked
    }

    try {
      const response = await updateStateApproval(user?.id, data, token);
      toast.success('Actualización realizada correctamente');
      setTimeout(() => {
        setShowModal(false)
        dispatch(me(restaurant, token, user?.phone))
      }, 800);



    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {
          toast.error(errors[i]);
        }
      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    }


  }





  return (

    <div className={styles.content}>

      <h1 className={commonStyles.title}>Aprobaciones </h1>
      <Typography textAlign='justify' >Al activar el sistema de aprobaciones todas las órdenes tendrán que ser previamente aprobadas por un Owner o Super Admin. Para agregar usuarios Super Admin dirígete a la página de Usuarios</Typography>
      <Stack mt={3} mb={3} direction="row" spacing={1} justifyContent='center' alignItems="center">
        <Typography>Inactiva</Typography>
        <Switch checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'ant design' }} />
        <Typography>Activa</Typography>
      </Stack>
      <CTA
        text="Guardar Cambios"
        onClick={() => handlscheduleChange()}
        isDisabled={loading}
      />
    </div>

  )

}

ProfileInfo.propTypes = {
  clickedCard: PropTypes.string,
  user: PropTypes.object,
  setShowModal: PropTypes.func.isRequired,
  //subscription: PropTypes.object,
  restaurants: PropTypes.object,
};

RestaurantInfo.propTypes = {
  restaurant: PropTypes.object,
  setRestaurant: PropTypes.func,
  setShowModal: PropTypes.func.isRequired,
};

UserInfo.propTypes = {
  user: PropTypes.object,
  setShowModal: PropTypes.func.isRequired,
};

// SubscriptionInfo.propTypes = {
//   subscription: PropTypes.object,
// };

RestaurantsInfo.propTypes = {
  local: PropTypes.array,
};

UsersManagement.propTypes = {
  setShowModal: PropTypes.func,
};

UserDeleteConfirmation.propTypes = {
  // row: PropTypes.object.isRequired,
  setShowModal: PropTypes.func,
};

export default ProfileInfo;
