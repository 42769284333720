import React, { useEffect } from 'react';
import logo from '../../assets/logo_name_white.png';
import InputTag from 'components/Input';
import useAuth from '../../hooks/useAuth';
import styles from './login.module.scss';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import AuthLogo from 'components/AuthLogo';
import useStore from 'store';
import { useNavigate } from 'react-router-dom';
//import { useAuthNew } from '../../hooks/useAuthNew';
import { useDispatch, useSelector } from 'react-redux';
import { LoginV } from '../../redux/slices/authSlice/thunksV2';

const Login = () => {
  const store = useStore((state) => state);
  const { error } = useSelector((state) => state.auth)
  const { user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const {
    loginData,
    handleLoginChange,
    handleLoginPhoneChange,
    loginUser,
    loginError,
    toRecover,
    disableLogin,
    validateLogin,
    toRegister,
  } = useAuth();

  const navigate = useNavigate();

  // const user = useStore((state) => state.user);

  //const { phone, password } = loginData;

  useEffect(validateLogin, [loginData]);


  const iniSesion = (e, loginData) => {
    store.setDataLogin(loginData);
    e.preventDefault();
    dispatch(LoginV(loginData))
  }


  useEffect(() => {
    if (user) {
      navigate('/');
    }
  }, []);

  return (
    <>
      <div className={styles.imageContainer}>
        <div className={styles.container}>
          <img src={logo} className={styles.logo} alt="cado logo" />
          <form
            className={styles.infoContainer}
            onSubmit={(e) => iniSesion(e, loginData)}
          >
            <div className={styles.styleInfoContainer}>
              <AuthLogo />
              <div className={styles.headerContainer}>
                <p className={styles.authHeader}>Iniciar Sesión</p>
                <p className={styles.authText}>
                  Por favor ingresa tus datos para acceder a Cado.
                </p>
              </div>
              <div className={styles.inputContainer}>
                <div
                  className={styles.individualInputContainer}
                  data-error={error ? error.phone : null}
                >
                  <PhoneInput
                    type="text"
                    value={loginData.phone}
                    name="phone"
                    placeholder="Teléfono"
                    onChange={(e) => handleLoginPhoneChange(e)}
                    className={styles.input}
                    required
                    style={{ padding: '16px 16px', height: '54px' }}
                    defaultCountry="EC"
                    autoComplete="tel"
                    autoCorrect="off"
                  />
                </div>
                <div
                  className={styles.individualInputContainer}
                  data-error={error ? error.password : null}
                >
                  <InputTag
                    type="password"
                    value={loginData.password}
                    name="password"
                    placeholder="Contraseña"
                    onChange={(e) => handleLoginChange(e)}
                    className={styles.input}
                  />
                </div>
                {typeof error === 'string' ? (
                  <span className={styles.errorSpan}>{error}</span>
                ) : (
                  ''
                )}
                <div className={styles.navigateContainer}>
                  <a className={styles.toRecover} onClick={(e) => toRecover(e)}>
                    Recuperar contraseña
                  </a>
                  <a className={styles.register} onClick={(e) => toRegister(e)}>
                    Registro
                  </a>
                </div>
              </div>
            </div>
            <div className={styles.buttonContainer}>
              <input
                type="submit"
                value="Iniciar Sesión"
                disabled={disableLogin}
                className={styles.submitAuth}
              />
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
