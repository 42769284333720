import React, { useEffect, useState } from 'react';
import { getListOfFavoriteSuppliers } from 'api/restaurants';

import Loading from 'components/Loading';
import SuppliersList from './components/SuppliersList';
import NoSuppliers from './components/NoSuppliers';



import { useDispatch, useSelector } from 'react-redux';
import { setSuppliersR } from '../../redux/slices/thunks';
import { HomeLayouts } from '../../components/layouts/HomeLayouts';
import { clearParamsO } from '../../redux/slices/paramsSlice';

const SuppliersParent = ({ restaurantr }) => {
  const { restaurant, token } = useSelector((state) => state.auth)

  const { suppliersByRestaurant, isLoading } = useSelector((state) => state.suppliersReducer)

  const [rerender, setRerender] = useState(false);
  const dispatch = useDispatch()



  useEffect(() => {
    dispatch(clearParamsO())
    dispatch(setSuppliersR(token, restaurant))

  }, [restaurant])



  return (
    <HomeLayouts>

      {
        isLoading ? <Loading /> :

          suppliersByRestaurant.length > 0 ? (
            <SuppliersList suppliers={suppliersByRestaurant} setRerender={setRerender} />
          ) : (
            <NoSuppliers setRerender={setRerender} />
          )}

    </HomeLayouts>

  );
};

export default SuppliersParent;
