
import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'


export const TableMobbileProducts = ( { products, restaurant }) => {



    const navigate = useNavigate()
    return (
     
        <Grid
          
           sx={{ display:{ xs:'flex', md:'none'  }}}
           mt={4}
           container
           border={1}
           borderColor={'#E9E7E2'}
           borderRadius={2}
           >
                <Box  width='100%' >
           
                  
               {
                    products.products.map((product)=>(


                         <Grid 
                         key={product.id}
                         border={1}
                         borderColor={'#E9E7E2'}
                         sx={{ cursor:'pointer', '&:hover':{
                                backgroundColor:'#F9F6F4'
                                 }} }
                         component='div'
                         onClick={()=>navigate(`/productos/producto/${restaurant}/${product.supplierid}/${product.id}` )}                   
                         padding={1}
                         container 
                         display='flex'
                         flexDirection='column'
                                             
                           >
                               <Grid  item md={4}  xs={12}  >
                                    <Typography fontSize={15} fontWeight='bold' > {product.name}</Typography>
                                    <Typography fontSize={13} noWrap   color='gray' > {product.supplier}</Typography>
                               </Grid>
                      
       
                               <Grid container     display='flex'
                           alignItems='center'
                           flexDirection='row'
                           justifyContent='space-between'>
                                <Grid  item padding={1} md={4}  xs={4}  >
                             <Typography fontSize={14} noWrap   fontWeight='bold' >Precio ref.:</Typography>  
                             <Typography fontSize={14}  > ${product.refePrice !== null ? product.refePrice : '-'}</Typography> 
                             
                           </Grid>
                           <Grid  item padding={1} md={4}  xs={4}  >
                           <Typography fontSize={14} noWrap   fontWeight='bold' >Ultimo precio</Typography>  
                           <Typography 
                              fontSize={14} 
                              padding={0.5} 
                              borderRadius={1} 
                              width='55%' 
                              bgcolor={ parseFloat(product.lastPrice)  <= parseFloat(product.refePrice)  ? '#DEECDC' : product.lastPrice === null ?'withe' : '#FAE3DE' }
                               >${product.lastPrice}
                               
                          </Typography>
                           </Grid>
                                </Grid>
                      
                         
                          
                          
                       </Grid>

                    ))
               }


                 

                  
                  
                </Box>
        </Grid>
    
    
      )
}
