import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';

import { getOrders } from 'api/orders';

import useOrders from 'hooks/useOrders';

import DesktopOrders from './DesktopOrders';
import CTA from 'components/CTA';
import SearchBar from 'components/SearchBar';

import Loading from 'components/Loading';
import { getDisplayDate } from 'utils/dates';
import { getArrayOfErrors } from 'utils/errors';

import useStore from 'store';

import styles from './myOrders.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getSubscription } from '../../../../api/user';
import Banner from '../../../../components/Banner/Banner';
import { Box, TablePagination, Typography } from '@mui/material';
import { clearParamsO, setParamsO } from '../../../../redux/slices/paramsSlice';
import { HomeLayouts } from '../../../../components/layouts/HomeLayouts';
import BannerFree from '../../../../components/Banner/BannerFree';
import moment from 'moment';


const MyOrders = ({ restaurantr }) => {
  const options = [10, 15, 20];
  const { restaurant, user, subscription, token } = useSelector((state) => state.auth)
  const { orders_allowed } = useSelector((state) => state.orderReducer)
  const { paramsO, tap, restaurantP } = useSelector((state) => state.params)
  // const user = useStore((state) => state.user);
  const [page, setpage] = useState(0)
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const store = useStore((state) => state);
  const { reorder } = useOrders();
  const [selectedTab, setSelectedTab] = useState(3);
  const [orders, setOrders] = useState(null);
  const [ordersRevision, setOrdersRevision] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    status: 'solicitada,aceptada',
    query: '',
  });



  const handleChangePage = (event, newPage) => {

    if (paramsO) {
      setpage(newPage)
      setParams({
        ...params,
        page: newPage + 1,
        status: paramsO.status,

      })
      dispatch(clearParamsO())
    } else {
      setpage(newPage);
      setParams({ ...params, page: newPage + 1 })
    }

  };

  const handleChangeRowsPerPage = (event) => {

    setpage(0)
    setParams({ ...params, page: 1, limit: parseInt(event.target.value, 10) });

  };

  const navigateToOrder = (e, id) => {
    if (selectedTab === 0) {
      if (e.target.nodeName !== 'BUTTON') {
        navigate(`/ordenes/detalle/${id}/${restaurant}`);
      }
      dispatch(setParamsO({ ...params, page: page + 1, tap: selectedTab, rest: restaurant }))
    } else if (selectedTab === 1) {
      dispatch(setParamsO({ ...params, page: page + 1, status: 'entregada,cancelada', tap: selectedTab, rest: restaurant }))
      if (e.target.nodeName !== 'BUTTON') {
        navigate(`/ordenes/detalle/${id}/${restaurant}`);
      }
    } else {
      dispatch(setParamsO({ ...params, page: page + 1, status: 'revisión', tap: selectedTab, rest: restaurant }))
      if (e.target.nodeName !== 'BUTTON') {
        navigate(`/ordenes/detalle/${id}/${restaurant}`);
      }
    }
  };




  useEffect(() => {
    getOrderRevision()
    setpage(0)
    setSelectedTab(0)
    setParams({
      page: 1,
      limit: 10,
      status: 'solicitada,aceptada',
      query: '',
    })

  }, [restaurant])




  useEffect(() => {
    getOrderRevision()
    getOrdeP()

  }, [page, params])

  const getOrderRevision = async () => {


    try {
      const response = await getOrders(
        {
          page: 1,
          limit: 10,
          status: 'revisión',
          query: '',
          idRestaurant: parseInt(restaurant) || restaurant,
        }, user.id, token
      );
      setOrdersRevision(response.totalCount)
    } catch (error) {



    }





  }

  const getOrdeP = async () => {




    if (paramsO && restaurantP === restaurant) {
      if (tap === 0) {

        setpage(parseInt(paramsO.page - 1))

        const response = await getOrders({
          ...params,
          page: paramsO.page,
          idRestaurant: parseInt(restaurant) || restaurant,
        }, user.id, token);
        setOrders(response);

      } else {
        setSelectedTab(tap)
        setpage(parseInt(paramsO.page - 1))

        const response = await getOrders({
          ...params,
          page: paramsO.page,
          status: paramsO.status,
          idRestaurant: parseInt(restaurant) || restaurant,
        }, user.id, token);
        setOrders(response);

      }

    } else {
      dispatch(clearParamsO())

      const response = await getOrders({
        ...params,
        idRestaurant: parseInt(restaurant) || restaurant,

      }, user.id, token);
      setOrders(response);
    }

  }



  if (!orders) return <HomeLayouts>



  </HomeLayouts>;


  return (

    <HomeLayouts>
      <div>
        {
          subscription && subscription.status === 'cancelada' ? <Banner />
            : null
        }
        {
          orders_allowed?.status === true && orders_allowed.number <= 0 ? <BannerFree />
            : null
        }
        <div className={styles.content}>
          <div className={styles.header}>
            <div>
              <h1 className={styles.title}>Mis Órdenes</h1>
            </div>

            <Box  >

              {subscription?.status !== 'cancelada' && user.roles[0].name !== 'Staff' && (
                <CTA
                  isDisabled={orders_allowed?.status === true && orders_allowed.number <= 0}
                  view="tablet"
                  text="Nueva Orden"
                  onClick={() => {
                    dispatch(clearParamsO())
                    localStorage.setItem('step', 1);
                    localStorage.setItem('state', JSON.stringify({}));
                    navigate('/ordenes/nueva');
                  }}
                  size="small"
                />
              )}
            </Box>


          </div>
          <div className={styles.searchAndFilter}>
            <SearchBar
              query={params.query}
              setQuery={(val) => {
                setParams({ ...params, query: val });
              }}
              placeholder="Buscar en Mis Órdenes"
              name="query"
            />
            {/* <Filter /> */}
          </div>

          <div className={styles.statusTab}>
            {
              user?.approval === false ? null :
                <div
                  className={`${styles.status} ${selectedTab === 3 && styles.active}`}
                  onClick={() => {
                    dispatch(clearParamsO())
                    setpage(0)
                    setSelectedTab(3);
                    setParams({ ...params, page: 1, status: 'revisión' });
                  }}
                >
                  {`Por Aprobar (${ordersRevision})  `}
                </div>
            }
            <div
              className={`${styles.status} ${selectedTab === 0 && styles.active}`}
              onClick={() => {
                dispatch(clearParamsO())
                setpage(0)
                setSelectedTab(0);
                setParams({ ...params, page: 1, status: 'solicitada,aceptada' });
              }}
            >
              Entrantes
            </div>
            <div
              className={`${styles.status} ${selectedTab === 1 && styles.active}`}
              onClick={async () => {
                dispatch(clearParamsO())
                setpage(0)
                setSelectedTab(1);
                setParams({ ...params, page: 1, status: 'entregada,cancelada' });
              }}
            >
              Anteriores
            </div>
          </div>

          <div className={styles.ordersContainer}>
            {isLoading ? (
              <Loading />
            ) : (
              orders && orders.orders.map((order, i) => {
                return (
                  <div
                    key={`${i}_${order.id}`}
                    className={styles.orderWrapper}
                    onClick={(e) => {
                      navigateToOrder(e, order.code);
                    }}
                  >
                    <div className={styles.order}>
                      <div className={styles.orderInformation}>
                        <p className={styles.supplier}>{order.supplier.name}</p>
                        <p className={styles.date}>
                          {order.deliveryDate
                            ? moment(order.deliveryDate).format('DD-MM-YYYY')
                            : moment(order.scheduleDate).format('DD-MM-YYYY')}

                        </p>
                        <label className={`${styles.label} ${styles[order.status]}`}>
                          {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
                        </label>
                      </div>
                      <button
                        className={styles.reorder}
                        onClick={() => {
                          reorder(order.code);
                        }}
                        style={{
                          display: subscription?.status === 'cancelada' || user?.roles[0].name === 'Staff' ? 'none' : null,
                        }}
                      >
                        Reordenar
                      </button>
                    </div>
                  </div>
                );
              })
            )}
          </div>
          {
            !orders ? null :
              <DesktopOrders
                subscription={subscription}
                orders={orders}
                params={params}
                setParams={setParams}
                reorder={reorder}
                navigateToOrder={navigateToOrder}
              />
          }

        </div>
        <Box sx={{ mr: { xs: 0, md: 35 } }} >
          <TablePagination

            component="div"
            labelRowsPerPage='Filas por página'
            rowsPerPageOptions={options}
            count={orders.totalCount}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={parseInt(params.limit)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />

        </Box>
      </div>
    </HomeLayouts>


  );
};

export default MyOrders;
