import axios from "axios";



  export const gatTotalOrdersBymonth = async (id, month, token) => {
    const response = await axios.get( `${process.env.API_URL}/orders/restaurants/${id}/stadistics/by-month?month=${month}`,
    {
      withCredentials:true,
     headers: {
         'Authorization': `Bearer ${token}`,
         'Accept': '*/*',
         'content-type': 'application/json'
         
     }
   }
    );
  
    return response.data;
  };

  export const getSpendSupplier = async (id, month, token) => {
    const response = await axios.get( `${process.env.API_URL}/orders/restaurants/${id}/stadistics/spend-by-supplier?month=${month}`,
    {
      withCredentials:true,
     headers: {
         'Authorization': `Bearer ${token}`,
         'Accept': '*/*',
         'content-type': 'application/json'
         
     }
   }
    );
  
    return response.data;
  };

  export const getSpendCategory = async (id, month, token) => {
    const response = await axios.get( `${process.env.API_URL}/orders/restaurants/${id}/stadistics/spend-by-category?month=${month}`,
    {
      withCredentials:true,
     headers: {
         'Authorization': `Bearer ${token}`,
         'Accept': '*/*',
         'content-type': 'application/json'
         
     }
   }
    );
  
    return response.data;
  };

  export const getSpendbyProduct = async (id, month, token) => {
    const response = await axios.get( `${process.env.API_URL}/orders/restaurants/${id}/stadistics/spend-by-product?month=${month}`,
    {
      withCredentials:true,
     headers: {
         'Authorization': `Bearer ${token}`,
         'Accept': '*/*',
         'content-type': 'application/json'         
     }
   }
    );
  
    return response.data;
  };