import React from 'react';
import PropTypes from 'prop-types';

import paperPlane from 'assets/paperPlane.svg';
import CTA from 'components/CTA';

import styles from '../../../pop-up.module.scss';

const Step4 = ({ setRerender, setShowModal }) => {
  return (
    <div className={styles.content}>
      <h1 className={styles.title}>Solicitud Recibida</h1>
      <h2 className={styles.subtitle}>
        Nuestro equipo ha recibido tu solicitud para agregar a este proveedor y su
        catálogo de productos. ¡Gracias por tu paciencia!
      </h2>
      <img className={styles.icon} src={paperPlane} alt="paper plane" />
      <div className={styles.bottomContainer}>
        <CTA
          text="Ver Mis Proveedores"
          onClick={() => {
            setShowModal(false);
            setRerender(true);
          }}
        />
      </div>
    </div>
  );
};

Step4.propTypes = {
  setRerender: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default Step4;
