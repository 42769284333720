

import { createSlice } from "@reduxjs/toolkit";




const initialState = {

    limit: 15,
    page: 1,
    totalPages: 2,
    totalCount: 29,
    documents:null,
    isLoadingP:false,
    error:''
} 





 export const  documentsSlice = createSlice({

    name:'documents',
    initialState:initialState,
    reducers:{
        startDocuments:(state)=>{
            state.isLoadingP = true
        },
        setDocuments: (state, action )=>{
                               
                state.documents = action.payload;
                state.isLoadingP = false;

        },
        setErrordoc: (state, action )=>{
                               
            state.error = action.payload;
            state.isLoadingP = false;

    },
    clearDocs: (state )=>{
        state.documents = null; 
        state.error = '';
        state.isLoadingP = false;

}
        
    }


})

export const { startDocuments, setDocuments, setErrordoc, clearDocs } = documentsSlice.actions


