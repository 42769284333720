import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useForm from 'hooks/useForm';
import CTA from 'components/CTA';
import PhoneInput from 'react-phone-number-input';

import styles from '../../pop-up.module.scss';

const EditSalesman = ({ updateContact, setShowModal }) => {
  const [phoneValue, setPhoneValue] = useState('');
  const { formData, handleInputChange, isDisabled } = useForm({
    name: '',
  });
  return (
    <div className={styles.content}>
      <h1 className={styles.title}>Cambiar Vendedor</h1>
      <h2 className={styles.subtitle}>Por favor ingresa los datos del nuevo vendedor.</h2>
      <input
        className={styles.input}
        type="text"
        placeholder="Nombre"
        name="name"
        value={formData.name}
        onChange={handleInputChange}
        autoComplete="name"
        autoCorrect="off"
      />
      <PhoneInput
        onChange={setPhoneValue}
        value={phoneValue}
        placeholder="Teléfono"
        type="tel"
        inputMode="tel"
        autoComplete="tel"
        autoCorrect="off"
        defaultCountry="EC"
        name="phone"
        className={styles.input}
      />
      <div>
        <CTA
          text="Guardar"
          isDisabled={isDisabled}
          onClick={() =>
            updateContact({
              name: formData.name,
              phone: phoneValue,
            })
          }
        />
        <CTA
          text="Cancelar"
          color="red"
          onClick={() => {
            setShowModal(false);
          }}
        />
      </div>
    </div>
  );
};

EditSalesman.propTypes = {
  updateContact: PropTypes.func.isRequired,
  setShowModal: PropTypes.func.isRequired,
};

export default EditSalesman;
