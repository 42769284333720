import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';

import { getSuppliers, createNewSupplier } from 'api/suppliers';
import { addSupplierToRestaurantFavorites } from 'api/restaurants';
import CTA from 'components/CTA';
import { getArrayOfErrors } from 'utils/errors';

import { toast } from 'react-hot-toast';

import styles from '../../../pop-up.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setSuppliersR } from '../../../../../redux/slices/thunks';

const Step2 = ({ formData, handleInputChange, isDisabled, setStep, step, state }) => {
  const dispatch = useDispatch()
  const { restaurant, token } = useSelector((state) => state.auth)
  const [phoneValue, setPhoneValue] = useState('');



  const createSupplier = async () => {
    try {
      const data = await getSuppliers({ limit: 1, page: 1, query: formData.ruc }, token);

      if (data.length > 0) {
        await addSupplierToRestaurantFavorites(
          {
            supplierId: data[0].id,
            contactName: formData.contactName,
            contactPhone: phoneValue,
          },
          parseInt(restaurant),
          token
        );
        dispatch(setSuppliersR(token, restaurant))
        setStep(3);
      } else {
        await createNewSupplier(
          {
            name: state.userInput,
            identifier: formData.ruc,
            contactName: formData.contactName,
            contactPhone: phoneValue,
          },
          parseInt(restaurant),
          token
        );
        setStep(4);
        dispatch(setSuppliersR(token, restaurant))
      }
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {
          toast.error(errors[i]);
        }
      } else {
        toast.error('Error al añadir proveedor');
      }
    }
  };



  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <Back className={styles.back} onClick={() => setStep(1)} />
        <h1 className={styles.title}>Añadir Vendedor</h1>
      </div>
      <h2 className={styles.subtitle}>Por favor ingresa los datos del vendedor.</h2>
      <input
        className={styles.input}
        type="text"
        placeholder="Nombre"
        name="contactName"
        value={formData.contactName}
        onChange={handleInputChange}
        autoComplete="name"
        autoCorrect="off"
      />
      <PhoneInput
        onChange={setPhoneValue}
        value={phoneValue}
        placeholder="Teléfono"
        type="tel"
        inputMode="tel"
        autoComplete="tel"
        autoCorrect="off"
        className={styles.input}
        defaultCountry="EC"
      />
      <div className={styles.bottomContainer}>
        <div className={styles.stepsContainer}>
          <div className={`${styles.dot} ${step === 1 && styles.current}`}></div>
          <div className={`${styles.dot} ${step === 2 && styles.current}`}></div>
        </div>
        <CTA text="Continuar" isDisabled={isDisabled} onClick={createSupplier} />
      </div>
    </div>
  );
};

Step2.propTypes = {
  formData: PropTypes.object,
  handleInputChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  setStep: PropTypes.func,
  step: PropTypes.number,
  state: PropTypes.object,
};

export default Step2;
