import React from 'react';
import { Routes, Route } from 'react-router-dom';

import MyOrders from './components/MyOrders';
import OrderDetails from './components/OrderDetails';
import RecieveOrder from './components/RecieveOrder';
import OrderConfirmation from './components/OrderConfirmation';
import NewOrder from 'views/NewOrder';
import NotFound from 'router/components/NotFound';

import subRoutes from './subRoutes';

const Orders = ({ restaurant }) => {

  return (
    <>
      <Routes>
        <Route path={subRoutes.orders} element={<MyOrders restaurant={restaurant} />} />
        <Route path={subRoutes.details} element={<OrderDetails />} />
        <Route path={subRoutes.recieve} element={<RecieveOrder />} />
        <Route path={subRoutes.confirmation} element={<OrderConfirmation />} />
        <Route path={subRoutes.newOrder} element={<NewOrder />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default Orders;
