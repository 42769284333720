

import { createSlice } from "@reduxjs/toolkit";




const initialState = {

    limit: 15,
    page: 1,
    totalPages: 2,
    totalCount: 29,
    products:null,
    product:null,
    isLoadingP:false,
    error:''
} 

 



 export const  productsSlice = createSlice({

    name:'products',
    initialState:initialState,
    reducers:{
        startProducts:(state)=>{
            state.isLoadingP = true
        },
        setProducts: (state, action )=>{
                               
                state.products = action.payload;
                state.isLoadingP = false;

        },
        setProduct: (state, action )=>{
                               
            state.product = action.payload;
            state.isLoadingP = false;

    },
        setErrorsProducts: (state, action )=>{
                               
            state.error = action.payload;
            state.isLoadingP = false;

    },
    clearProducts: (state )=>{
        state.products = null; 
        state.error = '';
        state.product=null;
        state.isLoadingP = false;
},
clearProduct: (state )=>{
      
    state.product=null;
    state.isLoadingP = false;
}
        
    }


})

export const { startProducts,setProduct, clearProduct, setProducts, setErrorsProducts, clearProducts } = productsSlice.actions


