
import React from 'react'
import Sidebar from '../Sidebar'
import { Box, Container } from '@mui/material'
import HamburgerMenu from '../HamburgerMenu';
import { Toaster } from 'react-hot-toast'
import { setRestaurant } from '../../redux/slices/restaurantSlice';
import { useSelector } from 'react-redux';
import { useState } from 'react';

export const HomeLayouts = ({ children }) => {
    const { user, restaurant, subscription, restaurantsSelect } = useSelector((state) => state.auth)
    const [rerender, setRerender] = useState(false);
    const [restaurantId, setRestaurantId] = useState(null);
    const [restaurantIdToState, setRestaurantIdToState] = useState(false);





    return (

        <>
            <HamburgerMenu

            />
            <Sidebar />
            <div id="app-wrapper">


                {children}



            </div>

            <Toaster />
        </>



    )
}
