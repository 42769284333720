import React from 'react';
import styles from './StyleRecover/confirm.module.scss';

const ConfirmRecover = ({ toLogin }) => (
  <div className={styles.infoContainer}>
    <div className={styles.headerContainer}>
      <p className={styles.authHeader}>Contraseña Actualizada</p>
      <p className={styles.authText}>
        Tu contraseña se ha actualizado correctamente. Ahora puedes entrar con tu nueva
        contraseña a Cado.
      </p>
    </div>
    <button className={styles.submitAuth} onClick={(e) => toLogin(e)}>
      Ir a Iniciar Sesión
    </button>
  </div>
);

export default ConfirmRecover;
