import React, { useEffect } from 'react';
import Loading from 'components/Loading';
import useAuth from '../../hooks/useAuth';
import { useDispatch } from 'react-redux';
import { clearAuthV2 } from '../../redux/slices/authSlice/authSliceV2';
//import { useAuthNew } from '../../hooks/useAuthNew';

const Logout = ({ deleteRestaurant }) => {

  const dispatch = useDispatch()


  useEffect(() => {
    clearA();
    //deleteRestaurant();
  }, []);

  const clearA = () => {

    setTimeout(() => {
      dispatch(clearAuthV2())

    }, 700);

  }


  return <Loading />;
};

export default Logout;
