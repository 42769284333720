import React from 'react';
import PropTypes from 'prop-types';
import styles from './quantityAmount.module.scss';
import { Grid, Typography } from '@mui/material';


const QuantityAmount = ({ product, total, subscription }) => {



  const getAmount = () => {

    if (typeof product.deliveredQuantity === 'number') {
      return product.deliveredQuantity;
    }
    if (typeof product.confirmedQuantity === 'number') {
      return product.confirmedQuantity;
    }
    return product.requestedQuantity;
  };


  return (


    product.confirmedQuantity === null && product.deliveredQuantity === null || product.deliveredQuantity === product.requestedQuantity ?
      <div className={styles.quantityContainer}>
        {`${getAmount()} ${product.measurementUnit}`}

      </div>

      :
      product.confirmedQuantity === null && product.deliveredQuantity != product.requestedQuantity ?

        <div className={styles.quantityContainer}>
          <p>{getAmount()} {product.measurementUnit}</p>  <p className={styles.strikethrough}>  <s> {product.requestedQuantity} {product.measurementUnit} </s></p>
        </div>
        : product.confirmedQuantity != null && product.confirmedQuantity === product.requestedQuantity ?
          <div className={styles.quantityContainer}>
            {`${getAmount()} ${product.measurementUnit}`}

          </div>
          :

          <div className={styles.quantityContainer}>
            <p>{getAmount()} {product.measurementUnit}</p>  <p className={styles.strikethrough}>  <s> {product.requestedQuantity} {product.measurementUnit} </s></p>
          </div>



  );
};

QuantityAmount.propTypes = {
  product: PropTypes.object.isRequired,
};

export default QuantityAmount;




