import React, { useEffect, useState } from 'react'
import styles from './products.module.scss'
import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loading from 'components/Loading';

import { Box, Divider, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material';
import { TableHistory } from './components/TableHistory';
import { TableHistoyMobil } from './components/TableHistoyMobil';
import { detailProduct } from '../../api/restaurants';
import { month, monthsList, year, years } from '../../utils/mounths';
import { formatNumber } from '../../utils/numbers';
import { HomeLayouts } from '../../components/layouts/HomeLayouts';
import { useDispatch, useSelector } from 'react-redux';
import { clearProduct, setProduct } from '../../redux/slices/productsSlice';
import { setProductCa } from '../../redux/slices/thunks';
import moment from 'moment/moment';


export const Product = () => {
    const { token } = useSelector((state) => state.auth)
    const { restaurant, id_supplier, id_product, } = useParams();
    const { product, isLoadingP } = useSelector((state) => state.productsReducer)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // const [product, setproduct] = useState()
    const [prices, setprices] = useState()
    const [fecha, setfecha] = useState()
    const [monthC, setMonth] = useState(month)
    const [yearC, setYearC] = useState(year)
    const yearsList = years()
    const monthListf = monthsList(yearC)





    const handleChange = async (event) => {

        setMonth(event.target.value);

        const response = await detailProduct(
            {
                restaurant_id: restaurant,
                supplier_id: id_supplier,
                product_id: id_product,
                month: event.target.value,
                year: yearC
            }, token)

        dispatch(setProduct(response))
        setprices(response.prices[0])
        setfecha(response.firstBuy)

    };
    const handleChangeY = async (event) => {

        setYearC(event.target.value);
        const response = await detailProduct(
            {
                restaurant_id: restaurant,
                supplier_id: id_supplier,
                product_id: id_product,
                month: monthC,
                year: event.target.value
            }, token)
        dispatch(setProduct(response))
        setprices(response.prices[0])
        setfecha(response.firstBuy)

    };





    const navegar = () => {
        dispatch(clearProduct())
        navigate('/productos')


    }



    useEffect(async () => {

        dispatch(setProductCa(restaurant, id_supplier, id_product, month, year, token))

    }, [])



    return (

        <>

            <HomeLayouts>
                {



                    !product ? <Loading /> :

                        product && Object.keys(product).length < 1 ?

                            <Box

                                padding={2}
                                mt={12}
                            >
                                <Typography color={'#D0D4CA'} fontWeight={'bold'} textAlign={'center'} >No hay información, para este producto</Typography>
                            </Box>

                            :

                            <>

                                <Box bgcolor='#F9F6F4'  >
                                    <div className={styles.content}>

                                        <div className={styles.header}>
                                            <Box display='flex' flexDirection='row' alignItems='center' justifyContent='center' >
                                                <Back className={styles.back} onClick={navegar




                                                } />
                                                <h1 className={styles.title}>Producto</h1>
                                            </Box>
                                        </div>

                                        <Grid container direction='column'  >
                                            <Typography fontSize={24} fontWeight='bold' >{product.name}</Typography>
                                            <Typography  >Codigo: {product.code} </Typography>
                                            <Typography fontSize={20} >{product.supplier}</Typography>
                                            <Box display='flex' flexDirection='row' >
                                                <Typography mr={5} fontSize={18} > {product.measurementUnit} </Typography>
                                                <Typography fontSize={18} > Primera compra: {moment(product.firstBuy).format('DD/MM/YYYY')}</Typography>
                                            </Box>

                                        </Grid>
                                        <Grid
                                            container
                                            direction='row'
                                            justifyContent='space-between' >


                                            <Grid
                                                sx={{ marginBottom: { xs: 2, md: 0 } }}
                                                bgcolor='white'
                                                padding={2}
                                                borderRadius={2}
                                                item
                                                sm={12}
                                                xs={12}
                                                md={4}
                                                border={1}
                                                borderColor={'#E9E7E2'} >
                                                <Typography fontWeight='bold' fontSize={20} color='#797877' >Ultimo precio</Typography>
                                                <Box display='flex' flexDirection='row' alignItems='center' sx={{ width: { md: 240 }, gap: { xs: 1, md: 0 }, justifyContent: { xs: 'center', md: 'space-between', sm: 'center' } }} >
                                                    <Typography fontWeight='bold' fontSize={24} color={formatNumber(product.price) > formatNumber(product.prices[0].priceRefencial) ? '#ed694c' : '#49A35B'}  >${product.price != null ? formatNumber(product.price) : 0}</Typography>
                                                    <Typography fontWeight='bold' fontSize={24} color='#797877' >-</Typography>
                                                    <Typography fontWeight='bold' fontSize={18} color='#797877' > {product.createdAt != null ? moment(product.createdAt).format('DD/MM/YYYY') : null}  </Typography>
                                                </Box>
                                            </Grid>

                                            <Grid

                                                sx={{ marginBottom: { xs: 2, md: 0 } }}
                                                bgcolor='white'
                                                padding={3}
                                                borderRadius={2}
                                                item
                                                xs={12}
                                                md={4.8}
                                                border={1}
                                                borderColor={'#E9E7E2'} >
                                                <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' >
                                                    <Box display='flex' flexDirection='column' >
                                                        <Typography fontWeight='bold' fontSize={20} color='#797877' >Precio de referencia</Typography>
                                                        <Box mr={2} display='flex' flexDirection='row' justifyContent='space-between' >
                                                            <Typography fontWeight='bold' fontSize={18}  >
                                                                $ {product.prices[0].priceRefencial ? `${formatNumber(product.prices[0].priceRefencial)}/${product.measurementUnit}` : 0}
                                                            </Typography>
                                                            {/* <Typography fontSize={16} color='#797877' >Editar</Typography>                 */}
                                                        </Box>


                                                    </Box>

                                                    <Divider orientation="vertical" sx={{ height: 50, borderColor: 'black' }} flexItem />

                                                    <Box sx={{ marginLeft: { md: 1, xs: 1 } }} display='flex' flexDirection='row' gap={2} justifyContent='space-between' >
                                                        <Box alignItems='center' display='flex' flexDirection='column' justifyContent='space-between' >
                                                            <Typography fontWeight='bold' fontSize={18} color='#797877' >Max</Typography>
                                                            <Typography fontWeight='bold' fontSize={18}  >
                                                                ${product.prices[0].princeMax ? formatNumber(product.prices[0].princeMax) : 0}
                                                            </Typography>
                                                        </Box>
                                                        <Box display='flex' flexDirection='column' alignItems='center' justifyContent='space-between' >
                                                            <Typography fontWeight='bold' fontSize={18} color='#797877' >Min</Typography>
                                                            <Typography fontWeight='bold' fontSize={18}  >${product.prices[0].priceMin ? formatNumber(product.prices[0].priceMin) : 0} </Typography>
                                                        </Box>

                                                    </Box>
                                                </Box>


                                            </Grid>

                                            <Grid

                                                xs={12}
                                                bgcolor='white'
                                                padding={3}
                                                borderRadius={2}
                                                item
                                                md={3}
                                                border={1}
                                                borderColor={'#E9E7E2'} >
                                                <Typography fontWeight='bold' fontSize={20} color='#797877' >Total compras</Typography>
                                                <Box sx={{ justifyContent: { xs: 'space-around', md: 'space-between' } }} display='flex' flexDirection='row' alignItems='center' >
                                                    <Typography fontWeight='bold' fontSize={18} >{product.prices[0].stock ? `${product.prices[0].stock} ${product.measurementUnit}` : 0}</Typography>
                                                    <Typography fontWeight='bold' fontSize={18} >${product.prices[0].total ? formatNumber(product.prices[0].total) : 0}</Typography>
                                                </Box>
                                            </Grid>

                                        </Grid>

                                    </div>
                                </Box>

                                <Box component={'div'} className={styles.content} >

                                    <Box
                                        alignItems='center'
                                        display='flex'
                                        flexDirection='row'

                                    >



                                        <Grid
                                            spacing={2}
                                            container
                                            direction='row'
                                            alignItems='center'

                                        >
                                            <Grid item >

                                                <Typography
                                                    mr={6}
                                                    fontSize={20}
                                                    fontWeight='bold' >
                                                    Historial de compras
                                                </Typography>
                                            </Grid>


                                            <Grid
                                                item
                                                md={1.5}
                                                xs={12}
                                            >



                                                <FormControl
                                                    fullWidth
                                                >

                                                    <Select
                                                        size='small'
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                        id="demo-simple-select"
                                                        value={monthC}
                                                        onChange={handleChange}
                                                    >

                                                        {
                                                            monthListf.map((month) => {

                                                                return <MenuItem key={month.value} value={month.value}>{month.name} </MenuItem>

                                                            })
                                                        }


                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid
                                                item
                                                md={1.5}
                                                xs={12}
                                            >
                                                <FormControl
                                                    fullWidth
                                                >
                                                    <Select
                                                        size='small'
                                                        value={yearC}
                                                        onChange={handleChangeY}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Without label' }}
                                                    >

                                                        {
                                                            yearsList.map((year) => {

                                                                return <MenuItem key={year} value={year}>{year} </MenuItem>

                                                            })
                                                        }
                                                    </Select>

                                                </FormControl>
                                            </Grid>

                                        </Grid>

                                    </Box>


                                    {
                                        isLoadingP ? <Loading /> :

                                            product.history.length < 1 ?

                                                <Box display='flex' justifyContent='center'  >
                                                    <Typography
                                                        color='gray' > No se encontro historial de compras para el producto </Typography>
                                                </Box>

                                                : <>
                                                    <TableHistory
                                                        priceRef={product.prices[0]?.priceRefencial}
                                                        unidad={product.measurementUnit}
                                                        history={product.history} />
                                                    <TableHistoyMobil
                                                        priceRef={product.prices[0]?.priceRefencial}
                                                        unidad={product.measurementUnit}
                                                        history={product.history}

                                                    />
                                                </>
                                    }
                                </Box>

                            </>
                }
            </HomeLayouts>


        </>

    )
}
