

import { createSlice } from "@reduxjs/toolkit";

//status: 'cheking' | 'not-authenticated' | 'get-user' | 'not-complete' | 'authenticated'


const initialState = {

    user:null,
    subscription:null,
    isLoadin:false,
    error:null,
    token:null,
    status: 'not-authenticated',     
    restaurantsSelect:null,
    restaurantList:null,   
    restaurant:null,
    hora:null,
    errorToken:false
   

} 


 


 export const  authSliceV2 = createSlice({

    name:'auth',
    initialState:initialState,
    reducers:{
        startAuthV2:(state)=>{
            state.isLoadin = true,
            state.status = 'cheking'
           
        },
        setJWT:(state, action )=>{
            state.token=action.payload,
            state.isLoadin=false,
            state.error=null,
            state.errorToken=false

        },
        setUserV2: (state, action )=>{
                            
            state.user=action.payload.user,
            state.isLoadin=false,
            state.error=null,
            state.status=action.payload.status      
            state.restaurantsSelect=action.payload.restaurantSelect,
            state.restaurantList=action.payload.restaurantList,                     
            state.restaurant=action.payload.restaurant,
            state.errorToken=false
           
          
        },
        setSubscriptionV2: (state, action )=>{
                          
            state.subscription = action.payload;
           
        },
        setErrorV2: (state, action )=>{
           
            state.user=null,
            state.subscription=null,
            state.isLoadin=false,
            state.error=action.payload,
            state.status= 'not-authenticated',    
            state.restaurant=null,
            state.errorToken=false
        },
        setRestaurantV2: (state, action )=>{
                          
            state.restaurant = action.payload;
           
        },
        clearAuthV2: (state )=>{

            state.user=null,
            state.subscription=null,
            state.isLoadin=false,
            state.error=null,
            state.token=null,
            state.status= 'not-authenticated',     
            state.restaurantsSelect=null,
            state.restaurantList=null,   
            state.restaurant=null,
            state.hora=null,
            state.errorToken=false
           
        },
        setHoraV2:(state, action )=> {

            state.hora = action.payload
    
        },
        setErrorToken:(state, action )=> {

            state.errorToken = action.payload
    
        },
                 }

})

export const {setJWT,startAuthV2, setUserV2, setSubscriptionV2,setRestaurantV2,  clearAuthV2, setErrorV2, setHoraV2, setErrorToken } = authSliceV2.actions


