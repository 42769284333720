import React, { useState, useEffect } from 'react';
import { getSuppliers } from 'api/suppliers';

import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import Step3 from './steps/Step3';
import Step4 from './steps/Step4';

import { getArrayOfErrors } from 'utils/errors';

import { toast } from 'react-hot-toast';
import useForm from 'hooks/useForm';
import { useSelector } from 'react-redux';

const AddSupplier = ({ setRerender, setShowModal }) => {
  const { token } = useSelector((state) => state.auth)
  const [step, setStep] = useState(1);
  const [suppliers, setSuppliers] = useState([]);
  const [query, setQuery] = useState('');

  const { formData, handleInputChange, isDisabled, setFormData } = useForm({
    ruc: '',
    contactName: '',
  });

  const [state, setState] = useState({
    activeSuggestion: 0,
    filteredSuggestions: [],
    showSuggestions: false,
    userInput: '',
  });

  const stepComponents = {
    1: (
      <Step1
        suppliers={suppliers}
        state={state}
        setState={setState}
        formData={formData}
        handleInputChange={handleInputChange}
        isDisabled={isDisabled}
        setStep={setStep}
        step={step}
        setQuery={setQuery}
        setFormData={setFormData}
      />
    ),
    2: (
      <Step2
        suppliers={suppliers}
        state={state}
        setState={setState}
        formData={formData}
        handleInputChange={handleInputChange}
        isDisabled={isDisabled}
        setStep={setStep}
        step={step}
      />
    ),
    3: <Step3 setRerender={setRerender} setShowModal={setShowModal} />,
    4: <Step4 setRerender={setRerender} setShowModal={setShowModal} />,
  };

  useEffect(async () => {
    try {
      const data = await getSuppliers({ limit: 3, page: 1, query: query }, token);
      let suggestions = [];
      for (let i = 0; i < data.length; i++) {
        suggestions.push(data[i].name);
      }
      setSuppliers(suggestions);
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {
          toast.error(errors[i]);
        }
      } else {
        toast.error('Error al añadir proveedor');
      }
    }
  }, [query]);

  return stepComponents[step];
};

export default AddSupplier;
