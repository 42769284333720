import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getArrayOfErrors } from 'utils/errors';
import {
  getListOfFavoriteSuppliers,

} from '../../../../api/restaurants';
import Loading from 'components/Loading';
import PopUp from 'components/PopUp';
import AddSupplier from 'components/PopUp/components/AddSupplier';
import SearchBar from 'components/SearchBar';
import CTA from 'components/CTA';
import commonStyles from '../../../../views/NewOrder/components/Suppliers/suppliers.module.scss';
import styles from './suppliersList.module.scss';
import Pagination from 'react-js-pagination';
import { useDispatch, useSelector } from 'react-redux';
import Banner from '../../../../components/Banner/Banner';





const SuppliersList = ({ suppliers, setRerender }) => {
  const { restaurant, subscription, user, token } = useSelector((state) => state.auth)
  const { supplierLIstALl } = useSelector((state) => state.suppliersReducer)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [newSuppliers, setNewSuppliers] = useState(suppliers);
  const [query, setQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [postsPerPage, setPostsPerPage] = useState(20);
  const [activePage, setActivePage] = useState(1);
  const [isMounted, setIsmounted] = useState(true);



  useEffect(() => {
    if (isMounted) {
      supliersLÑist()
    }

  }, [query, restaurant]);

  useEffect(() => {
    setNewSuppliers(suppliers)

    return () => {

      setIsmounted(false)
    }
  }, [])

  useEffect(() => {

    supliersLÑist()

  }, [currentPage])


  const supliersLÑist = async () => {

    // setLoading(true)

    try {
      const data = await getListOfFavoriteSuppliers(token, {
        limit: postsPerPage,
        page: currentPage,
        query,
        idRestaurant: parseInt(restaurant),
      });

      setTimeout(() => {
        setNewSuppliers(data.suppliers);
        setTotal(data.totalCount);
        setLoading(false)
      }, 500);
    } catch (error) {

      if (error.response) {


        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {

          toast.error(errors[i]);
        }


      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }


    }


    //dispatch(setSuppliersListAll(data.suppliers))


  }



  const handlePageChange = async (pageNumber) => {

    setActivePage(pageNumber);
    const data = await getListOfFavoriteSuppliers(token, {
      limit: postsPerPage,
      page: pageNumber,
      query,
      idRestaurant: parseInt(restaurant),
    });
    //dispatch(setSuppliersListAll(data.suppliers))
    setNewSuppliers(data.suppliers);
  };


  if (loading) {
    return <Loading />
  }

  return (
    <>
      {
        subscription && subscription.status === 'cancelada' ? <Banner />
          : null
      }
      <div className={styles.spaceElements}>

        <div className={commonStyles.content}>

          <PopUp setShowModal={setShowModal} showModal={showModal}>
            <AddSupplier setRerender={setRerender} setShowModal={setShowModal} />
          </PopUp>
          <div className={commonStyles.header}>
            <h1 className={commonStyles.title}>Mis Proveedores </h1>
            <div className={styles.actions}>
              <SearchBar

                name="query"
                query={query}
                placeholder="Buscar proveedor"
                setQuery={setQuery}
              />
              {
                subscription && subscription.status !== 'cancelada' && user.roles[0].name !== 'Staff' && (
                  <CTA
                    size="small"
                    text="Añadir Proveedor"
                    view="tablet"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  />
                )
              }

            </div>
          </div>
          <h2 className={styles.subtitle}>Mis Proveedores</h2>
          <div className={commonStyles.suppliersContainer}>
            {newSuppliers && newSuppliers.map((supplier, i) => {



              return (
                <div
                  key={i}
                  className={commonStyles.supplier}
                  style={{
                    backgroundImage: `url(${supplier.coverPhoto
                      ? supplier.coverPhoto
                      : 'https://images.unsplash.com/photo-1542838132-92c53300491e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80'
                      })`,
                  }}
                  onClick={() => {
                    navigate(`/proveedores/detalle/${supplier.id}`);
                  }}
                >
                  {supplier.name}
                </div>
              );
            })}
          </div>
          <div className={commonStyles.pagination}>
            <Pagination
              activePage={activePage}
              itemsCountPerPage={postsPerPage}
              totalItemsCount={total}
              pageRangeDisplayed={10}
              innerClass={commonStyles.pagination}
              linkClass={commonStyles.linkClass}
              activeClass={commonStyles.active}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </>

  );
};

export default SuppliersList;