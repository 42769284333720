import React from 'react';
import styles from './recoverpassword.module.scss';
import logo from 'assets/logo_name_white.png';
import useAuth from 'hooks/useAuth';
import RecoverPassword from './RecoverPassword';

const RecoverWrapper = () => {
  const {
    recoveryStep,
    recoverPasswordState,
    handleRecoverChange,
    handleRecoverPhoneChange,
    recoverError,
    disablePassRecovery,
    recoverPassword,
    verifyRecoveryCode,
    toLogin,
    handleRecoveryCode,
    validateRecoverPassword,
    recoveryCode,
    verifyRecoveryError,
    disableRecoveryCode,
    validateRecoveryCode,
    handleRecoveryCodeChange,
    goBackRecovery,
  } = useAuth();





  return (
    <div className={styles.imageContainer}>
      <div className={styles.container}>
        <img src={logo} className={styles.logo} alt="cado logo" />
        <RecoverPassword
          recoverPasswordState={recoverPasswordState}
          handleRecoverChange={handleRecoverChange}
          handleRecoverPhoneChange={handleRecoverPhoneChange}
          handleRecoveryCodeChange={handleRecoveryCodeChange}
          disablePassRecovery={disablePassRecovery}
          recoverError={recoverError}
          toLogin={toLogin}
          recoverPassword={recoverPassword}
          validateRecoverPassword={validateRecoverPassword}
          verifyRecoveryCode={verifyRecoveryCode}
          recoveryStep={recoveryStep}
          handleRecoveryCode={handleRecoveryCode}
          recoveryCode={recoveryCode}
          recoverPasswordState={recoverPasswordState}
          verifyRecoveryError={verifyRecoveryError}
          disableRecoveryCode={disableRecoveryCode}
          validateRecoveryCode={validateRecoveryCode}
          recoverPasswordState={recoverPasswordState}
          goBackRecovery={goBackRecovery}
        />
      </div>
    </div>
  );
};

export default RecoverWrapper;
