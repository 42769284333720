import React, { useState } from 'react';
import PropTypes from 'prop-types';

import DatePicker from 'components/DatePicker';

import { formatDate } from 'utils/dates';
import styles from '../../home.module.scss';

const MobileDateHeader = ({

  monday,
  tuesday,
  wednesday,
  thursday,
  friday,
  saturday,
  sunday,
  date,
  setDate,
  refObj,
}) => {
  const [isSelected, setIsSelected] = useState(new Date().toISOString().split('T')[0]);
  const executeScroll = (i) => refObj[i].current.scrollIntoView();
  const array = [
    {
      initial: 'L',
      date: monday,
    },
    {
      initial: 'M',
      date: tuesday,
    },
    {
      initial: 'X',
      date: wednesday,
    },
    {
      initial: 'J',
      date: thursday,
    },
    {
      initial: 'V',
      date: friday,
    },
    {
      initial: 'S',
      date: saturday,
    },
    {
      initial: 'D',
      date: sunday,
    },
  ];
  return (
    <div className={styles.mobileDateHeader}>
      <DatePicker startDate={monday} endDate={sunday} date={date} setDate={setDate} />
      <div className={styles.dateSelectorContainer}>
        {array.map((day, i) => {
          return (
            <div
              key={i}
              onClick={() => {
                executeScroll(i);
                setIsSelected(day.date.split('T')[0]);
              }}
              className={
                day.date.split('T')[0] === isSelected
                  ? `${styles.smallDate} ${styles.selected} `
                  : styles.smallDate
              }
            >
              <div>{formatDate(day.date, 'mobile')}</div>
              <div className={styles.initial}>{day.initial}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

MobileDateHeader.propTypes = {
  monday: PropTypes.string.isRequired,
  tuesday: PropTypes.string.isRequired,
  wednesday: PropTypes.string.isRequired,
  thursday: PropTypes.string.isRequired,
  friday: PropTypes.string.isRequired,
  saturday: PropTypes.string.isRequired,
  sunday: PropTypes.string.isRequired,
  date: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired,
  refObj: PropTypes.object.isRequired,
};

export default MobileDateHeader;
