import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';
import { toast } from 'react-hot-toast';
import { getOrderById, cancelOrder } from 'api/orders';
import { useReactToPrint } from 'react-to-print';
import useOrders from 'hooks/useOrders';
import printIcon from 'assets/print.svg';
import { getArrayOfErrors } from 'utils/errors';
import { getDisplayDate } from 'utils/dates';

import CTA from '../../../../components/CTA';
import ProductReview from 'components/ProductReview';

import useStore from 'store';

import commonStyles from 'views/NewOrder/components/Summary/summary.module.scss';
import { useSelector } from 'react-redux';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import PopUp from '../../../../components/PopUp';
import CancelOrder from '../../../../components/PopUp/components/CancelOrder/CancelOrder';
import Banner from '../../../../components/Banner/Banner';
import PrintComponentOrder from '../../../../components/PrintComponent/PrintComponentOrder';
import { HomeLayouts } from '../../../../components/layouts/HomeLayouts';
import { usePopUp } from '../../../../hooks/usePopUp';
import moment from 'moment';


const OrderDetails = ({ route }) => {
  const { restaurantList, restaurant, subscription, user, token } = useSelector((state) => state.auth)
  const { id, restaurantId } = useParams();



  const componentRef = useRef();

  const { reorder } = useOrders();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [orderL, setOrderL] = useState([]);
  const [dateRequest, setDateRequest] = useState(null)
  const [dateschedule, setDateschedule] = useState(null)
  const [isSame, setIsSame] = useState(null)

  const { state, } = useLocation()



  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handleCancel = async () => {

    try {

      const cancelledOrder = await cancelOrder(parseInt(restaurant), order.id, token, user?.id);

      toast.success('Orden cancelada');
      setOrder(cancelledOrder);

    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {

          toast.error(errors[i]);
        }
      } else {

        toast.error('Error interno. Inténtelo más tarde');
      }
    }
  };




  useEffect(async () => {

    try {

      if (restaurantId) {
        localStorage.removeItem('restaurant')
        const order = await getOrderById(user.id, parseInt(restaurantId), id, token)
        setOrder(order);
        const orL = [{ date: '0', orders: [] },]
        orL[0].orders.push(order)
        const dateR = moment(order.requestDate).format('YYYY-MM-DD')
        const dateS = moment(order.scheduleDate).format('YYYY-MM-DD')
        const isSa = moment(dateR).isSame(dateS);
        setIsSame(isSa)
        setDateRequest(dateR)
        setDateschedule(dateS)
        setOrderL(orL)
      } else {
        const order = await getOrderById(user.id, parseInt(restaurantId), id, token);

        setOrder(order);
      }
    } catch (error) {
      if (error.response) {


        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {

          toast.error(errors[i]);
        }
      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    }
  }, [order?.status]);



  if (!order) return <HomeLayouts>


  </HomeLayouts>;

  return (
    <HomeLayouts>

      <div className={commonStyles.gridColors}>
        {
          subscription && subscription.status === 'cancelada' ? <Banner />
            : null
        }
        <PopUp setShowModal={setShowModal} showModal={showModal}>
          <CancelOrder
            setShowModal={setShowModal}
            suppliuer={order.supplier.name}
            date={order.deliveryDate
              ? moment(order.deliveryDate).format('DD-MM-YYYY')
              : moment(order.scheduleDate).format('DD-MM-YYYY')}
            onClick={() => handleCancel(order.id)}
          />
        </PopUp>

        <div className={commonStyles.header}>
          <Back className={commonStyles.back} onClick={() => navigate('/ordenes', { state })} />
          <h1 className={commonStyles.title}>Detalle</h1>
          {
            order?.status != 'cancelada' ?
              <Box
                sx={{ display: { xs: 'flex', md: 'none' } }}

                alignItems='center'
                justifyContent='end' justifyItems='end' mb={-2.5} width={250} height={60} >
                <button className={commonStyles.printButton} onClick={handlePrint}>

                  <img height={22} src={printIcon} alt="printIcon" />

                </button>
              </Box> : null

          }

        </div>
        <div className={commonStyles.content}>
          <div className={commonStyles.leftSide}>
            {
              order?.status != 'cancelada' ?
                <Box
                  alignItems='center' justifyContent='end' justifyItems='end' mb={-2.5}  >
                  <button className={commonStyles.printButton} onClick={handlePrint}>

                    <img height={22} src={printIcon} alt="printIcon" />

                  </button>
                </Box> : null
            }

            <ProductReview restaurant={restaurant} allowChanges={false} state={order} setState={() => { }} />
            <div>

              <p className={commonStyles.subtitle}>Notas del Comercio</p>
              <textarea className={commonStyles.note} value={order.note} readOnly />
              {
                order.noteSupplier === null ? null :
                  <>
                    <p className={commonStyles.subtitle}>Notas del Proveedor </p>
                    <textarea
                      disabled
                      className={commonStyles.note}
                      value={order.noteSupplier != null ? order.noteSupplier : ''}
                      readOnly={order.status === 'revisión' && user.roles[0].name === 'Owner' || order.status === 'revisión' && user.roles[0].name === 'Super Admin' ? false : true}
                      onChange={(e) => {
                        setNotas(e.target.value);
                      }}
                    />
                  </>
              }
              {/* <p className={commonStyles.subtitle}>Notas del Proveedor </p>
              <textarea className={commonStyles.note} value={order.noteSupplier != null ? order.noteSupplier : ''} readOnly /> */}
              {

                order.status === 'cancelada' || order.status === 'entregada' ? (
                  <CTA
                    isDisabled={subscription && subscription.status === 'cancelada' ? true : false}
                    text="Reordenar"
                    onClick={() => {
                      reorder(id);
                    }}
                    view="mobile"
                    isDisplayed={user?.roles[0].name === 'Staff' ? 'staff' : 'other'}
                  />
                ) : order.status === 'revisión' ?

                  (
                    <CTA
                      isDisabled={subscription && subscription.status === 'cancelada' ? true : false}
                      text="Aprobar orden"
                      onClick={() => {
                        navigate(`/ordenes/recibir/${id}`);
                      }}
                      view="mobile"
                      isDisplayed={user?.roles[0].name === 'Staff' || user?.roles[0].name === 'Administrator' ? 'staff' : 'other'}
                    />
                  )

                  :

                  (
                    <CTA
                      isDisabled={subscription && subscription.status === 'cancelada' ? true : false}
                      text="Recibir Orden"
                      onClick={() => {
                        navigate(`/ordenes/recibir/${id}`);
                      }}
                      view="mobile"
                    //isDisplayed={user?.roles[0].name === 'Staff' || user?.roles[0].name === 'Administrator' ? 'staff' : 'other'}
                    />
                  )




              }
              {order.status === 'solicitada' || order.status === 'revisión' ? (
                <CTA
                  isDisabled={subscription && subscription.status === 'cancelada' ? true : false}
                  text="Cancelar Orden"
                  onClick={() => {
                    setShowModal(true);
                  }}
                  view="mobile"
                  isDisplayed={user?.roles[0].name === 'Staff' || user?.roles[0].name === 'Administrator' && order.status === 'revisión' ? 'staff' : 'other'}
                  color="red"
                />
              )

                : null

              }


            </div>
          </div>
          <div className={commonStyles.rightSide}>
            <div className={commonStyles.detailsContainer}>
              {
                isSame && order.status === 'solicitada' || isSame && order.status === 'aceptada' ?
                  <Box
                    padding={0.5}
                    mb={1}
                  >
                    <Typography fontSize={18} fontWeight={'bold'} color={'#FB8B24'} textAlign={'center'} >¡Atención! Orden generada para el mismo día.</Typography>
                  </Box> : null
              }

              <div className={commonStyles.field}>

                <p className={commonStyles.subtitle}>Estado: </p>
                <Box ml={1} paddingBottom={0.1} paddingLeft={1} paddingRight={1} borderRadius={3} bgcolor={order.status === 'solicitada' ? '#FAECCC' :
                  order.status === 'aceptada' ? '#D6E4EE' : order.status === 'entregada' ? '#DEECDC' : '#FAE3DE'} >
                  <Typography fontSize={13} > {order.status === 'solicitada' ? 'Solicitada' :
                    order.status === 'aceptada' ? 'Aceptada' : order.status === 'entregada' ? 'Entregada' : order.status === 'cancelada' ? 'Cancelada' : null} </Typography>
                </Box>
              </div>
              <div className={commonStyles.field}>
                <p className={commonStyles.subtitle}>Orden: </p>
                <p className={commonStyles.value}>{order.code}</p>
              </div>
              <div className={commonStyles.field}>
                <p className={commonStyles.subtitle}>Proveedor: </p>
                <p className={commonStyles.value}>{order.supplier.name}</p>
              </div>
              <div className={commonStyles.field}>
                <p className={commonStyles.subtitle}>Vendedor: </p>
                <p className={commonStyles.value}>{order.supplierUser.name}</p>
              </div>
            </div>
            <div className={commonStyles.detailsContainer}>
              <div className={commonStyles.field}>
                <p className={commonStyles.subtitle}>Comercio: </p>
                <p className={commonStyles.value}>
                  {restaurantList.find(
                    (val) =>
                      val.id === parseInt(restaurant),
                  )?.name || '-'}
                </p>
              </div>
              <div className={commonStyles.field}>
                <p className={commonStyles.subtitle}>Fecha de orden: </p>
                <p className={commonStyles.value}>{
                  order.requestDate ?
                    getDisplayDate(order.requestDate)
                    : ''
                }</p>
              </div>
              <div className={commonStyles.field}>
                <p className={commonStyles.subtitle}>Fecha de entrega: </p>
                <p className={commonStyles.value}>
                  {order.deliveryDate
                    ? moment(order.deliveryDate).format('DD-MM-YYYY')
                    : moment(order.scheduleDate).format('DD-MM-YYYY')}
                </p>
              </div>
              <div className={commonStyles.field}>
                <p className={commonStyles.subtitle}>Horario de entrega: </p>
                <p className={commonStyles.value}> {order?.restaurant.hourStart} a   {order?.restaurant.hourEnd}
                </p>

              </div>
            </div>
            {



              order.status === 'cancelada' || order.status === 'entregada' ? (
                <CTA
                  isDisabled={subscription && subscription.status === 'cancelada' ? true : false}
                  text="Reordenar"
                  onClick={() => {
                    reorder(id);
                  }}
                  view="desktop"
                  isDisplayed={user?.roles[0].name === 'Staff' ? 'staff' : 'other'}
                />
              ) : order.status === 'revisión' ?

                (
                  <CTA
                    isDisabled={subscription && subscription.status === 'cancelada' ? true : false}
                    text="Aprobar orden"
                    onClick={() => {
                      navigate(`/ordenes/recibir/${id}`);
                    }}
                    view="desktop"
                    isDisplayed={user?.roles[0].name === 'Staff' || user?.roles[0].name === 'Administrator' ? 'staff' : 'other'}
                  />
                )

                :

                (
                  <CTA
                    isDisabled={subscription && subscription.status === 'cancelada' ? true : false}
                    text="Recibir Orden"
                    onClick={() => {
                      navigate(`/ordenes/recibir/${id}`);
                    }}
                    view="desktop"
                  //isDisplayed={user?.roles[0].name === 'Staff' || user?.roles[0].name === 'Administrator' ? 'staff' : 'other'}
                  />
                )




            }
            {order.status === 'solicitada' || order.status === 'revisión' ? (
              <CTA
                isDisabled={subscription && subscription.status === 'cancelada' ? true : false}
                text="Cancelar Orden"
                onClick={() => {
                  setShowModal(true);
                }}
                view="desktop"
                isDisplayed={user?.roles[0].name === 'Staff' || user?.roles[0].name === 'Administrator' && order.status === 'revisión' ? 'staff' : 'other'}
                color="red"
              />
            )

              : null

            }
          </div>
        </div>
        <PrintComponentOrder subscription={subscription} orders={orderL} state={order} ref={componentRef} setState={() => { }} />
      </div>
    </HomeLayouts>

  );
};

export default OrderDetails;