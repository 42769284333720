

import { createSlice } from "@reduxjs/toolkit";




const initialState = {

   dateN:null, 
   dataO:null,
   orders_allowed:null,
   isLoadingO:false,
   errorO:'',
   orderList:null
  
} 



 export const  orderSlice = createSlice({

    name:'orders',
    initialState:initialState,
    reducers:{
        startOrders:(state)=>{
            state.isLoadingO = true
        },
        setOrdersR:(state, action)=>{
          
            state.dataO= action.payload,
            state.isLoadingO=false,
            state.errorO=''
        },  
        setOrdersAllowed:(state, action)=>{
            state.orders_allowed= action.payload,
            state.isLoadingO=false,
            state.errorO=''

        },
         setErrorO:(state, action)=>{
           
            state.errorO=action.payload
        },
        setOrderList:(state, action)=>{

            state.orderList = action.payload

        },
        clearList:(state)=>{

            state.dataO=null,
            state.isLoadingO=false,
            state.errorO='',
            state.orderList=null

        },
        setDateN:(state, action)=>{

            state.dateN = action.payload
        },   
        clearDate:(state, action)=>{

            state.dateN =action.payload
           

        },
        clearOrders:(state)=>{
            state.dateN=null, 
            state.dataO=null,
            state.isLoadingO=false,
            state.orders_allowed=false,
            state.errorO='',
            state.orderList=null
        }

    }

})

export const { startOrders, setOrdersR, setErrorO, setOrderList, clearList, setDateN, clearDate, clearOrders, setOrdersAllowed } = orderSlice.actions


