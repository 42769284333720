import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from 'assets/logo_name_white.png';
import PropTypes from 'prop-types';
import { shouldHideNavbar } from '../../utils/routes';
import styles from './sidebar.module.scss';
import Select from '../Select';
import useStore from 'store';

import { linkADmin, linkEStandar, links, linksS } from './linksF';
import { useDispatch, useSelector } from 'react-redux';
import { LinkC } from './Link';
import { LinkMenu } from './LinkMenu';
import { setRestaurantV2 } from '../../redux/slices/authSlice/authSliceV2';
import { usePopUp } from '../../hooks/usePopUp';
import { Typography } from '@mui/material';
import PopUp from '../PopUp';
import Closesession from '../PopUp/components/CloseSesion/Closesession';
import { me } from '../../redux/slices/authSlice/thunksV2';




const Sidebar = () => {
  const { user, restaurant, subscription, restaurantsSelect, token } = useSelector((state) => state.auth)
  const { show, setShow } = usePopUp()
  const dispatch = useDispatch()



  const { pathname } = useLocation();

  const handleLocation = ({ target }) => {


    dispatch(setRestaurantV2(target.value))

  };


  useEffect(() => {

    dispatch(me(restaurant, token, user?.phone))

  }, [])



  const isCurrentPath = (route) => {
    if (route === '/') {
      return route === pathname;
    } else {
      return pathname.startsWith(route);
    }
  };


  let filteredLinks = [];

  if (user?.roles[0]?.name === "Staff") {
    filteredLinks = linksS;
  } else if (!subscription || subscription.plan.abreviacion !== "gold") {
    filteredLinks = links.filter((link) =>
      ["Marketplace", "Resumen", "Mi Local", "Reportes", "Perfil", "Cerrar sesión"].includes(link.name)
    );

    filteredLinks = filteredLinks.map((link) => {
      if (link.name === "Mi Local") {
        return {
          ...link,
          list: link.list.filter((item) => ["Ordenes", "Cotizaciones", "Proveedores"].includes(item.name)),
        };
      }
      if (link.name === "Reportes") {
        return {
          ...link,
          list: link.list.filter((item) => item.name === "Compras"),
        };
      }
      return link;
    });
  } else {
    filteredLinks = links;
  }

  if (subscription?.plan.quotesEnabled === false) {
    filteredLinks = filteredLinks.filter((link) => link.name !== "Marketplace");
    filteredLinks = filteredLinks.map((link) => {
      if (link.name === "Mi Local") {
        return {
          ...link,
          list: link.list.filter((item) => item.name !== "Cotizaciones"),
        };
      }
      return link;
    });
  }

  if (shouldHideNavbar(pathname)) return null;

  return (


    <div className={styles.container}>

      <PopUp setShowModal={setShow} showModal={show}>
        <Closesession
          setShowModal={setShow}
        />
      </PopUp>


      <Link to="/" className={styles.logo}>
        <img src={logo} alt="Cado logo" />
      </Link>

      {restaurantsSelect.length > 1 && <p>Seleccione el comercio:</p>}
      {restaurantsSelect.length > 1 && (
        <Select
          onChange={handleLocation}
          options={restaurantsSelect}
          restaurant={restaurant ? restaurant : ''}
        />
      )}


      <nav className={styles.nav}>

        {
          user?.roles[0]?.name === "Staff" ?

            filteredLinks.map((link, i) => {



              return (
                <Link
                  key={i}
                  to={link.to}
                  className={`${styles.link} ${isCurrentPath(link.to) ? styles.currentLink : ''
                    }`}
                >
                  <img src={link.icon} className={styles.icons} />
                  {link.name}
                </Link>
              );
            })
            :
            filteredLinks.map((link, i) => link.list.length === 0 ? (
              <LinkC

                restaurant={restaurant}
                key={i}
                to={link.to}
                name={link.name}
                icon={link.icon}
              />

            ) :
              (
                <LinkMenu
                  restaurant={restaurant}
                  key={i}
                  icon={link.icon}
                  links={link.list}
                  name={link.name}
                >
                </LinkMenu>
              ))
        }



      </nav>

      {/* <nav className={styles.nav}>
        {
          user?.roles[0]?.name === 'Staff' ?
            linksS.map((link, i) => {



              return (
                <Link
                  key={i}
                  to={link.to}
                  className={`${styles.link} ${isCurrentPath(link.to) ? styles.currentLink : ''
                    }`}
                >
                  <img src={link.icon} className={styles.icons} />
                  {link.name}
                </Link>
              );
            })
            :

            !subscription || subscription.plan.abreviacion != "gold" ?

              linkEStandar.map((link, i) => link.list.length === 0 ?
                (

                  <LinkC

                    restaurant={restaurant}
                    key={i}
                    to={link.to}
                    name={link.name}
                    icon={link.icon}
                  />

                ) :
                (
                  <LinkMenu
                    restaurant={restaurant}
                    key={i}
                    icon={link.icon}
                    links={link.list}
                    name={link.name}
                  >
                  </LinkMenu>
                )



              )

              :

              linkADmin.map((link, i) => link.list.length === 0 ?
                (

                  <LinkC
                    restaurant={restaurant}
                    key={i}
                    to={link.to}
                    name={link.name}
                    icon={link.icon}
                  />

                ) :
                (
                  <LinkMenu
                    restaurant={restaurant}
                    key={i}
                    icon={link.icon}
                    links={link.list}
                    name={link.name}
                  >
                  </LinkMenu>
                )



              )
        }

      </nav> */}
    </div>
  );
};

// Sidebar.propTypes = {
//   handleLocation: PropTypes.func.isRequired,
//   restaurantId: PropTypes.number,
// };

export default Sidebar;
