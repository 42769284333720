import React from 'react'
import styles from './hamburgerMenu.module.scss';
import { Link, useLocation } from 'react-router-dom';

export const LinkC = ({ icon, to, name, }) => {


    return (
        <Link
            to={to}
            key={name}
            className={`${styles.link}`}
        >
            <img src={icon} className={styles.icons} />
            {name}
        </Link>
    )
}
