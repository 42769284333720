import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import { AiOutlineLeft as Left } from '@react-icons/all-files/ai/AiOutlineLeft';
import { AiOutlineRight as Right } from '@react-icons/all-files/ai/AiOutlineRight';

import { changeReferenceDate, formatDate } from 'utils/dates';
import styles from './datePicker.module.scss';
import { setDateN } from '../../redux/slices/orderSlice';
import moment from 'moment/moment';

const DatePicker = ({ startDate, endDate, date, setDate, restaurant }) => {




  return (
    <div className={styles.datePicker}>
      <div
        className={styles.insideSpace}
        onClick={() => {

          setDate(changeReferenceDate(date, 'previous'));
        }}
      >
        <Left className={styles.icon} />
        <span>&nbsp; {formatDate(startDate)} &nbsp;</span>
      </div>
      <div
        className={styles.insideSpace}
        onClick={() => {

          setDate(changeReferenceDate(date, 'next'));
        }}
      >
        <span>
          - &nbsp;
          {formatDate(endDate)} &nbsp;
        </span>
        <Right className={styles.icon} />
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  date: PropTypes.object.isRequired,
  setDate: PropTypes.func.isRequired,
};

export default DatePicker;
