

import { Box, Grid, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { formatNumber } from '../../../utils/numbers'
import CheckIcon from '@mui/icons-material/Check';

export const TableHistory = ( { history, unidad, priceRef } ) => {
   
   
    const navigate = useNavigate()
  return (
     
    <Grid 
    sx={{ display:{ xs:'none', md:'flex'  }}}
       container
       border={1}
       borderColor={'#E9E7E2'}
       borderRadius={2}
       >
            <Box  width='100%' >
                <Grid 
                    sx={{ borderBottomColor:'#E9E7E2' }}
                    borderBottom={1}                    
                    padding={1}
                    container 
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                    bgcolor='#F9F6F4'
                    >

                        <Grid item padding={1} md={1.7} >
                            <Typography fontWeight='bold' >Descripcion</Typography>
                        </Grid>
                        <Grid item padding={1}  md={1.7}>
                            <Typography fontWeight='bold' >Documento</Typography>
                        </Grid>
                        <Grid item padding={1} md={1.7} >
                            <Typography fontWeight='bold' >Proveedor</Typography>
                        </Grid>
                        <Grid item padding={1} md={1.7}>
                            <Typography fontWeight='bold' >Cantidad</Typography>
                        </Grid>
                        <Grid item padding={1} md={1.7}>
                            <Typography fontWeight='bold' >Ultimo precio</Typography>
                        </Grid>
                        <Grid item padding={1} md={1.7}>
                            <Typography fontWeight='bold' >Total</Typography>
                        </Grid>
                        <Grid item padding={1} md={1.7}>
                            <Typography fontWeight='bold' >Descuento</Typography>
                        </Grid>

                </Grid>


                {
                            history.length  && history.map((history, index )=>{
                                const fec = new Date(history.dateAuthorization).toLocaleDateString()
                                return (

                                    <Grid 
                                    key={index }
                                    paddingLeft={1}
                                    container 
                                    display='flex'
                                    flexDirection='row'
                                    justifyContent='space-between'
                                   
                                    >
                                             
                
                                    <Grid  item padding={1} md={1.7} >
                                    <Tooltip arrow title={history.name} >
                                          <Typography fontSize={14} noWrap > { history.name }</Typography>
                                     </Tooltip>
                                       
                                    </Grid>
                                    <Grid item padding={1}  md={1.7}>
                                        <Typography fontSize={14}   >  {fec} </Typography>
                                    </Grid>
                                    <Grid item padding={1} md={1.7} >
                                    <Tooltip arrow title={history.supplier } >
                                    <Typography fontSize={14} noWrap > { history.supplier }</Typography>
                                     </Tooltip>
                                      
                                    </Grid>
                                    <Grid item padding={1} md={1.7}>
                                        <Typography fontSize={14}> { `${history.quantity} ${unidad } ` }</Typography>
                                    </Grid>
                                    <Grid item padding={1} md={1.7} >
                                        <Typography fontSize={14} padding={0.5} borderRadius={1} width='35%' bgcolor={formatNumber(history.unitPrice) > priceRef ? '#FAE3DE' :   '#DEECDC'  }  > ${formatNumber(history.unitPrice)  }</Typography>
                                    </Grid>
                                    <Grid item padding={1} md={1.7} >
                                        <Typography fontSize={14} padding={0.5} borderRadius={1} width='35%' >${ formatNumber(history.total)   } </Typography>
                                    </Grid>
                                    <Grid item padding={1} md={1.7} >
                                        {
                                                history.descuento ? 

                                            <CheckIcon sx={{ color : '#a2ce9c' }} /> : '-'
                                        }
                                        
                                    </Grid>
                
                                </Grid>

                                )

                            })

                        }
            </Box>
    </Grid>


  )
}
