import axios from "axios";


export const getSupplierCategories = async (id, token) => {
  const response = await axios.get(`${process.env.API_URL}/suppliers/${id}/category`,
  {
    withCredentials:true,
   headers: {
       'Authorization': `Bearer ${token}`,
       'Accept': '*/*',
       'content-type': 'application/json'
       
   }
 }
  );

  return response.data;
};


export const getSupplier = async (idRestaurant, id, token) => {
  const response = await axios.get(
    `${process.env.API_URL}/restaurants/${idRestaurant}/suppliers/${id}`,
    {
      withCredentials:true,
     headers: {
         'Authorization': `Bearer ${token}`,
         'Accept': '*/*',
         'content-type': 'application/json'
         
     }
   });
  
  // const response = await axios.get(`/suppliers/${id}`);

  return response.data;
};

export const getSupplierProducts = async (params, token) => {
  const { id_rest, id, limit = 50, page = 1, query = '', categoryId } = params;

  const response = await axios.get(`${process.env.API_URL}/suppliers/${id}/restaurants/${id_rest}/products?limit=${limit}&page=${page}&query=${query}${categoryId ? `&categoryId=${categoryId}` : ''}`,
    {
      withCredentials:true,
     headers: {
         'Authorization': `Bearer ${token}`,
         'Accept': '*/*',
         'content-type': 'application/json'
         
     }
   }

  );

  return response.data;
};




export const getSupplierWithContact = async (idRestaurant, id, token) => {
  const response = await axios.get(`${process.env.API_URL}/restaurants/${idRestaurant}/suppliers/${id}`,
  {
    withCredentials:true,
   headers: {
       'Authorization': `Bearer ${token}`,
       'Accept': '*/*',
       'content-type': 'application/json'
       
   }
 }
  );

  return response.data;
};



export const getSuppliers = async (params, token) => {

  const { limit = 20, page = 1, query = '' } = params;

  const response = await axios.get(
    `${process.env.API_URL}/suppliers?limit=${limit}&page=${page}&query=${query}`,
    {
      withCredentials:true,
     headers: {
         'Authorization': `Bearer ${token}`,
         'Accept': '*/*',
         'content-type': 'application/json'
         
     }
   }

  );
  return response.data;
};

export const createNewSupplier = async (data, idRestaurant, token) => {
  const response = await axios.post(`${process.env.API_URL}/restaurants/${idRestaurant}/invite-supplier`, data,
  {
    withCredentials:true,
   headers: {
       'Authorization': `Bearer ${token}`,
       'Accept': '*/*',
       'content-type': 'application/json'
       
   }
 }
  
  
  
  );

  return response.data;
};


export const dateExpiration = async (data, idRestaurant, token) => {
  const response = await axios.put(`${process.env.API_URL}/suppliers/${idRestaurant}/payday`, data,
  {
    withCredentials:true,
   headers: {
       'Authorization': `Bearer ${token}`,
       'Accept': '*/*',
       'content-type': 'application/json'
       
   }
 }
  
  );

  return response.data;
};




























































//https://api-stg.cado.ai/suppliers/103/payday