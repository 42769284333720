
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { esES, zhCN } from "@mui/material/locale";
import React from "react";





export const themePalette = {
    FONT_GLOBAL: "'HK Grotesk'"

}


const theme = createTheme(

    {


        typography: {

            fontFamily: themePalette.FONT_GLOBAL,
            esES,
        },
        components: {
            MuiButton: {
                defaultProps: {
                    style: {
                        textTransform: 'none'
                    }
                }
            }
        }

    })



export const ThemeConfig = ({ children }) => {


    return (

        <ThemeProvider theme={theme} >
            <CssBaseline />
            {children}
        </ThemeProvider>

    )


} 