/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';

import flyIcon from 'assets/fly.svg';
import CTA from 'components/CTA';

import styles from '../../../../pop-up.module.scss';

const UserConfirmation = ({ setShowModal }) => {
    return (
        <div className={styles.content}>
            <h1 className={styles.title}>Registro Exitoso</h1>
            <h2 className={styles.subtitle}>
                Nuestro equipo ha agregado tu nuevo usuario y le han enviado los detalles de
                registro de Whatsapp
            </h2>
            <img className={styles.icon} src={flyIcon} alt="fly" />
            <div className={styles.bottomContainer}>
                <CTA
                    text="Ver Usuarios"
                    onClick={() => {
                        setShowModal(false);
                        window.location.reload();
                    }}
                />
            </div>
        </div>
    );
};

UserConfirmation.propTypes = {
    setShowModal: PropTypes.func.isRequired,
};

export default UserConfirmation;
