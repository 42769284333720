/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';

import { getUsersByOwner, deleteUser } from '../../api/user';

import Options from '../../assets/dots.png'

import useStore from 'store';
import { toast } from 'react-hot-toast';
import { getArrayOfErrors } from 'utils/errors';
import Delete from '../../assets/delete.png';
import Edit from '../../assets/editing.png';

import PopUp from '../../components/PopUp';
import ProfileInfo from '../../components/PopUp/components/ProfileInfo';
import CTA from 'components/CTA';

import Tooltip from 'rc-tooltip';

import styles from './users.module.scss';
import 'rc-tooltip/assets/bootstrap_white.css';
import { useSelector } from 'react-redux';
import { HomeLayouts } from '../../components/layouts/HomeLayouts';
import { Box, Divider, Typography } from '@mui/material';


const Users = () => {
    const { user, token } = useSelector((state) => state.auth)
    const [usersByOwner, setUsersByOwner] = useState([]);
    const [clickedCard, setClickedCard] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showModalDeleteConfirmation, setShowModalDeleteConfirmation] = useState(false);
    const [popUp, setPopUp] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [idPop, setIdPop] = useState(null);
    const [userRow, setUserRow] = useState(null);

    const heading = ['Id', 'Nombre', 'Rol', 'Local', 'Creado', 'Acciones'];


    useEffect(async () => {
        localStorage.removeItem('step');
        localStorage.removeItem('nameUser');
        localStorage.removeItem('wspUser');
        localStorage.removeItem('emailUser');
        const userListByOwner = await getUsersByOwner(user.id, token)

        const creationDate = new Date();
        const userListObject = userListByOwner.map(val => {
            return {
                id: val.id,
                name: val.user,
                role: val.role,
                phone: val.phone,
                local: val.restaurant,
                creation: `Se unió: ${creationDate.toLocaleString('en-US', { month: 'short', })} ${creationDate.getDay().toString()}, ${creationDate.getFullYear()}`,
                actions: ""
            }
        });

        setUsersByOwner(userListObject);
    }, []);

    const handleOpenPopUp = () => {
        if (popUp) {
            setIdPop(null);
        }
        setPopUp(!popUp)
    };
    const openRow = (index) => {

        if (index !== idPop) return setIdPop(index);
        return setIdPop(null);
    };

    const handleDeleteUser = async (row, setClick) => {
        try {
            setPopUp(false);
            setIdPop(null);
            setShowModalDeleteConfirmation(true);
            setClickedCard(setClick);
        } catch (error) {
            if (error.response) {


                const errors = getArrayOfErrors(error.response.data);
                for (let i = 0; i < errors.length; i++) {

                    toast.error(errors[i]);
                }


            } else {
                toast.error('Error interno. Inténtelo más tarde');
            }
        }
    };

    const popOverComponent = (row) => {


        return (
            <>




                {
                    user?.roles[0].name === 'Administrator' || user?.roles[0].name === 'Super Admin' ?
                        <div className={styles.container} onClick={() => handleDeleteUser(row, 'userDelete')}>
                            <div className={styles.image}>
                                <img src={Delete} width={10} height={15} />
                            </div>
                            <div className={styles.text}>
                                Eliminar usuario
                            </div>
                        </div> :

                        <>

                            {
                                user?.approval === true && row.role === 'Administrator' || user?.approval === true && row.role === 'Super Admin' ?

                                    <>

                                        <div className={styles.containerSup} onClick={() => handleDeleteUser(row, 'updateUser')}>
                                            <div className={styles.image}>
                                                <img src={Edit} width={25} height={22} />                                            </div>
                                            <div className={styles.text}>
                                                Editar
                                            </div>
                                        </div>
                                        <Divider />
                                    </>
                                    : null

                            }

                            <div className={styles.container} onClick={() => handleDeleteUser(row, 'userDelete')}>
                                <div className={styles.image}>
                                    <img src={Delete} width={25} height={20} />
                                </div>
                                <div className={styles.text}>
                                    Eliminar usuario
                                </div>
                            </div>

                        </>

                }



            </>

        );
    };

    return (

        <HomeLayouts>
            <div>

                <div className={styles.content}>
                    <PopUp showModal={showModal} setShowModal={setShowModal}>
                        <ProfileInfo
                            clickedCard={clickedCard}
                            user={user}
                            setShowModal={setShowModal}
                            local={[]}
                        />
                    </PopUp>
                    <PopUp showModal={showModalDeleteConfirmation} setShowModal={setShowModalDeleteConfirmation}>
                        <ProfileInfo
                            clickedCard={clickedCard}
                            row={userRow}
                            setShowModal={setShowModal}
                            local={[]}
                        />
                    </PopUp>
                    <div className={styles.headerContainer}>
                        <h1 className={styles.header}>Usuarios</h1>
                        <CTA
                            view="tablet"
                            text="Añadir Usuario"
                            onClick={() => {
                                setClickedCard('usersByOwner');
                                setShowModal(true);
                            }}
                            size="small"
                        />
                    </div>
                    <div>
                        <table className={styles.tableUsers}>
                            <thead>
                                <tr>
                                    {heading.map((head, i) => <th style={{ display: i === 0 || i === 3 || i === 4 ? 'none' : null }} key={i}>{head}</th>)}
                                </tr>
                            </thead>
                            <tbody>
                                {usersByOwner.map((row, index) => {


                                    return (
                                        <tr key={index} style={{ backgroundColor: index === 0 ? '#E9E7E2' : null }}>

                                            {Object.values(row).map((val, i) => {


                                                return <td
                                                    style={{ display: i === 0 || i === 3 || i === 4 || i === 5 ? 'none' : null }}
                                                    className={`${i === 1 ? styles.leftColor : i === 6 ? styles.rightColor : styles.test} ${styles.tdUsers} ${styles.containerTd} `}
                                                    key={i}>
                                                    {i === 6 && index !== 0 ?
                                                        <Tooltip

                                                            placement='left'
                                                            visible={index === idPop && popUp}
                                                            animation='zoom'
                                                            onVisibleChange={handleOpenPopUp}
                                                            trigger='click'
                                                            overlay={popOverComponent(row)}
                                                            arrowContent={<div className="rc-tooltip-arrow-inner" ></div>}
                                                            id={i}
                                                        >
                                                            <img
                                                                src={Options}
                                                                alt='restaurants'
                                                                width='25px'
                                                                height='12px'
                                                                className={styles.iconOptions}
                                                                onClick={() => {
                                                                    setUserRow(row);
                                                                    openRow(index);
                                                                }} />
                                                        </Tooltip>
                                                        :
                                                        <Box  >

                                                            {i === 1 ?

                                                                <>
                                                                    <Typography> {val} </Typography>
                                                                    <Typography> {row.phone} </Typography>
                                                                </>
                                                                :
                                                                val
                                                            }

                                                        </Box>
                                                    }
                                                </td>
                                            })}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </HomeLayouts>


    );
};

export default Users;
