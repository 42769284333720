import React from 'react';
import { Routes, Route } from 'react-router-dom';

import SuppliersParent from './SuppliersParent';
import SupplierInfo from './components/SupplierInfo';
import NotFound from 'router/components/NotFound';

import subRoutes from './subRoutes';

const Suppliers = ({ restaurant }) => {
  return (
    <>
      <Routes>
        <Route
          path={subRoutes.suppliers}
          element={<SuppliersParent restaurant={restaurant} />}
        />
        <Route path={subRoutes.details} element={<SupplierInfo />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default Suppliers;
