import create from 'zustand';

const useStore = create((set) => ({
  user: null,
  setUser: (user) => set(() => ({ user })),
  subscription: null,
  setSubscription: (subscription) => set(() => ({ subscription })),
  isUserLoading: true,
  setIsUserLoading: (isUserLoading) => set(() => ({ isUserLoading })),
  locations: [],
  setLocations: (locations) => set(() => ({ locations })),
  locationSelected: null,
  setLocationSelected: (locationSelected) => set(() => ({ locationSelected })),
  lastOrder: null,
  setLastOrder: (lastOrder) => set(() => ({ lastOrder })),
  dataLogin: null,
  setDataLogin: (dataLogin) => set(() => ({ dataLogin })),
}));

export default useStore;
