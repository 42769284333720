
import React from 'react';
import PropTypes from 'prop-types';

import SearchBar from 'components/SearchBar';

import { getDisplayDate } from 'utils/dates';

import { AiOutlineLeft as Left } from '@react-icons/all-files/ai/AiOutlineLeft';
import { AiOutlineRight as Right } from '@react-icons/all-files/ai/AiOutlineRight';

import useStore from 'store';

import styles from './myOrders.module.scss';
import { useSelector } from 'react-redux';
import moment from 'moment';

const DesktopOrders = ({ subscription, orders, params, setParams, reorder, navigateToOrder }) => {

  const { restaurant, user } = useSelector((state) => state.auth)


  //const user = useStore((state) => state.user);
  const options = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1];
  const columns = [
    {
      name: 'Proveedor',
      size: 'large',
    },
    {
      name: 'Estado',
      size: 'medium',
    },
    {
      name: 'Fecha de entrega',
      size: 'medium',
    },
    {
      name: '',
      size: 'small',
    },
  ];

  return (
    <>
      <div className={styles.desktopOrdersContainer}>
        <div className={styles.row}>
          <div className={styles.column}>
            <SearchBar
              query={params.query}
              setQuery={(val) => {
                setParams({ ...params, query: val });
              }}
              placeholder="Buscar en Mis Órdenes"
              height="small"
              name="query"
            />
            {/* <Filter /> */}
          </div>
        </div>
        <div className={`${styles.row} ${styles.headerRow}`}>
          {columns.map((column, i) => {
            return (
              <div
                key={`${i}_${column}`}
                className={`${styles.column} ${styles[column.size]}`}
              >
                {column.name}
              </div>
            );
          })}
        </div>
        {orders.orders.map((order, i) => {
          return (
            <div
              key={`${i}_${order.id}`}
              className={`${styles.row} ${styles.orderRow}`}
              onClick={(e) => {
                navigateToOrder(e, order.code);
              }}
            >
              <div className={`${styles.column} ${styles.large}`}>
                {order.supplier.name}
              </div>
              <div className={`${styles.column} ${styles.medium}`}>
                <label className={`${styles.label} ${styles[order.status]}`}>
                  {order.status.charAt(0).toUpperCase() + order.status.slice(1)}{' '}
                </label>
              </div>
              <div className={`${styles.column} ${styles.medium}`}>
                {order.deliveryDate
                  ? moment(order.deliveryDate).format('DD-MM-YYYY')
                  : moment(order.scheduleDate).format('DD-MM-YYYY')}
              </div>
              <div className={`${styles.column} ${styles.small} ${styles.centered}`}>
                <button
                  className={styles.reorder}
                  onClick={() => {
                    reorder(order.code);
                  }}
                  style={{
                    display: !subscription || subscription.status === 'cancelada' || user.roles[0].name === 'Staff' ? 'none' : null,
                  }}
                >
                  Reordenar
                </button>
              </div>
            </div>
          );
        })}
      </div>

    </>
  );
};

DesktopOrders.propTypes = {
  orders: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  setParams: PropTypes.func.isRequired,
  reorder: PropTypes.func.isRequired,
  navigateToOrder: PropTypes.func.isRequired,
};

export default DesktopOrders;

