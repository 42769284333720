import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, Navigate } from 'react-router-dom';
import { getOrderById } from 'api/orders';

import Loading from 'components/Loading';
import { toast } from 'react-hot-toast';

import { getArrayOfErrors } from 'utils/errors';
import { getDisplayDate } from 'utils/dates';

import CTA from 'components/CTA';

import styles from 'views/NewOrder/components/Confirmation/confirmation.module.scss';

import useStore from 'store';
import { useSelector } from 'react-redux';
import { HomeLayouts } from '../../../../components/layouts/HomeLayouts';
import PopUp from '../../../../components/PopUp';


const OrderConfirmation = () => {
  const store = useStore((state) => state);
  const { restaurant, user, token } = useSelector((state) => state.auth)
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);





  useEffect(async () => {
    try {

      const order = await getOrderById(user.id, parseInt(restaurant), id, token);
      setOrder(order);
    } catch (error) {
      if (error.response) {

        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {

          toast.error(errors[i]);
        }

      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    } finally {
      setLoading(false);
    }
  }, []);

  if (loading) return <Loading />;
  if (!order) return <HomeLayouts>

  </HomeLayouts>;
  if (order.status !== 'entregada') return <Navigate to="/ordenes" />;

  const details = [
    {
      label: 'Proveedor: ',
      value: order.supplier.name,
    },
    {
      label: 'Vendedor: ',
      value: order.supplierUser.name,
    },
    {
      label: 'Fecha de entrega: ',
      value: getDisplayDate(order.deliveryDate),
    },
  ];

  return (
    <HomeLayouts>
      <div className={styles.content}>
        <div className={styles.mainContainer}>
          <h1 className={styles.title}>¡Orden Recibida!</h1>
          <h2 className={styles.subtitle}>
            Has recibido los productos que solicitaste. ¡Ahora puedes empezar a preparar tus
            platos estrella!
          </h2>
          <div className={styles.detailsContainer}>
            <h3 className={styles.orderNumber}>Orden # {order.code}</h3>
            {details.map((detail) => (
              <div className={styles.detail} key={detail.label}>
                <span className={styles.label}>{detail.label}</span>
                <span className={styles.value}>{detail.value}</span>
              </div>
            ))}
            <span
              className={styles.span}
              onClick={() => {
                navigate(
                  `/ordenes/detalle/${order.code}${restaurant}`,
                );
              }}
            >
              Ver Detalle
            </span>
          </div>
        </div>
        <div className={styles.ctaContainer}>
          <CTA
            text="Ir a Mis Órdenes"
            isDisabled={false}
            onClick={() => {
              navigate('/ordenes');
            }}
          />
        </div>
      </div>
    </HomeLayouts>

  );
};

export default OrderConfirmation;
