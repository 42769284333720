
import { Box, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import icon from '../../../assets/docPDF.png'
import moment from 'moment'


export const TableMobbileProducts = ( { products, restaurant }) => {



    const navigate = useNavigate()
    return (
     
        <Grid
          
           sx={{ display:{ xs:'flex', md:'none'  }}}
           mt={4}
           container
           border={1}
           borderColor={'#E9E7E2'}
           borderRadius={2}
           >
                <Box  width='100%' >
           
                  
               {
                    products.map((product)=>{

                         const fecha = moment(product.dateAuthorization).format('DD-MM-YYYY')
                        
                         const fechaC = moment(product.dateExpiration).add(1, 'days').format('DD-MM-YYYY')
                         return (
                              <Grid 
                              key={product.id}
                              border={1}
                              borderColor={'#E9E7E2'}
                              sx={{ cursor:'pointer', '&:hover':{
                                     backgroundColor:'#F9F6F4'
                                      }} }
                              component='div'
                                            
                              padding={1}
                              container 
                              display='flex'
                              flexDirection='column'
                                                  
                                >
                                    <Grid  item md={4}  xs={12}  >
                                         <Typography fontSize={15} fontWeight='bold' > {product.supplier}</Typography>
                                         <Typography fontSize={13} noWrap   color='gray' > Factura</Typography>
                                    </Grid>
                           
            
                                    <Grid container     
                                        display='flex'
                                        alignItems='center'
                                        flexDirection='row'
                                        
                                        justifyContent='end'
                                       >
                                     <Grid
                                      
                                        item 
                                        padding={1} 
                                        xs={6}  >
                                  <Typography fontSize={14} noWrap   fontWeight='bold' >Fecha:</Typography>  
                                  <Typography fontSize={14}  > { fecha} </Typography> 
                                  
                                </Grid>
                                <Grid
                                      
                                      item 
                                      padding={1} 
                                      xs={6}  >
                                <Typography fontSize={14} noWrap   fontWeight='bold' >Fecha de Caducidad:</Typography>  
                                <Typography fontSize={14}  > { fechaC} </Typography> 
                                
                              </Grid>
                                <Grid  item padding={1} md={4}  xs={6}  >
                                <Typography fontSize={14} noWrap   fontWeight='bold' >Total Doc.</Typography>  
                                <Typography 
                                   fontSize={14} 
                                   padding={0.5} 
                                   borderRadius={1} 
                                   width='55%' 
                                  
                                    >${product.total}
                                    
                               </Typography>
                              
                                </Grid>
                                {
                                     product.documentPdf != null ?
                                     <Grid 
                                    
                                     item 
                                     padding={1}  xs={6}  >
                                         <Typography fontSize={14} noWrap   fontWeight='bold' >Descargar Doc.</Typography>  
                                         <IconButton 
                                             href={product.documentPdf}
                                             aria-label="delete" size="small">
                                                <img src={icon} height={25} /> 
                                         </IconButton>
                                    
                                 </Grid> :
                                    <Grid 
                                    justifyContent={'center'}
                                   
                                    item 
                                    padding={1}  xs={6}  >
                                        <Typography fontSize={11} color={'gray'} > No hay documento  </Typography>
                                   
                                </Grid>
                                 }
                               </Grid>
                           
                              
                               
                               
                            </Grid>

                         )
                    }
                         
                        
                       

                    )
               }
             
                  
                  
                </Box>
        </Grid>
    
    
      )
}
