

import { createSlice } from "@reduxjs/toolkit";




const initialState = {

    reports:[],
    isLoadingR:false,
    error:''
} 





 export const  reportSlice = createSlice({

    name:'reports',
    initialState:initialState,
    reducers:{
        startReport:(state)=>{
            state.isLoadingR = true
        },
        setReports: (state, action )=>{
                               
                state.reports = action.payload;
                state.isLoadingR = false;

        },
        setErrorsReport: (state, action )=>{
                               
            state.error = action.payload;
            state.isLoadingR = false;

    },
    clearReports: (state )=>{
        state.reports = []; 
        state.error = '';
        state.isLoadingR = false;

}
        
    }


})

export const { startReport, setReports, setErrorsReport, clearReports } = reportSlice.actions


