import React, { useEffect, useState } from 'react'
import { HomeLayouts } from '../../components/layouts/HomeLayouts'
import styles from '../Reports/reports.module.scss'
import { Box, Chip, Grid, Pagination, Typography } from '@mui/material'
import 'react-multi-carousel/lib/styles.css';
import SearchBarMarket from '../../components/SearchBar/SearchBarMarket'
import { CardProductMovil } from './components/CardProductMovil'
import { CarrouselComp } from './components/CarrouselComp'
import { useDispatch, useSelector } from 'react-redux'
import { getProductsMarketPlace } from '../../api/marcketPlace'
import Loading from '../../components/Loading'





export const Marketplace = () => {
    const { token } = useSelector((state) => state.auth)
    const [query, setQuery] = useState('');
    const [categorias, setCategorias] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingCat, setloadingCat] = useState(false);
    const [categoriasBna, setCategoriasBan] = useState(null);
    const [filteredProducts, setFilteredProducts] = useState(null);
    const [productos, setproductos] = useState([]);
    const [cantidad, setcantidad] = useState(1)
    const [isSearch, setisSearch] = useState(false);
    const [currentPage, setCurrentPage] = useState(1); // Página actual
    const [catSelect, setCatSeklect] = useState('Todos')
    const [catId, setCatId] = useState(0)
    const [total, setTotal] = useState(100);
    const ItemsPerPage = 50;



    useEffect(() => {

        setData()

    }, [])
    const startIndex = (currentPage - 1) * ItemsPerPage;
    const endIndex = startIndex + ItemsPerPage;
    const currentItems = filteredProducts ? filteredProducts.slice(startIndex, endIndex) : [];



    useEffect(() => {

    }, [currentItems])

    useEffect(() => {

    }, [categorias])


    const dism = (id, nuevaCantidad) => {


        setproductos(prevProductos =>
            prevProductos.map(producto =>
                producto.id === id ? { ...producto, cantidad: nuevaCantidad } : producto
            )
        );

    }

    const handlePageChange = (event, page) => {

        setCurrentPage(page);
    };


    const add = (id, nuevaCantidad) => {


        setproductos(prevProductos =>
            prevProductos.map(producto =>
                producto.id === id ? { ...producto, cantidad: nuevaCantidad } : producto
            )
        );

    }




    const setData = async () => {
        setQuery('')
        setLoading(true)
        const pro = await getProductsMarketPlace(token, '1000', '', '');
        const categoriasDinamicas = {};
        const productFil = []
        const cat = []

        if (pro.ok) {
            pro.data.forEach(product => {
                if (!categoriasDinamicas[product.category]) {
                    categoriasDinamicas[product.category] = [];
                }
                categoriasDinamicas[product.category].push({ ...product, cantidad: 1 });
                productFil.push({ ...product, cantidad: 1 })
                cat.push(product.category)
            });
            const kk = Object.keys(categoriasDinamicas).map((categoria, index) => {
                return categoria
            }

            )
            setisSearch(false)
            setCategoriasBan(kk)
            setproductos(productFil)
            setCategorias(categoriasDinamicas);
            localStorage.setItem('cat', JSON.stringify(categoriasDinamicas))
            setLoading(false)
        } else {

            setCategoriasBan(null)
            setproductos(null)
            setCategorias(null);
            setLoading(false)

        }

    }

    const onBlourMar = () => {


        setCategorias(null)

    }

    const addCantProduct = (e, item) => {

        setcantidad(cantidad + parseInt(e.target.value))


    }

    const selectCat = (cat) => {



        if (query.length > 0) {

            setisSearch(false)
            setFilteredProducts(null)
            setQuery('')
            filterP(cat)
            setCatSeklect(cat)

        } else {

            setCatSeklect(cat)
            filterP(cat)
        }


    }


    const onBlourMarText = async (cat = '') => {



        if (query.length > 0) {

            if (catSelect === 'Todos') {

                const pro = await getProductsMarketPlace(token, '200', query, '');
                if (pro.ok) {
                    setisSearch(true)
                    setTotal(pro.data.length)
                    const startIndex = (currentPage - 1) * ItemsPerPage;
                    const endIndex = startIndex + ItemsPerPage;
                    //setproductos(productos.slice(startIndex, endIndex));
                    setFilteredProducts(pro.data)

                    setLoading(false)
                } else {

                    setCategoriasBan(null)
                    setproductos(null)
                    setCategorias(null);
                    setLoading(false)
                }
            } else {

                const pro = await getProductsMarketPlace(token, '200', query, '');
                if (pro.ok) {
                    const searCategory = pro.data.filter((product) => {
                        return product.category === catSelect
                    })
                    setisSearch(true)
                    setTotal(searCategory.length)
                    const startIndex = (currentPage - 1) * ItemsPerPage;
                    const endIndex = startIndex + ItemsPerPage;
                    setFilteredProducts(searCategory)
                    setLoading(false)

                } else {
                    setCatSeklect(catSelect)
                    setCategoriasBan(null)
                    setproductos(null)
                    setCategorias(null);
                    setLoading(false)
                }

            }
            //filterProducts()

        } else {
            setData()
            setisSearch(false)
            setFilteredProducts(null)
            // setCatSeklect('Todos')
        }
    }



    const filterProducts = () => {

        const filtered = productos.filter(product => {
            // Aquí puedes agregar tus criterios de filtro, por ejemplo:
            return product.name.toLowerCase().includes(query.toLowerCase());
        });

        setFilteredProducts(filtered);
        //organizeByCategory(filtered);
    };


    const filterP = (category) => {

        const c = localStorage.getItem('cat')
        const cate = JSON.parse(c)

        setloadingCat(true)
        const nuevoInventario = { [category]: cate[category] };

        setCategorias(nuevoInventario)
        setTimeout(() => {
            setloadingCat(false)
        }, 300);
    }


    if (loading) {

        return (
            <HomeLayouts>
                <Loading />
            </HomeLayouts>
        )

    }




    return (
        <HomeLayouts>
            <Grid
                paddingY={2}

                sx={{ paddingX: { xs: 1, md: 6 }, marginTop: { md: 5 } }}
                container
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}

            >
                <Grid
                    sx={{ display: { xs: 'none', md: 'flex' }, }}
                    item
                    xs={12}
                    md={4}
                >
                    <div>
                        <h1 className={styles.title}>Marketplace</h1>
                    </div>
                </Grid>
                <Grid

                    item
                    xs={12}
                    md={4}
                >

                    <SearchBarMarket
                        onBlur={onBlourMar}
                        onBlurText={onBlourMarText}
                        name="query"
                        setCategorias={setCategorias}
                        query={query}
                        placeholder="Buscar en Cado..."
                        setQuery={setQuery}
                    />
                </Grid>
                <Grid

                    flexDirection={'row'}
                    justifyContent={'flex-end'}
                    sx={{ display: { xs: 'none', md: 'flex' }, }}
                    md={4}
                    item
                    xs={12}
                >
                    {/* <BtnResume /> */}
                </Grid>
            </Grid>

            <Box mt={2} mb={4} sx={{ overflow: { xs: 'scroll', md: 'hidden' }, justifyContent: { md: 'center' } }} display={'flex'} flexDirection={'row'} padding={2}  >
                <Chip style={{ color: catSelect === 'Todos' ? 'white' : 'black', backgroundColor: catSelect === 'Todos' ? 'black' : '#00000014' }} sx={{ marginRight: 2, fontSize: 17 }} label='Todos' onClick={() => { setData(), setCatSeklect('Todos') }} />
                {
                    categoriasBna != null ?
                        categoriasBna.map((categoria, index) => (

                            <Chip key={index} style={{ color: catSelect === categoria ? 'white' : 'black', backgroundColor: catSelect === categoria ? 'black' : '#00000014' }} sx={{ marginRight: 2, fontSize: 17 }} label={categoria} onClick={() => { selectCat(categoria) }} />

                        ))
                        : null
                }

            </Box>

            {loadingCat ?

                <Loading /> :
                <Box padding={0} backgroundColor='#f6f6f6' >
                    {


                        productos.length > 0 && categorias != null && !isSearch ?
                            Object.keys(categorias).map((categoria, index) => {

                                const CatId = productos.filter((pro) => {
                                    return pro.category === categoria
                                })


                                return (

                                    <CarrouselComp
                                        i={index}
                                        proInd={CatId}
                                        productos={productos}
                                        add={add}
                                        dism={dism}
                                        cantidad={cantidad}
                                        addCantProduct={addCantProduct}
                                        key={index}
                                        categoria={categoria}
                                        categorias={categorias}
                                    />

                                )
                            })
                            : null
                    }

                </Box>
            }


            <Grid
                marginTop={2}
                paddingBottom={3}
                sx={{

                    paddingX: { xs: 0, md: 0, lg: 3 }
                }}
                container
            >

                {
                    currentItems && currentItems.map(item => (
                        <Grid
                            key={item.id}
                            marginTop={1}
                            item
                            md={6}
                            lg={3}
                            xs={12}
                            sx={{ paddingRight: { xs: 0, md: 0, lg: 4 } }}

                        >
                            <CardProductMovil
                                key={item.id}
                                item={item}
                            />
                        </Grid>

                    ))
                }
            </Grid>
            {
                currentItems.length > 0 &&
                <Box
                    padding={5}

                    mb={25}
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'center'}

                >
                    {currentItems &&
                        <Pagination
                            page={currentPage}
                            onChange={handlePageChange}
                            component="div"
                            count={Math.ceil(total / ItemsPerPage)}
                        />
                    }
                </Box>
            }

        </HomeLayouts>
    )
}




