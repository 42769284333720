import axios from "axios";


export const getUser = async (token, phone) => {

       

  const response = await axios.post( `${process.env.API_URL}/me`,
  {
    phone
  },
  {
    withCredentials:true,
  headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': '*/*',
      'content-type': 'application/json'
      
  }
});
return response.data;
}

// export const getUser = async () => {
//   const response = await axios.post('/me');
//   return response.data;
// };

export const getSubscription = async (token, id) => {
  
  const response = await axios.get( `${process.env.API_URL}/subscriptions/${id}`,
  {
  withCredentials:true,
  headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': '*/*',
      'content-type': 'application/json'
      
  }
});



return response;
   
};




export const getUsersByOwner = async (idOwner, token) => {
  const response = await axios.get(`${process.env.API_URL}/restaurants/find/${idOwner}`,
  
  {
    withCredentials:true,
    headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'
        
    }
  }
  );
  return response.data;
};

export const changePassword = async (data, id, token) => {
  const response = await axios.put(`${process.env.API_URL}/me/${id}/password`, data,
  {
    withCredentials:true,
    headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'
        
    }
  });
  return response.data;
};

export const addUser = async (data, id, token) => {
  const response = await axios.post(`${process.env.API_URL}/me/${id}/add`, data,
  {
    withCredentials:true,
    headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'
        
    }
  }  
  
  
  );
  return response;
};

export const deleteUser = async (idUser, id, token) => {
  const response = await axios.delete(`${process.env.API_URL}/me/${id}/user/${idUser}`,  
  {
    withCredentials:true,
    headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'
        
    }
  }  
  );
  return response.data;
};



export const updateUserRole = async (idUser, data, token) => {
  const response = await axios.put(`${process.env.API_URL}/me/${idUser}/users/profiles`, data,

  {
    withCredentials:true,
    headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'
        
    }
  }  
  
  
  );
  return response.data;
};

export const updateStateApproval = async (idUser, data, token) => {
  const response = await axios.put(`${process.env.API_URL}/me/${idUser}/approval`, data,
  {
    withCredentials:true,
    headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'
        
    }
  }  
  
  
  );
  return response.data;
};

export const changeContact = (data, id, token) => {
  return axios.put(`${process.env.API_URL}/me/${id}/contact-method`, data,
  
  {
    withCredentials:true,
    headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'
        
    }
  }  
  
  );
};














































