import React from 'react';
import { useLocation } from 'react-router-dom';

import { shouldHideNavbar } from 'components/Sidebar/helpers.js';

import styles from './banner.module.scss';
import { Button } from '@mui/material';

const Banner = () => {
  const { pathname } = useLocation();

  if (shouldHideNavbar(pathname)) return null;

  return (
    <div className={styles.banner}>
      <p className={styles.text}>
        Tu suscripción se ha caducado. Renueva tu{' '}
        <span className={styles.word}>suscripción</span> para poder ordenar en Cado. {' '}
        <Button
          size='small'
          target='_blank'
          sx={{ color: '#050624', textDecoration: true }}
          href='https://wa.me/+593968652005'
        > Renovar aquí </Button>
      </p>
    </div>
  );
};

export default Banner;
