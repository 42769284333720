/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-hot-toast';
import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';
import { addRestaurantByUser } from '../../../../../../api/restaurants';
import { getArrayOfErrors } from 'utils/errors';

import styles from './restaurantAddress.module.scss';
import CTA from '../../../../../CTA';
import { useDispatch, useSelector } from 'react-redux';
import { me } from '../../../../../../redux/slices/authSlice/thunksV2';
import { getSites } from '../../../../../../api/sites';

const RestaurantAddress = ({ changeStep, restaurantInfo }) => {
    const { restaurant, user, token } = useSelector((state) => state.auth)
    const [address, setAddress] = useState('');
    const [loading, setLoading] = useState(false);
    const [cities, setCities] = useState(null);
    const [countries, setCountries] = useState(null);
    const [provinces, setProvinces] = useState(null);
    const [city, setCity] = useState(null);
    const [country, setCountry] = useState(null);
    const [province, setProvince] = useState(null);
    const [reference, setReference] = useState('');
    const dispatch = useDispatch()
    const [name, setName] = useState('');


    useEffect(() => {
        getS()
    }, [])

    const getS = async () => {

        const contr = await getSites('C', '', token)
        setCountries(contr)


    }


    const handleOnchangeCountry = async (value) => {

        const data = value.split(' ')

        const prov = await getSites('S', data[0], token)
        setCountry(value)
        setProvinces(prov)

    }
    const handleOnchangeProv = async (value) => {

        const data = value.split(' ')
        const cit = await getSites('CI', data[0], token)
        setProvince(value)
        setCities(cit)

    }
    const handleOnchangeCity = async (value) => {

        setCity(value)

    }

    const handleAddRestaurant = async () => {
        setLoading(true)
        const cit = city.split(' ')
        const coun = country.split(' ')
        const prov = province.split(' ')


        try {
            const dataRestaurant = {
                "identifier": restaurantInfo.ruc,
                "name": restaurantInfo.name,
                "business_name": restaurantInfo.alias,
                "address": address,
                "city": cit[1],
                "state": prov[1],
                "country": coun[1],
                "depto_oficina_piso": reference,
                "name_commerce": name,
                "latitude": "1.000003",
                "longitude": "-0.59867334",
                "is_active": true
            };


            await addRestaurantByUser(dataRestaurant, user?.id, token);
            dispatch(me(restaurant, token, user?.phone))
            toast.success('Local agregado correctamente');
            setTimeout(() => {
                window.location.reload();
                setLoading(false)
            }, 800);


        } catch (error) {

            if (error.response) {
                const errors = getArrayOfErrors(error.response.data);
                for (let i = 0; i < errors.length; i++) {
                    toast.error(errors[i]);
                }
                setLoading(false)
            } else {
                toast.error('Error al añadir comercio');
                setLoading(false)
            }
        }
    };

    return (
        <div className={styles.contentLocal}>
            <div className={styles.header}>
                <Back className={styles.back} onClick={() => changeStep('prev')} />
                <h1 className={styles.title}>Agregar dirección</h1>
            </div>
            <div className={styles.section} style={{ marginBottom: '30px' }}>
                <input
                    placeholder="Dirección"
                    className={`${styles.input}`}
                    onChange={(e) => setAddress(e.target.value)}
                    minLength="5"
                    required
                />
                <input
                    placeholder="Depto./Oficina/Piso"
                    className={`${styles.input}`}
                    onChange={(e) => setReference(e.target.value)}
                    minLength="1"
                    required
                />
                <input
                    placeholder="Nombre del negocio o del edificio (opcional)"
                    className={`${styles.input}`}
                    onChange={(e) => setName(e.target.value)}
                />
                <select
                    value={country != null ? country : ''}
                    name='pais'
                    onChange={(e) => handleOnchangeCountry(e.target.value)}
                    className={styles.inputAddP} >

                    <option className={styles.optionInii} value={''} >Pais </option>
                    {countries && countries.map((coun, i) => {
                        return <option key={i} value={`${coun.id} ${coun.name}`} >{coun.name
                        } </option>
                    })}
                </select>
                <select
                    value={province != null ? province : ''}
                    name='provincia'
                    onChange={(e) => handleOnchangeProv(e.target.value)}
                    className={styles.inputAddP} >

                    <option className={styles.optionInii} value={''} >Provincia </option>
                    {provinces && provinces.map((prov, i) => {
                        return <option key={i} value={`${prov.id} ${prov.name}`} >{prov.name
                        } </option>
                    })}
                </select>
                <select
                    value={city != null ? city : ''}
                    name='ciudad'
                    onChange={(e) => handleOnchangeCity(e.target.value)}
                    className={styles.inputAddP} >

                    <option className={styles.optionInii} value={''} >Ciudad </option>
                    {cities && cities.map((cit, i) => {
                        return <option key={i} value={`${cit.id} ${cit.name}`} >{cit.name
                        } </option>
                    })}
                </select>
            </div>
            <CTA text="Agregar dirección" onClick={handleAddRestaurant} isDisabled={!address || !reference || !country || !province || !city || loading || reference.length < 1} />
        </div>
    );
};

RestaurantAddress.propTypes = {
    changeStep: PropTypes.func.isRequired,
    setState: PropTypes.func.isRequired,
    restaurantInfo: PropTypes.object,
};

export default RestaurantAddress;
