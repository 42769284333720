import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useForm from 'hooks/useForm';
import CTA from 'components/CTA';
import PhoneInput from 'react-phone-number-input';

import styles from '../../pop-up.module.scss';

const EditTerm = ({ updateContact, restaurant_id, isDisabled, value }) => {

    const [dias, setDias] = useState('');


    useEffect(() => {
        setDias(value)
    }, [])


    const send = () => {

        setDias('')
        updateContact({
            restaurantId: restaurant_id,
            payday: dias

        })


    }

    return (
        <div className={styles.content}>
            <h1 className={styles.title}>Cambiar PLazo</h1>
            <h2 className={styles.subtitle}>Por favor ingresa los datos del plazo de pago.</h2>
            <div className={styles.plazo}    >
                <input
                    className={styles.inputPLazo}
                    type="text"
                    name="name"
                    value={dias}
                    onChange={(event) => {
                        setDias(event.target.value);
                    }}
                    autoComplete="name"
                    autoCorrect="off"
                />

                <label> días </label>
            </div>

            <div>
                <CTA
                    text="Guardar"
                    isDisabled={dias === '' ? true : isDisabled}
                    onClick={send}
                />
                {/* <CTA
                    text="Cancelar"
                    color="red"
                    onClick={() => {
                        setShowModal(false);
                    }}
                /> */}
            </div>
        </div>
    );
};



export default EditTerm;
