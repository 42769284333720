

import React from 'react';
import { Routes, Route } from 'react-router-dom';


import NotFound from 'router/components/NotFound';

import { Documents } from './Documents';



const DocumentsRouter = ({ restaurant }) => {

    return (
        <>
            <Routes>
                <Route path={'/'} element={<Documents restaurant={restaurant} />} />

                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    );
};

export default DocumentsRouter;
