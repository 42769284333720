import React, { useEffect, useState } from 'react'
import { HomeLayouts } from '../../components/layouts/HomeLayouts'
import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';
import { Box, Grid, Pagination, Typography } from '@mui/material'
import Loading from '../../components/Loading';
import styles from './market.module.scss'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { products } from './data';
import { CardProductMovil } from './components/CardProductMovil';
import SearchBarMarket from '../../components/SearchBar/SearchBarMarket';
import { BtnResume } from './components/BtnResume';
import { getProductsMarketPlace, getProductsMarketPlaceCategoryId } from '../../api/marcketPlace';
import { useSelector } from 'react-redux';
import SearchBar from '../../components/SearchBar/SearchBar';

export const CategoryMarket = () => {
  const { token } = useSelector((state) => state.auth)
  const { category, id } = useParams();
  const [loading, setloading] = useState(false)
  const [prod, setproductos] = useState(null)
  const [produFil, setproductosFil] = useState(null)
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1); // Página actual
  const [paginatedItems, setPaginatedItems] = useState([]);
  const ItemsPerPage = 48;


  useEffect(() => {
    setProducts()

  }, [])


  useEffect(() => {

    setProducts()


  }, [query])



  useEffect(() => {
    if (prod && prod.length > 0) {
      const startIndex = (currentPage - 1) * ItemsPerPage;
      const endIndex = startIndex + ItemsPerPage;
      setPaginatedItems(prod.slice(startIndex, endIndex)); // Dividir los items según la página
    }

  }, [currentPage, prod]);

  const handlePageChange = (event, page) => {

    setCurrentPage(page);
  };



  const setProducts = async () => {
    setloading(true)
    const productFil = []
    const pro = await getProductsMarketPlace(token, '200', query, id);

    if (pro.ok) {
      pro.data.forEach(product => {
        productFil.push({ ...product, cantidad: 1 })
      });;
      const filtered = productFil.filter(product => {
        // Aquí puedes agregar tus criterios de filtro, por ejemplo:
        return product.category.toLowerCase().includes(category.toLowerCase());
      });
      setproductos(filtered)
      setproductosFil(filtered)
      setloading(false)
    } else {

      setproductos(null)
      setproductosFil(null)
      setloading(false)

    }

  }


  const onBlourMar = () => {

    setproductos(null)

  }

  const onBlourMarText = () => {

    if (query.length > 0) {
      filterProducts()
    } else {
      setProducts()
    }
  }


  const filterProducts = () => {
    const filtered = produFil.filter(product => {
      // Aquí puedes agregar tus criterios de filtro, por ejemplo:
      return product.name.toLowerCase().includes(query.toLowerCase());
    });
    setproductos(filtered);
    //organizeByCategory(filtered);
  };







  return (

    <HomeLayouts>
      <Grid
        sx={{ paddingX: { xs: 1, md: 10 }, marginTop: { md: 5 } }}
        container
        alignItems={'center'}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}

      >
        <Grid
          marginBottom={3}
          item
          xs={6}
          md={4}
        >
          <Box display={'flex'} >
            <Back className={styles.back} onClick={() => navigate('/marketplace')} />
            <Typography fontWeight='bold' variant='h1' fontSize='28px' >{category}</Typography>
          </Box>
        </Grid>
        <Grid
          sx={{ display: { xs: 'flex', md: 'none' }, }}
          marginBottom={3}
        >
          {/* <BtnResume
            text="Resumen"
            size="small"
            onClick={() => navigate('/marketplace/carrito')}
          /> */}
        </Grid>
        <Grid

          item
          xs={12}
          md={4}
        >


          <SearchBar
            onBlur={onBlourMar}
            onBlurText={onBlourMarText}
            name="query"
            query={query}
            placeholder="Buscar en Cado..."
            setQuery={setQuery}
          />
        </Grid>
        <Grid

          flexDirection={'row'}
          justifyContent={'flex-end'}
          sx={{ display: { xs: 'none', md: 'flex' }, }}
          md={4}
          item
          xs={12}
        >

          {/* <BtnResume /> */}

        </Grid>
      </Grid>

      {
        loading ?
          <Box height={500} width={'100%'} >
            <Loading />
          </Box> :
          <Grid
            marginTop={2}
            marginBottom={5}
            sx={{ paddingX: { xs: 0, md: 6 } }}
            container
          >

            {
              paginatedItems && paginatedItems.map((item, index) => (
                <Grid
                  key={index}
                  marginTop={1}
                  item
                  md={6}
                  lg={4}
                  xl={3}
                  xs={12}
                  sx={{ paddingRight: { xs: 0, md: 0, lg: 4 } }}

                >
                  <CardProductMovil
                    key={item.id}
                    item={item}
                  />
                </Grid>

              ))
            }
          </Grid>
      }
      <Box
        padding={5}
        mb={25}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'center'}

      >
        {prod &&
          <Pagination
            page={currentPage}
            onChange={handlePageChange}
            component="div"
            count={Math.ceil(prod.length / ItemsPerPage)}
          />
        }
      </Box>
    </HomeLayouts>
  )
}
