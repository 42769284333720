import React from 'react';
import { useLocation } from 'react-router-dom';

import { shouldHideNavbar } from 'components/Sidebar/helpers.js';

import styles from './banner.module.scss';
import { Button } from '@mui/material';

const BannerEsta = () => {
    const { pathname } = useLocation();

    if (shouldHideNavbar(pathname)) return null;

    return (
        <div className={styles.bannerA}>
            <p className={styles.text}>
                Este reporte esta disponible solo para suscripción Gold. Puedes Actualizar{' '}
                <span className={styles.word}>tu suscripción aqui </span>.
                {' '}
                <Button
                    size='small'
                    target='_blank'
                    sx={{ fontWeight: 'bold', color: '#050624', textDecoration: true }}
                    href='https://wa.me/+593968652005'
                > Actualizar </Button>
            </p>
        </div>
    );
};

export default BannerEsta;
