import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { getOrderById } from 'api/orders';

import { getArrayOfErrors } from 'utils/errors';

import useStore from 'store';
import { useSelector } from 'react-redux';

const useOrders = () => {
  const { restaurant, user, token } = useSelector((state) => state.auth)
  const store = useStore((state) => state);
  const navigate = useNavigate();

  const reorder = async (id) => {
    try {

      const order = await getOrderById(user.id, parseInt(restaurant), id, token);
      const { products, supplier, note } = order;
      products.forEach((product) => {
        product.quantity = product.requestedQuantity;
      });

      localStorage.setItem('step', 3);
      localStorage.setItem(
        'state',
        JSON.stringify({
          supplierId: supplier.id,
          products,
          note,
        }),
      );
      navigate('/ordenes/nueva');
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {
          toast.error(errors[i]);
        }
      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    }
  };

  return {
    reorder,
  };
};

export default useOrders;
