

import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import {  useNavigate } from 'react-router-dom'
import Loading from 'components/Loading';
import { formatNumber } from '../../../utils/numbers';

export const TableProducts = ( { products, restaurant } ) => {



    const navigate = useNavigate()
  return (
     
    <Grid 
       sx={{ display:{ xs:'none', md:'flex'  }}}
       mt={4}
       container
       border={1}
       borderColor={'#E9E7E2'}
       borderRadius={2}
       >
            <Box  width='100%' >
                <Grid 
                    sx={{ borderBottomColor:'#E9E7E2' }}
                    borderBottom={1}                    
                    padding={1}
                    container 
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                    bgcolor='#F9F6F4'
                    >

                    <Grid item padding={1} md={3} >
                        <Typography fontWeight='bold' >Producto</Typography>
                    </Grid>
                    <Grid item padding={1}  md={3}>
                        <Typography fontWeight='bold' >Proveedor</Typography>
                    </Grid>
                    <Grid item padding={1} md={2} >
                        <Typography fontWeight='bold' >UDM</Typography>
                    </Grid>
                    <Grid item padding={1} md={2}>
                        <Typography fontWeight='bold' >Precio ref.</Typography>
                    </Grid>
                    <Grid item padding={1} md={2}>
                        <Typography fontWeight='bold' >Ultimo precio</Typography>
                    </Grid>
                </Grid>


                {
                    products.products < 1 ? <Typography textAlign='center' color='gray' >No hay resultados para los parametros de busqueda</Typography> :

                    products.products.map((producto)=>{

                        return(
                            <Grid 
                            key={ producto.id }
                            sx={{ cursor:'pointer', '&:hover':{
                                
                                backgroundColor:'#F9F6F4'
                                 }} }
                            component='div'
                            onClick={()=>navigate(`/productos/producto/${restaurant}/${producto.supplierid}/${producto.id}` )}               
                            container 
                            display='flex'
                            flexDirection='row'
                            justifyContent='space-between'
                           
                            >
        
        
                            <Grid  item padding={1} md={3} >
                                <Typography fontSize={14} noWrap >{producto.name} </Typography>
                            </Grid>
                            <Grid item padding={1}  md={3}>
                                <Typography fontSize={14}  noWrap > {producto.supplier} </Typography>
                            </Grid>
                            <Grid item padding={1} md={2} >
                                <Typography fontSize={14}  > {  producto.measurementUnit } </Typography>
                            </Grid>
                            <Grid item padding={1} md={2}>
                                <Typography fontSize={14}  > 
                                
                               
                                            {

                                                producto.refePrice ?  ` $${producto.refePrice} ` : null
                                            }
                                
                                
                                 </Typography>
                            </Grid>
                            <Grid item padding={1} md={2}   >
                                <Typography 
                                    fontSize={14} 
                                    padding={0.5} 
                                    borderRadius={1} 
                                    width='35%' 
                                    bgcolor={ parseFloat(producto.lastPrice)  <= parseFloat(producto.refePrice)  ? '#DEECDC' : producto.lastPrice === null ?'withe' : '#FAE3DE' }  >

                                            {
                                                producto.lastPrice ?  ` $${ formatNumber(producto.lastPrice)  } ` : null
                                            }
                                        
                                        </Typography>
                            </Grid>
        
                        </Grid>

                        )

                    })

                }


                        


            </Box>
    </Grid>


  )
}
