import React from 'react'
import DatePicker from 'react-multi-date-picker'
import DatePanel from 'react-multi-date-picker/plugins/date_panel';

export const DatePIckerMulti = ({ setDAtes }) => {



    const handleDate = async (data) => {
        const dateRange = {
            fromDate: `${data[0]?.year}-${data[0]?.month < 10 ? '0' + data[0].month : data[0]?.month}-${data[0]?.day < 10 ? '0' + data[0].day : data[0]?.day} 00:00:00`,
            toDate: data[1]?.year ? `${data[1]?.year}-${data[1]?.month < 10 ? '0' + data[1].month : data[1]?.month}-${data[1]?.day < 10 ? '0' + data[1].day : data[1]?.day} 23:59:59` : ''
        }
        setDAtes(dateRange);
    };


    return (
        <DatePicker

            range
            onChange={handleDate}
            plugins={[
                <DatePanel />
            ]}
            placeholder='Selecciona un rango de fechas'
            style={{ width: '70%', height: '40px' }}
        />
    )
}
