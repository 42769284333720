import React from 'react';
import styles from './StyleRegister/confirm.module.scss';

const ConfirmRegister = ({ completeRegister }) => (
  <div className={styles.infoContainer}>
    <div className={styles.headerContainer}>
      <h1 className={styles.authHeader}>Registro Exitoso</h1>
      <p className={styles.authText}>
        ¡Gracias por registrate con Cado! Ahora podrás ahorrar tiempo en todos tus
        pedidos.
      </p>
    </div>
    <button className={styles.submitAuth} onClick={(e) => completeRegister(e)}>
      Ingresar
    </button>
  </div>
);

export default ConfirmRegister;
