import React from 'react';
import styles from '../../../PopUp/pop-up.module.scss'
import CTARed from 'components/CTARed';
import { useState } from 'react';
import { Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { clearAuthV2 } from '../../../../redux/slices/authSlice/authSliceV2';
import { clearOrders } from '../../../../redux/slices/orderSlice';


const Closesession = ({
    setShowModal,


}) => {

    const [isLoading, setIsloading] = useState(false)
    const dispatch = useDispatch()

    const handleSubmit = () => {

        setIsloading(true)

        setTimeout(() => {
            dispatch(clearAuthV2())
            dispatch(clearOrders())
            setIsloading(false)
        }, 700);


    }


    return (
        <div className={styles.content}>

            <h1 className={styles.title}>Sesión </h1>
            <h2 className={styles.subtitle}>Tu sesión ha caducado por favor ingresa de nuevo para disfrutar de los servicios de Cado</h2>

            <div className={styles.bottomContainer}>
                <CTARed
                    isDisabled={isLoading}
                    text="Iniciar sesión"
                    onClick={handleSubmit}
                />
            </div>
        </div>
    );
};



export default Closesession;
