import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './products.module.scss'
import { Box, Grid, TablePagination, Typography } from '@mui/material'
import SearchBar from '../../components/SearchBar'
import { useState } from 'react'
import useStore from 'store';
import { TableProducts } from './components/TableProducts'
import { TableMobbileProducts } from './components/TableMobbileProducts'
import { useEffect } from 'react'
import Loading from 'components/Loading';
import { setProductsP, setProductsT } from '../../redux/slices/thunks'
import { TableReportDesk } from '../../components/Tables/TableReportDesk'
import Banner from '../../components/Banner/Banner'
import BannerEsta from '../../components/Banner/BannerEsta'
import { getSubscription } from '../../api/user'
import { HomeLayouts } from '../../components/layouts/HomeLayouts'
import { clearParamsO } from '../../redux/slices/paramsSlice'


export const Products = () => {
    const { restaurant, subscription, token } = useSelector((state) => state.auth)
    const { isLoadingP, products } = useSelector((state) => state.productsReducer)
    const [query, setquery] = useState('')
    // const subscription = useStore((state) => state.subscription);
    const [page, setpage] = useState(0)
    const store = useStore((state) => state);
    const [query2, setquery2] = useState('')
    const dispatch = useDispatch()
    const [params, setparams] = useState({
        supplier: '',
        product: '',
        page: 1,
        limit: 20,
    })



    useEffect(() => {
        dispatch(setProductsT({ ...params, restaurant }, token))
    }, [params])


    useEffect(() => {
        dispatch(clearParamsO())
        dispatch(setProductsT({ ...params, restaurant }, token))
        setpage(0)
        setparams({
            supplier: '',
            product: '',
            page: 1,
            limit: 20,
            query: '',
        })

    }, [restaurant])



    const handleChangePage = (event, newPage) => {
        setpage(newPage);
        dispatch(setProductsT({ ...params, page: newPage + 1, restaurant }, token))
        // setparams({ ...params, page: newPage + 1 })

    };


    const handleChangeRowsPerPage = (event) => {
        setpage(0)
        setparams({ ...params, page: 1, limit: parseInt(event.target.value, 10) });

    };


    if (!subscription || subscription.plan.abreviacion === 'estandar' || subscription.plan.abreviacion === 'free') {

        return (
            <HomeLayouts>
                <BannerEsta />
            </HomeLayouts>


        )

    }

    if (subscription.status === 'cancelada') {

        return (
            <HomeLayouts>
                <Banner />
            </HomeLayouts>
        )
    }


    return (

        <>

            <HomeLayouts>
                {



                    <div className={styles.content}>

                        <div className={styles.header}>
                            <div>
                                <h1 className={styles.title}>Productos</h1>
                            </div>
                        </div>


                        <Grid
                            container
                            display='flex'
                            flexDirection='row'
                            justifyContent='space-between'

                        >

                            <Box mb={2} sx={{ width: { xs: '100%', sm: 300, md: 300 } }} >

                                <SearchBar
                                    name="query"
                                    query={params.product}
                                    placeholder="Buscar producto"
                                    setQuery={(val) => {
                                        setparams({ ...params, product: val });
                                    }}
                                />
                            </Box>

                            <Box mb={2} sx={{ width: { xs: '100%', sm: 300, md: 300 } }}  >
                                <SearchBar
                                    name="query"
                                    query={params.supplier}
                                    placeholder="Buscar proveedor"
                                    setQuery={(val) => {
                                        setparams({ ...params, supplier: val });
                                    }}
                                />
                            </Box>
                        </Grid>


                        {
                            isLoadingP ?
                                <Box mt={12} >
                                    <Loading />
                                </Box>

                                :

                                products ?

                                    <>
                                        <TableProducts
                                            products={products}
                                            restaurant={restaurant}
                                        />

                                        <TableMobbileProducts
                                            products={products}
                                            restaurant={restaurant}
                                        />

                                        <TablePagination
                                            sx={{ marginTop: -5 }}
                                            component="div"
                                            labelRowsPerPage='Filas por página'
                                            rowsPerPageOptions={[20, 25, 30]}
                                            count={products.totalCount}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            rowsPerPage={params.limit}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </> :
                                    <Box mt={3} >
                                        <Typography color='gray' textAlign={'center'} > Aún no hay productos para este comercio </Typography>
                                    </Box>

                        }

                    </div>

                }

            </HomeLayouts>


        </>

    )
}
