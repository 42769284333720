
import Chart from 'react-apexcharts'
import React from 'react'

export const PieChart = ({labels, series, title, subtitle}) => {

    const options = {
        chart: {
          width: '100%',
          type: 'pie',
        },
        
        labels: labels,
        theme: {
          monochrome: {
            enabled: true
          }
        },
        fill: {
          colors:[ '#FBFFB1', '#95BDFF', '#E5BA73', '#b6d6e3', '#AFC8AD', '#A0C49D'],
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -5
            }
          }
        },
        
        title: {
         
          text: title,
          style: {
            fontSize:  '16px',
            fontWeight:  'bold',
            
          },
        },
        subtitle: {
          text: subtitle,         
          style: {
            fontSize:  '15px',
            fontWeight:  'normal',
            fontFamily:  undefined,
            color:  '#9699a2'
          },
      },
        dataLabels: {
          style: {
            fontWeight:'none',
            colors: ['black', 'black', 'black', 'black','black', 'black', 'black', 'black','black', 'black', 'black', 'black','black', 'black', 'black', 'black']
          },
          formatter(val, opts) {
        
            //return val + '%'
            
      

              const name = opts.w.globals.labels[opts.seriesIndex]
              return [name, opts.w.globals.initialSeries[opts.seriesIndex]]
            
            
          }
        },
        legend: {
          show: false
          
        }
      }

       const seriesl =  series

  return (
    <Chart type='pie' options={options} series={seriesl} /> 
  )
}
