import React from 'react';
import { Routes, Route } from 'react-router-dom';


import NotFound from 'router/components/NotFound';

import { QuotesDetail } from './components/QuotesDetail';
import { Quotes } from './Quotes';
import { QuotesConfirmation } from './QuotesConfirmation';

const QuotesRoute = ({ restaurant }) => {

  return (
    <>
      <Routes>
        
        <Route path={'/'} element={<Quotes restaurant={restaurant} />} />
        <Route path={'/detalle_cotizacion/:id'} element={<QuotesDetail />} /> 
        <Route path={'/confirmacion_cotizacion/:id'} element={<QuotesConfirmation />} />         
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default QuotesRoute;
