
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './products.module.scss'
import { Box, Grid, TablePagination } from '@mui/material'
import { useState } from 'react'
import { TableProducts } from './components/TableProducts'
import { TableMobbileProducts } from './components/TableMobbileProducts'
import { useEffect } from 'react'
import makeAnimated from "react-select/animated";
import Loading from 'components/Loading';

import { Option } from '../../components/Option/Option'
import { setDoc, setDocRe, setSuppliersDocs, setSuppliersR } from '../../redux/slices/thunks'
import { components } from 'react-select';
import useStore from 'store';
import { DatePIckerMulti } from '../../components/DatePickerCAdo/DatePIckerMulti'
import MySelect from '../../components/Select/MySElect'
import BannerEsta from '../../components/Banner/BannerEsta'
import { getSubscription } from '../../api/user'
import Banner from '../../components/Banner/Banner'
import { HomeLayouts } from '../../components/layouts/HomeLayouts'
import { clearParamsO } from '../../redux/slices/paramsSlice'


export const Documents = () => {
    const { restaurant, subscription, token } = useSelector((state) => state.auth)
    const animatedComponents = makeAnimated();
    const [suppliersL, setsuppliersL] = useState({ optionSelected: null })
    const [suppliers, setsuppliers] = useState({ optionSelected: [] })
    // const subscription = useStore((state) => state.subscription);
    const { isLoadingP, documents } = useSelector((state) => state.documents)
    const { supplierListAll, isLoading } = useSelector((state) => state.suppliersReducer)
    const [page, setpage] = useState(0)
    const store = useStore((state) => state);
    const dispatch = useDispatch()
    const [dates, setDates] = useState({
        fromDate: '',
        toDate: ''
    });

    useEffect(() => {
        enviarInfoirm()
    }, [suppliers, dates])


    useEffect(() => {
        dispatch(clearParamsO())
        setData()

    }, [restaurant])


    const MultiValue = props => (
        <components.MultiValue {...props}>
            <span>{props.data.label}</span>
        </components.MultiValue>
    );

    const setData = () => {
        if (restaurant) {
            dispatch(setSuppliersDocs(restaurant, token))
            dispatch(setDoc({
                restaurantId: restaurant,
                fromDate: '',
                toDate: '',
                suppliers: []
            }, token))
        }


    }

    const handleChange = selected => {
        const suppliersAux = selected.map(val => val.value);
        setsuppliers({
            optionSelected: suppliersAux
        });
        setsuppliersL({
            optionSelected: selected
        });
    };
    const enviarInfoirm = () => {

        dispatch(setDocRe({
            restaurantId: restaurant,
            fromDate: dates.fromDate,
            toDate: dates.toDate,
            suppliers: suppliers.optionSelected

        }, token))

    }


    if (!subscription || subscription.plan.abreviacion === 'estandar' || subscription.plan.abreviacion === 'free') {

        return (
            <HomeLayouts>
                <BannerEsta />
            </HomeLayouts>


        )

    }

    if (subscription.status === 'cancelada') {

        return (
            <HomeLayouts>
                <Banner />
            </HomeLayouts>


        )

    }


    return (

        <>

            <HomeLayouts>
                {

                    isLoadingP ? <Loading /> :

                        <div className={styles.content}>

                            <div className={styles.header}>
                                <div>
                                    <h1 className={styles.title}>Documentos</h1>
                                </div>
                            </div>


                            <Grid
                                container
                                display='flex'
                                flexDirection='row'
                                justifyContent='space-between'

                            >

                                <Grid item md={5} xs={12} display='flex' flexDirection={'column'} >

                                    <label > Seleccionar proveedor: </label>

                                    {
                                        supplierListAll && supplierListAll.length ?

                                            <MySelect
                                                options={supplierListAll}
                                                isMulti
                                                className="basic-multi-select"
                                                hideSelectedOptions={false}
                                                components={{ Option, MultiValue, animatedComponents }}
                                                onChange={handleChange}
                                                allowSelectAll={true}
                                                placeholder='Selecciona los proveedores'
                                                isSearchable
                                                value={suppliersL.optionSelected}
                                            />
                                            :
                                            null
                                    }



                                </Grid>
                                <Grid item md={4} xs={12} display='flex' flexDirection={'column'} justifyContent='center' >
                                    <label > Seleccionar rango de fechas: </label>
                                    <DatePIckerMulti
                                        setDAtes={setDates}
                                    />
                                </Grid>
                            </Grid>
                            {
                                documents !== null ?
                                    <>
                                        <TableProducts
                                            products={documents}
                                            restaurant={restaurant}
                                        />
                                        <TableMobbileProducts
                                            products={documents}
                                            restaurant={restaurant}
                                        />

                                    </> :
                                    null
                            }
                        </div>
                }

            </HomeLayouts>


        </>

    )
}
