import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CTA from 'components/CTA';
import PopUp from 'components/PopUp';
import AddSupplier from 'components/PopUp/components/AddSupplier';
import emptySupplier from 'assets/emptySupplier.svg';

import styles from './noSuppliers.module.scss';

const NoSuppliers = ({ setRerender }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className={styles.content}>
        <PopUp setShowModal={setShowModal} showModal={showModal}>
          <AddSupplier setRerender={setRerender} setShowModal={setShowModal} />
        </PopUp>
        <div className={styles.header}>
          <div>
            <h1 className={styles.title}>Mis Proveedores</h1>
          </div>
        </div>
        <div className={styles.desktopContainer}>
          <div className={styles.desktopContent}>
            <h3 className={styles.subtitleDesktop}>Tu lista está vacía</h3>
            <p className={styles.pDesktop}>
              Aún no tienes proveedores. Agrega uno nuevo a tu lista y empieza a realizar
              pedidos.
            </p>
            <img src={emptySupplier} alt="No suppliers" className={styles.img} />
            <CTA
              text="Añadir Proveedor"
              view="tablet"
              onClick={() => {
                setShowModal(true);
              }}
            />
          </div>
        </div>
        <div className={styles.mobileContent}>
          <h2 className={styles.subtitle}>Mis Proveedores</h2>
          <p className={styles.p}>
            Aún no tienes proveedores. Agrega uno nuevo a tu lista y empieza a realizar
            pedidos.
          </p>
        </div>
        <div className={styles.ctaContainer}>
          <CTA
            text="Añadir Proveedor"
            view="mobile"
            onClick={() => {
              setShowModal(true);
            }}
          />
        </div>
      </div>
    </>
  );
};

NoSuppliers.propTypes = {
  setRerender: PropTypes.func.isRequired,
};

export default NoSuppliers;
