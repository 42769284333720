/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';

import plusIcon from 'assets/plus.svg';
import minusIcon from 'assets/minus.svg';

import { sumAndSubstract } from 'utils/numbers';

import styles from './quantityModifier.module.scss';
import { Box } from '@mui/material';

const QuantityModifierAux = ({
    price,
    product,
    state,
    setState,
    quantityKey,
    shouldRemoveEmpty = false,
}) => {
    const handleKeyPress = (e) => {
        const regex = new RegExp(/^\d*\.?\d*$/);
        if (!regex.test(e.key) && e.key !== ',') {
            e.preventDefault();
        } else if (e.target.value.includes('.') && (e.key === '.' || e.key === ',')) {
            e.preventDefault();
        } else if (e.target.value.includes(',') && (e.key === ',' || e.key === '.')) {
            e.preventDefault();
        }
    };



    const modifyProducts = (id, quantity, product) => {

        id = Number(id);

        const copyArray = [...state.products];

        if (copyArray.find((product) => product.id === id)) {
            copyArray.find((product) => product.id === id)[quantityKey] = quantity;
            copyArray.find((product) => product.id === id)['confirmedQuantity'] = null;
            copyArray.find((product) => product.id === id)['requestedQuantity'] = quantity;
        } else {

            if (quantity !== null && quantity > 0) {
                const newProduct = { ...product, [quantityKey]: quantity };
                copyArray.push(newProduct);
            }
        }
        setState({ ...state, products: copyArray });
    };

    const handleBlur = (id, quantity) => {



        id = Number(id);

        const copyArray = [...state.products];

        if (copyArray.find((product) => product.id === id)) {
            if (quantity === '' || quantity === 0) {
                copyArray.find((product) => product.id === id)[quantityKey] = 0;
                setState({ ...state, products: copyArray });
            }
            if (quantity === null || quantity <= 0 || quantity === '.' || quantity === ',') {
                if (shouldRemoveEmpty) {
                    copyArray.splice(
                        copyArray.findIndex((product) => product.id === id),
                        1,
                    );
                    setState({ ...state, products: copyArray });
                }
            } else if (quantity.endsWith('.')) {
                copyArray.find((product) => product.id === id)[quantityKey] = Number(quantity);
                setState({ ...state, products: copyArray });
            } else if (quantity.includes(',') || quantity.startsWith('.')) {
                copyArray.find((product) => product.id === id)[quantityKey] = Number(
                    quantity.replace(',', '.'),
                );
                setState({ ...state, products: copyArray });
            }
        }
    };

    const modifyAmount = (operation, id, product) => {
        const input = document.querySelector(`input[name="${id}"]`);
        input.value = input.value.replace(/,/g, '.');

        let parsedValue = Number(input.value);

        if (operation === 'plus') {
            input.value = sumAndSubstract(parsedValue + 1);
            modifyProducts(id, sumAndSubstract(parsedValue + 1), product);
        }

        if (operation === 'minus') {
            if (parsedValue > 1) {
                input.value = sumAndSubstract(parsedValue - 1);
                modifyProducts(id, sumAndSubstract(parsedValue - 1), product);
            } else {
                input.value = '';
                handleBlur(id, 0);
            }
        }
    };

    const getInitialInputValue = (id) => {
        for (let i = 0; i < state.products.length; i++) {
            if (state.products[i].id === id) {
                // if (state.products[i].confirmedQuantity !== null) {
                //     return state.products[i]['confirmedQuantity'];
                // } else {
                //     return state.products[i]['requestedQuantity'];
                // }
                return state.products[i][quantityKey];
            }
        }
        return '';
    };

    const isProductInCart = (id) => {
        for (let i = 0; i < state.products.length; i++) {
            if (state.products[i].id === id) {
                return true;
            }
        }
        return false;
    };







    return (
        <Box  >
            <div className={price != false ? styles.quantityContainer : styles.quantityContainerPriceN}>
                <div className={styles.inputContainer}>
                    <input
                        className={styles.input}
                        name={product.id}
                        type="text"
                        inputMode="decimal"
                        value={getInitialInputValue(product.id)}
                        onChange={(e) => modifyProducts(e.target.name, e.target.value, product)}
                        onBlur={(e) =>
                            setTimeout(() => {
                                handleBlur(e.target.name, e.target.value, product);
                            }, 250)
                        }
                        onKeyPress={(e) => handleKeyPress(e)}
                        disabled={!isProductInCart(product.id)}
                    />
                    {isProductInCart(product.id) && <span>{product.measurementUnit}</span>}
                </div>
            </div>
        </Box>

    );
};

QuantityModifierAux.propTypes = {
    product: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    setState: PropTypes.func.isRequired,
    quantityKey: PropTypes.string.isRequired,
    shouldRemoveEmpty: PropTypes.bool,
};

export default QuantityModifierAux;
