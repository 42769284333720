
import React from 'react';
import { useLocation } from 'react-router-dom';

import { shouldHideNavbar } from 'components/Sidebar/helpers.js';

import styles from './banner.module.scss';
import { Button } from '@mui/material';

const BannerFree = () => {
    const { pathname } = useLocation();

    if (shouldHideNavbar(pathname)) return null;

    return (
        <div className={styles.banner}>
            <p className={styles.text}>
                Has llegado al límite de órdenes solicitadas por mes. Actualiza tu{' '}
                <span className={styles.word}>suscripción</span> para poder seguir ordenando en Cado. {' '}
                <Button
                    size='small'
                    target='_blank'
                    sx={{ color: '#050624', textDecoration: true }}
                    href='https://wa.me/+593968652005'
                >Actualiza aquí.</Button>
            </p>
        </div>
    );
};

export default BannerFree;
