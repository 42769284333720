import React, { useEffect, useState } from 'react';
import { getSupplierWithContact } from 'api/suppliers';
import { updateSupplierContact } from '../../../../api/restaurants';
import { useParams, useNavigate } from 'react-router-dom';

import { toast } from 'react-hot-toast';
import useStore from 'store';
import { getArrayOfErrors } from 'utils/errors';

import PopUp from 'components/PopUp';
import EditSalesman from 'components/PopUp/components/EditSalesman';

import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';

import styles from './supplierInfo.module.scss';
import { useSelector } from 'react-redux';
import EditTerm from '../../../../components/PopUp/components/EditTerm/EditTerm';
import PopUpP from '../../../../components/PopUp/PopUpP';
import { dateExpiration } from '../../../../api/suppliers';
import Loading from '../../../../components/Loading/Loading';
import { HomeLayouts } from '../../../../components/layouts/HomeLayouts';

const SupplierInfo = () => {

  const { restaurant, user, token } = useSelector((state) => state.auth)
  const { id } = useParams();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [showModalP, setShowModalP] = useState(false);
  const [supplier, setSupplier] = useState(null);
  const [loading, setloading] = useState(false);

  const [isDisabled, setisDisabled] = useState(false);

  //dateExpiration

  const updateDateExpiration = async (data) => {



    try {

      setisDisabled(true)
      await dateExpiration(data, id, token);
      setloading(true)
      setShowModalP(false);
      toast.success('Plazo actualizado');
      setTimeout(async () => {
        const data = await getSupplierWithContact(parseInt(restaurant), id, token);
        setSupplier(data);
        setisDisabled(false)
        setloading(false)

      }, 1000);
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {
          toast.error(errors[i]);
        }
        setloading(false)
        setisDisabled(false)
      } else {
        toast.error('Error interno. Inténtelo más tarde');
        setloading(false)
        setisDisabled(false)
      }
    }
  };

  const updateContact = async (contact) => {
    try {

      await updateSupplierContact(parseInt(restaurant), id, contact, token);
      setShowModal(false);
      toast.success('Contacto actualizado');
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {
          toast.error(errors[i]);
        }
      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    }
  };

  useEffect(async () => {

    const data = await getSupplierWithContact(parseInt(restaurant), id, token);
    setSupplier(data);
  }, [id]);

  if (!supplier) return <HomeLayouts><Loading /></HomeLayouts>;

  if (loading) return <HomeLayouts><Loading /></HomeLayouts>

  return (


    <HomeLayouts>
      <div>
        <div className={styles.content}>
          <PopUp showModal={showModal} setShowModal={setShowModal}>
            <EditSalesman updateContact={updateContact} setShowModal={setShowModal} />
          </PopUp>
          <PopUpP showModal={showModalP} setShowModal={setShowModalP}>
            <EditTerm updateContact={updateDateExpiration} restaurant_id={restaurant} isDisabled={isDisabled} value={supplier.payday} />
          </PopUpP>
          <div className={styles.desktopHeader}>Mis Proveedores Test</div>
          <div className={styles.header}>
            <Back
              className={styles.back}
              onClick={() => {
                navigate('/proveedores');
              }}
            />
            <h2 className={styles.supplierName}>{supplier.name}</h2>
          </div>
          <div className={styles.cardsContainer}>
            <div className={styles.card}>
              <div className={styles.cardTitle}>Información del Proveedor</div>
              <div className={styles.cardField}>
                <div className={styles.label}>Nombre: </div>
                <div className={styles.value}>{supplier.name}</div>
              </div>
              <div className={styles.cardField}>
                <div className={styles.label}>RUC: </div>
                <div className={styles.value}>{supplier.identifier}</div>
              </div>
              {!supplier.isActive && <div className={styles.inactiveLabel}>Inactivo</div>}
            </div>
            <div className={styles.card}>

              {

              }

              <div className={styles.cardTitle}>Información del Vendedor</div>
              <div className={styles.cardField}>
                <div className={styles.label}>Nombre: </div>
                <div className={styles.value}>{supplier.contact.name}</div>
              </div>
              <div className={styles.cardField}>
                <div className={styles.label}>Teléfono: </div>
                <div className={styles.value}>{supplier.contact.phone}</div>
              </div>
              {
                supplier?.subscription !== null && supplier?.subscription.abreviacion
                  === 'pro' ? null :
                  <div
                    className={styles.link}
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    Cambiar Vendedor
                  </div>
              }

            </div>


            <div className={styles.card}
              style={{
                display: user.roles[0].name === 'Staff' ? 'none' : null,
              }}
            >
              <div className={styles.cardTitle}>Información de Pago</div>
              <div className={styles.cardField}>
                <div className={styles.label}>Plazo: </div>
                <div className={styles.value}> {supplier.payday} </div>
              </div>

              <div
                className={styles.link}
                onClick={() => {
                  setShowModalP(true);
                }}
              >
                Cambiar Plazo
              </div>
            </div>
          </div>
        </div>
      </div>
    </HomeLayouts>

  );
};

export default SupplierInfo;
