import toast from "react-hot-toast";
import { login } from "../../../api/auth";
import { getSubscription, getUser } from "../../../api/user";
import { setRestaurant } from "../restaurantSlice";
import { setErrorToken, setErrorV2, setJWT, setSubscriptionV2, setUserV2, startAuthV2 } from "./authSliceV2";

//status: 'cheking' | 'not-authenticated' | 'get-user' | 'not-complete' | 'authenticated'


export const LoginV =  (data) => {
        

  
    return async (dispatch) => {
    

        try {
          dispatch(startAuthV2())
          const token =  await login(data);   
    
         
          if (token.data.app === 'restaurant') {
            dispatch(setJWT(token.data.token)) 

          const user = await getUser(token.data.token, data.phone); 
          const subscription = await getSubscription(token.data.token, user.id)
          let restaurantList = user.restaurants.map((val) => {
            return {
                id: val.restaurantId,
                name: val.restaurant,
                value: val.restaurantId,
                address: val.address,
                city: val.city,
                state: val.state,
              };
           });
        let restaurantListSelect = user.restaurants.map((val) => {
            return {
                id: val.id ? val.id : val.restaurantId,
                name: val.name ? val.name : val.restaurant,
                value: val.value ? val.value : val.restaurantId,
              };
            });
          
          dispatch(setSubscriptionV2(subscription.data))
            if (!user.complete) {

             
                dispatch(setUserV2({ user, status: 'not-complete', restaurant: user.restaurants[0].restaurantId, restaurantSelect:restaurantListSelect, restaurantList}))
               
            }else{

            
              dispatch(setUserV2({ user, status: 'authenticated', restaurant: user.restaurants[0].restaurantId, restaurantSelect:restaurantListSelect, restaurantList}))
             

            }
         
            
          }  else{
            dispatch( setErrorV2('Esta cuenta no esta registrada como comercio') )
          }

          
          
           
        } catch (error) {
          dispatch( setErrorV2(error.response.data) )
      
          toast.error('Error interno. Inténtelo más tarde');
             
           
        }

              }
   }


   export const me =  (restaurant, token, phone ) => {
             
 

    return async (dispatch) => {
               
            try {
              const user = await getUser(token, phone); 

              const subscription = await getSubscription(token, user.id)
                   
              dispatch(setSubscriptionV2(subscription.data))

                let restaurantList = user.restaurants.map((val) => {
                    return {
                        id: val.restaurantId,
                        name: val.restaurant,
                        value: val.restaurantId,
                        address: val.address,
                        city: val.city,
                        state: val.state,
                      };
          });
                let restaurantListSelect = user.restaurants.map((val) => {
                    return {
                        id: val.id ? val.id : val.restaurantId,
                        name: val.name ? val.name : val.restaurant,
                        value: val.value ? val.value : val.restaurantId,
                      };
          });
          if (!user.complete) {

             
            dispatch(setUserV2({ user, status: 'not-complete', restaurant: restaurant, restaurantSelect:restaurantListSelect, restaurantList}))
            
        }else{

        
          dispatch(setUserV2({ user, status: 'authenticated', restaurant: restaurant, restaurantSelect:restaurantListSelect, restaurantList}))
         

        }
               
              
               
            } catch (error) {
         
              if (error.response.data === 'Su token ha caducado por favor vuelve a iniciar sesión') {
                dispatch( setErrorToken( true ) )
              }else{
                dispatch( setErrorV2(error.response.data) )
              }

             // 
            }
              }

   }