import React from 'react';
import styles from '../../../PopUp/pop-up.module.scss'
import CTARed from 'components/CTARed';
import { useState } from 'react';
import { getArrayOfErrors } from 'utils/errors';
import { Box, Checkbox, FormControlLabel, InputAdornment, OutlinedInput, TextField, Typography } from '@mui/material';
import CTA from '../../../CTA';
import { CheckBox } from '@mui/icons-material';
import { flashQuote } from '../../../../api/marcketPlace';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';


const QuoteFlash = ({
    item,
    setShowModal,
       onClick

}) => {
   
    const { restaurant, subscription, user, token } = useSelector((state) => state.auth)
    const [checked, setChecked] = React.useState(false);
    const [canti, setCant] = React.useState(null);
    const [nota, setNota] = React.useState(null);
    const [isLoading, setIsloading] = useState(false)

    const handleSubmit = async (item) => {
        setIsloading(true)
        const data =[{
            supplierId: item.supplierId.toString() ,
            anonymous: checked,
            note: nota,           
            products: [
              {
                productId: item.id,
                quantity: parseInt(canti) 
              }
            ]
          }]

        
          try {
            const resp = await flashQuote(token, user.id, restaurant, data  )

    
            toast.success('Cotización enviada de manera correcta');
            setTimeout(() => {
                setShowModal(false)
                setIsloading(false)
            }, 500);
          } catch (error) {
            if (error.response) {
                const errors = getArrayOfErrors(error.response.data);
                for (let i = 0; i < errors.length; i++) {
                  toast.error(errors[i]);
                }
                setShowModal(false)
                setIsloading(false)
              } else {
                toast.error('Error al añadir proveedor');
                setShowModal(false)
                setIsloading(false)
              }

        
          }

       
          
       
    

    }
    const handleChange = (event) => {
        setChecked(event.target.checked);
      };


     

    return (
       <Box>
            <Typography fontSize={23} fontWeight={'bold'} >{ item.name }</Typography>
            <Typography fontSize={17} fontWeight={'bold'} color={'#000000'} >{ item.measurementUnit }</Typography>
            <Typography fontSize={15}  >{ item.category }</Typography>
            <Typography fontSize={15} fontWeight={'bold'} color={'#6d6d6d'} >{ item.supplier }</Typography>
           


            <Box mt={3} display={'flex'} flexDirection={'column'}>
              <Typography>Cantidad a ordenar (Mensual)</Typography>
              <OutlinedInput
                  onChange={( e)=>setCant(e.target.value)}
                  id="outlined-adornment-weight"
                  endAdornment={<InputAdornment style={{ marginRight:15 }} position="end">/{ item.measurementUnit}</InputAdornment>}
                  size='small'
                  value={!canti ? '' : canti}          
                  aria-describedby="outlined-weight-helper-text"
                  type='number'
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                />
             <Box ml={1} >
            {/* <Typography> { item.measurementUnit } </Typography> */}
            </Box>
           
            </Box>
            <Box mt={3} display={'flex'} flexDirection={'column'}>
              <Typography>Notas</Typography>
            <TextField 
                onChange={( e)=>setNota(e.target.value)}
                value={!nota ? '' : nota}                
                multiline
                size='small'
                type='number'
            />
            <Box ml={1} >
            {/* <Typography> { item.measurementUnit } </Typography> */}
            </Box>
           
            </Box>
            {/* <Box mt={3} >
                <FormControlLabel control={
                    <Checkbox 
                        color='success'
                        checked={checked}
                        onChange={handleChange}
                     />
                 } label="Deseas que tu cotizacion sea anonima?" />
            </Box> */}
           

            <Box mt={3} >
                <CTA
                    isDisabled={ canti === null || isLoading ? true :  false }
                    onClick={()=>handleSubmit(item) }
                    text='Cotizar'
                />
            </Box>

       </Box>
    );
};



export default QuoteFlash;
