import React, { useEffect } from 'react';
import styles from './StyleRecover/step1.module.scss';
import Sidebar from 'components/Sidebar';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import InputTag from 'components/Input';

const Step1 = ({
  phone,
  newPassword,
  recoverPasswordState,
  handleRecoverChange,
  handleRecoverPhoneChange,
  recoverError,
  disablePassRecovery,
  recoverPassword,
  toLogin,
  validateRecoverPassword,
}) => {
  useEffect(validateRecoverPassword, [phone, newPassword]);

  return (
    <>
      <form
        className={styles.infoContainer}
        onSubmit={(e) => recoverPassword(e, recoverPasswordState)}
      >
        <div className={styles.styleInfoContainer}>
          <div className={styles.headerContainer}>
            <p className={styles.authHeader}>Recuperar Contraseña</p>
            <p className={styles.authText}>
              Por favor ingresa tu teléfono y tu nueva contraseña.
            </p>
          </div>
          <div className={styles.inputContainer}>
            <div
              className={styles.individualInputContainer}
              data-error={recoverError ? recoverError.phone : null}
            >
              <PhoneInput
                defaultCountry="EC"
                type="text"
                value={phone.phone}
                name="phone"
                placeholder="Teléfono"
                onChange={(e) => handleRecoverPhoneChange(e)}
                className={styles.input}
                required
              />
            </div>
            <div
              className={styles.individualInputContainer}
              data-error={recoverError ? recoverError.newPassword : null}
            >
              <InputTag
                type="password"
                value={newPassword.newPassword}
                name="newPassword"
                placeholder="Nueva Contraseña"
                onChange={(e) => handleRecoverChange(e)}
                className={styles.input}
              />
            </div>
            {typeof recoverError === 'string' ? (
              <span className={styles.errorSpan}>{recoverError}</span>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className={styles.buttonContainer}>
          <input
            type="submit"
            value="Solicitar Código"
            disabled={disablePassRecovery}
            className={styles.submitAuth}
          />
          <div className={styles.navigateContainer}>
            <a className={styles.register} onClick={(e) => toLogin(e)}>
              Regresar
            </a>
          </div>
        </div>



      </form>
    </>
  );
};

export default Step1;
