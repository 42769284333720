import { createSlice } from "@reduxjs/toolkit";




const initialState = {

    restaurantsC:[],
    restaurant:null,
    isLoading:false,
} 


 export const  restaurantsSlice = createSlice({

    name:'restaurants',
    initialState:initialState,
    reducers:{
        startRestaurant:(state)=>{
            state.isLoading = true
        },
        setRestaurantsC: (state, action )=>{
                               
                state.restaurantsC = action.payload;
                state.isLoading = false;

        },
        setRestaurant: (state, action )=>{
                               
            state.restaurant = action.payload;
            state.isLoading = false;

    },
        clearRestaurant: (state, action )=>{
                           
        state.restaurant = null;
        state.isLoading = false;

},
clearRestaurants: (state )=>{
                           
    state.restaurantsC = [];
    state.isLoading = false;
    state.isLoading=false

}
    
        
    }


})

export const { startRestaurant, setRestaurantsC, setRestaurant, clearRestaurant, clearRestaurants } = restaurantsSlice.actions


