import { login } from "../../../api/auth";
import { getSubscription, getUser } from "../../../api/user";
import { clearAuth, setError, setSubscription, setUser, startAuth } from "./authSlice";


export const auth =  (data) => {
        
   
  
    return async (dispatch) => {

        dispatch(startAuth())
      
        try {

            await login(data);
            const user = await getUser(); 
            let restaurantList = user.restaurants.map((val) => {
                return {
                    id: val.restaurantId,
                    name: val.restaurant,
                    value: val.restaurantId,
                    address: val.address,
                    city: val.city,
                    state: val.state,
                  };
      });
            let restaurantListSelect = user.restaurants.map((val) => {
                return {
                    id: val.id ? val.id : val.restaurantId,
                    name: val.name ? val.name : val.restaurant,
                    value: val.value ? val.value : val.restaurantId,
                  };
      });

            dispatch( setUser( { user, restaurant: user.restaurants[0].restaurantId, restaurantSelect:restaurantListSelect, restaurantList} ) )

       

            const userSubscription = await getSubscription(user?.id)
           
            dispatch( setSubscription(userSubscription) )    
           
        } catch (error) {
            
            dispatch(setError(error.response.data));
        }

              }
   }

   export const me =  (restaurant ) => {
             

    return async (dispatch) => {
        dispatch(startAuth())
       
            try {
                 const user = await getUser(); 
         
                 if (user.role === "guestUser") {
                    dispatch(clearAuth())
                 }

                let restaurantList = user.restaurants.map((val) => {
                    return {
                        id: val.restaurantId,
                        name: val.restaurant,
                        value: val.restaurantId,
                        address: val.address,
                        city: val.city,
                        state: val.state,
                      };
          });
                let restaurantListSelect = user.restaurants.map((val) => {
                    return {
                        id: val.id ? val.id : val.restaurantId,
                        name: val.name ? val.name : val.restaurant,
                        value: val.value ? val.value : val.restaurantId,
                      };
          });
    
                dispatch( setUser( { user, restaurant: restaurant, restaurantSelect:restaurantListSelect, restaurantList} ) )
                const userSubscription = await getSubscription(user?.id)
                   
                dispatch( setSubscription(userSubscription) )    
               
            } catch (error) {
                
                dispatch(setError(error.response.data));
            }
              }

   }

   
   export const meStart =  () => {
             

    return async (dispatch) => {
        dispatch(startAuth())
       
            try {
                           const user = await getUser(); 
                let restaurantList = user.restaurants.map((val) => {
                    return {
                        id: val.restaurantId,
                        name: val.restaurant,
                        value: val.restaurantId,
                        address: val.address,
                        city: val.city,
                        state: val.state,
                      };
          });
                let restaurantListSelect = user.restaurants.map((val) => {
                    return {
                        id: val.id ? val.id : val.restaurantId,
                        name: val.name ? val.name : val.restaurant,
                        value: val.value ? val.value : val.restaurantId,
                      };
          });
    
                dispatch( setUser( { user, restaurant: user.restaurants[0].restaurantId, restaurantSelect:restaurantListSelect, restaurantList} ) )
                const userSubscription = await getSubscription(user?.id)
                dispatch( setSubscription(userSubscription) )    
               
            } catch (error) {
                
                dispatch(setError(error.response.data));
            }
              }
   }
