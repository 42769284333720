import routes from 'router/routeList';
import chartBar from 'assets/chartBarBlack.svg';
import packageIcon from 'assets/packageBlack.svg';
import receiptIcon from 'assets/receiptBlack.svg';
import userIcon from 'assets/userBlack.svg';
import logout from 'assets/logout.svg';

export default [
  { name: 'Resumen', to: routes.home, icon: chartBar },
  { name: 'Órdenes', to: routes.orders, icon: receiptIcon },
  //{ name: 'Reportes', to: routes.report, icon: packageIcon },
  { name: 'Proveedores', to: routes.suppliers, icon: packageIcon },
  { name: 'Perfil', to: routes.profile, icon: userIcon },
  { name: 'Cerrar sesión', to: routes.logout, icon: logout },
];
 