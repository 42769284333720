import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Router from './router';
import { getSubscription, getUser } from './api/user';
import { setSubscription, setUser } from './redux/slices/authSlice/authSlice';
import { useDispatch } from 'react-redux';
import { me } from './redux/slices/authSlice/thunks';






const App = () => {
  // const dispatch = useDispatch()

  // useEffect(() => {

  //   getUserL()


  // }, [])

  // const getUserL = async () => {

  //   dispatch(me())

  // }



  return (

    <BrowserRouter>

      <Router />

    </BrowserRouter>

  );
};

export default App;
