import axios from "axios";





export const getListOfFavoriteSuppliers = async (token, params) => {

  const { limit = 10, page = 1, query = '', idRestaurant } = params;

  

  const response = await axios.get( `${process.env.API_URL}/restaurants/${idRestaurant}/suppliers?limit=${limit}&page=${page}&query=${query} `,
 
  {
    withCredentials:true,
  headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': '*/*',
      'content-type': 'application/json'
      
  }
});
return response.data;
}


export const getListSuppliersAll = async (params, token) => {
  const {  idRestaurant } = params;

  const response = await axios.get(
    `${process.env.API_URL}/restaurants/${idRestaurant}/all-suppliers`,
    {
      withCredentials:true,
    headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'
        
    }
  }
  );

  return response.data;
};



export const getProductForRestaurant = async ( params, token) => {
 
  const { page = 1, limit = 5, restaurant, supplier='',product=''  } = params;

  const response = await axios.get(`${process.env.API_URL}/restaurants/${restaurant}/products?limit=${limit}&page=${page}&supplier=${supplier}&product=${product}`,
  {
    withCredentials:true,
  headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': '*/*',
      'content-type': 'application/json'
      
  }
}
  );
  return response.data;
};


export const detailProduct = async ( params, token) => {
 
  const { restaurant_id , supplier_id , product_id, month, year } = params;

  const response = await axios.get(`${process.env.API_URL}/restaurants/${restaurant_id}/suppliers/${supplier_id}/products/${product_id}?month=${month}&year=${year}`,
  
  {
    withCredentials:true,
  headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': '*/*',
      'content-type': 'application/json'
      
  }
}
  );
  return response.data;
};




export const getProducts = async ( data,  token) => {
  const response = await axios.post(`${process.env.API_URL}/restaurants/${data.restauranId}/find/products`, data, 
  
  {
    withCredentials:true,
  headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': '*/*',
      'content-type': 'application/json'
      
  }
}
  );
  return response.data;
};





export const addSupplierToRestaurantFavorites = async (data, idRestaurant, token) => {
  const response = await axios.post(
    `${process.env.API_URL}/restaurants/${idRestaurant}/suppliers`,
    data,
    {
      withCredentials:true,
    headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'
        
    }
  }
  );
  return response.data;
};



export const addRestaurantByUser = async (data, id, token) => {
  const response = await axios.post(`${process.env.API_URL}/restaurants/user/${id}/register-new`, data,

   {
      withCredentials:true,
    headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'
        
    }
  }
  
  );
  return response.data;
};


export const updateHoursRestaurant = async ( restaurant_id, data, token) => {
 
  const response = await axios.put(`${process.env.API_URL}/restaurants/${restaurant_id}/schedules`,
data,
{
  withCredentials:true,
headers: {
    'Authorization': `Bearer ${token}`,
    'Accept': '*/*',
    'content-type': 'application/json'
    
}
}
);
return response.data;
};

export const updateSupplierContact = async (idRestaurant, id, data, token) => {
  const response = await axios.put(
    `${process.env.API_URL}/restaurants/${idRestaurant}/suppliers/${id}/contact`,
    data,
    {
      withCredentials:true,
    headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'
        
    }
    }
  );
  return response.data;
};






































































export const updateRestaurant = async (data) => {
  const response = await axios.put('/restaurants/current', data);
  return response.data;
};













