

import { Box, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import {  useNavigate } from 'react-router-dom'
import DescriptionIcon from '@mui/icons-material/Description';
import Loading from 'components/Loading';
import { formatNumber } from '../../../utils/numbers';
import icon from '../../../assets/docPDF.png'
import xmlIcon from '../../../assets/xml.png'
import moment from 'moment';


export const TableProducts = ( { products, restaurant } ) => {



    const navigate = useNavigate()
  return (
     
    <Grid 
       sx={{ display:{ xs:'none', md:'flex'  }}}
       mt={4}
       container
       border={1}
       borderColor={'#E9E7E2'}
       borderRadius={2}
       >
            <Box  width='100%' >
                <Grid 
                    sx={{ borderBottomColor:'#E9E7E2' }}
                    borderBottom={1}                    
                    padding={1}
                    container 
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                    bgcolor='#F9F6F4'
                    >

                    <Grid item padding={1} md={2} >
                        <Typography fontWeight='bold' >Proveedor</Typography>
                    </Grid>
                    <Grid item padding={1}  md={2}>
                        <Typography fontWeight='bold' >Fecha de Emisión</Typography>
                    </Grid>
                    <Grid item padding={1}  md={2}>
                        <Typography fontWeight='bold' >Fecha de Caducidad</Typography>
                    </Grid>
                    <Grid item padding={1} md={2} >
                        <Typography fontWeight='bold' >Total Doc.</Typography>
                    </Grid>
                    <Grid item padding={1} md={2}>
                        <Typography fontWeight='bold' >Tipo Doc.</Typography>
                    </Grid>
                    <Grid item padding={1} md={1}>
                        <Typography fontWeight='bold' >Descarga PDF</Typography>
                    </Grid>
                    <Grid item padding={1} md={1}>
                        <Typography fontWeight='bold' >Descarga XML</Typography>
                    </Grid>
                </Grid>


                {
                    products?.length < 1 ? <Typography textAlign='center' color='gray' >No hay resultados para los parametros de busqueda</Typography> :

                    products.map((producto)=>{

                            const fecha = moment(producto.dateAuthorization).format('DD-MM-YYYY')
                            const fechaC = moment(producto.dateExpiration).add(1, 'days').format('DD-MM-YYYY')

                        return(
                            <Grid 
                            key={ producto.id }
                         
                            component='div'
                            container 
                            display='flex'
                            alignItems='center'
                            flexDirection='row'
                            justifyContent='space-between'
                           
                            >
        
        
                            <Grid  item padding={1} md={2} >
                                <Typography fontSize={14} noWrap >{producto.supplier} </Typography>
                            </Grid>
                            <Grid item padding={1}  md={2}>
                                <Typography fontSize={14}  noWrap > {fecha} </Typography>
                            </Grid>
                            <Grid item padding={1}  md={2}>
                                <Typography fontSize={14}  noWrap > {fechaC} </Typography>
                            </Grid>
                            <Grid item padding={1} md={2} >
                            <Typography fontSize={14}  > {`$${ formatNumber(producto.total)}`} </Typography>
                            </Grid>
                            <Grid item padding={1} md={2}>
                           
                                <Typography fontSize={14}  > Factura </Typography>
                          
                            </Grid>
                            {
                                producto.documentPdf != null ?
                                <Grid 
                                item 
                                padding={1} md={1}   >
                                    <IconButton 
                                        href={producto.documentPdf}
                                        aria-label="delete" size="small">
                                           <img src={icon} height={30} /> 
                                    </IconButton>
                               
                            </Grid> :
                               <Grid 
                               item 
                               padding={1} md={1}   >
                                   <Typography fontSize={11} color={'gray'} > - </Typography>
                              
                           </Grid>
                            }
                              {
                                producto.documentXml != null ?
                                <Grid 
                                item 
                                padding={1} md={1}   >
                                    <IconButton 
                                        href={producto.documentXml}
                                        aria-label="delete" size="small">
                                           <img src={xmlIcon} width={39} /> 
                                    </IconButton>
                               
                            </Grid> :
                               <Grid 
                               item 
                               padding={1} md={1}   >
                                   <Typography fontSize={11} color={'gray'} > - </Typography>
                              
                           </Grid>
                            }
                          
                        </Grid>
                        )
                    })
                }
               </Box>
    </Grid>


  )
}
