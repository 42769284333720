import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './summaryCTA.module.scss';
import { useDispatch } from 'react-redux';


const SummaryCTA = ({ products, changeStep }) => {

  const [productsl, setproductsl] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    filterProdu(products)
  }, [products])


  const filterProdu = (produ) => {

    const prod = produ.filter((product) => {

      return product.requestedQuantity !== 0 && product.requestedQuantity !== '0.0' && product.requestedQuantity !== '0'

    })

    setproductsl(prod)

  }


  return (
    <button
      disabled={productsl.length > 0 ? false : true}
      className={styles.button}
      onClick={() => {
        changeStep('next');

      }}
    >
      Resumen {productsl.length > 0 ? `(${productsl.length})` : ''}
    </button>
  );
};

SummaryCTA.propTypes = {
  products: PropTypes.array.isRequired,
  changeStep: PropTypes.func.isRequired,
};

export default SummaryCTA;
