import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { toast } from 'react-hot-toast';
import {
  getListOfFavoriteSuppliers,

} from '../../../../api/restaurants';

import { getArrayOfErrors } from 'utils/errors';

import SearchBar from '../../../../components/SearchBar';
import Loading from 'components/Loading';

import styles from './suppliers.module.scss';
import Pagination from 'react-js-pagination';
import useStore from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { HomeLayouts } from '../../../../components/layouts/HomeLayouts';
import { Box } from '@mui/material';
import { usePopUp } from '../../../../hooks/usePopUp';
import PopUp from '../../../../components/PopUp';



const Suppliers = ({ stateR, setSt, changeStep, setState, state }) => {
  const { restaurant, token } = useSelector((state) => state.auth)

  const store = useStore((state) => state);
  const [isLoading, setIsLoading] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(20);
  const [activePage, setActivePage] = useState(1);

  const [query, setQuery] = useState('');



  useEffect(async () => {
    setIsLoading(true);
    try {

      const data = await getListOfFavoriteSuppliers(token, {
        limit: postsPerPage,
        page: currentPage,
        query,
        idRestaurant: store.locationSelected || parseInt(restaurant),
      });

      setSuppliers(data.suppliers);
      setTotal(data.totalCount);

    } catch (error) {
      if (error.response) {

        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {

          toast.error(errors[i]);
        }


      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(async () => {

    const data = await getListOfFavoriteSuppliers(token, {
      limit: postsPerPage,
      page: currentPage,
      query,
      idRestaurant: store.locationSelected || parseInt(restaurant),
    });

    if (JSON.stringify(data) !== JSON.stringify(suppliers)) {
      setSuppliers(data.suppliers);
    }

  }, [query]);

  const handlePageChange = async (pageNumber) => {

    setActivePage(pageNumber);
    const data = await getListOfFavoriteSuppliers(token, {
      limit: postsPerPage,
      page: pageNumber,
      query,
      idRestaurant: store.locationSelected || parseInt(restaurant),
    });

    setSuppliers(data.suppliers);
  };

  return (

    <HomeLayouts>

      <div>
        <div className={styles.content}>

          {
            isLoading ? (

              <Box height={500} width={'100%'} >
                <Loading />
              </Box>


            ) :
              (
                <>
                  <div className={styles.header}>
                    <h1 className={styles.title}>Nueva Orden</h1>
                    <SearchBar
                      name="query"
                      query={query}
                      placeholder="Buscar proveedor"
                      setQuery={setQuery}
                    />
                  </div>

                  < h2 className={styles.subtitle}>Seleccionar proveedor</h2>



                  <div className={styles.suppliersContainer}>

                    {
                      suppliers.map((supplier, i) => {
                        return (
                          <div
                            key={i}
                            className={styles.supplier}
                            style={{
                              backgroundImage: `url(${supplier.coverPhoto
                                ? supplier.coverPhoto
                                : 'https://images.unsplash.com/photo-1542838132-92c53300491e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80'
                                })`,
                            }}
                            onClick={() => {


                              if (state.supplierId !== supplier.id) {

                                setState({
                                  supplierId: supplier.id,
                                  products: [],
                                  note: '',
                                  orderId: null,
                                });

                                changeStep('next');
                              } else {
                                setState({
                                  ...state,
                                  supplierId: supplier.id,
                                });
                              }
                              changeStep('next');
                            }}
                          >
                            {supplier.name}
                          </div>
                        );
                      })
                    }
                  </div>
                </>
              )

          }



          <div className={styles.pagination}>
            <Pagination
              activePage={activePage}
              itemsCountPerPage={postsPerPage}
              totalItemsCount={total}
              pageRangeDisplayed={10}
              innerClass={styles.pagination}
              linkClass={styles.linkClass}
              activeClass={styles.active}
              onChange={handlePageChange}
            />
          </div>
        </div>
      </div>

    </HomeLayouts >



  );
};

Suppliers.propTypes = {
  changeStep: PropTypes.func.isRequired,
  setState: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
};

export default Suppliers;
