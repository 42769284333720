import routes from 'router/routeList';
import chartBar from 'assets/chartBarBlack.svg';
import packageIcon from 'assets/packageBlack.svg';
import market from 'assets/market.jpeg'
import report from 'assets/reportBlack.svg';
import products from 'assets/productsBlack.svg';;

import receiptIcon from 'assets/receiptBlack.svg';
import userIcon from 'assets/userBlack.svg';
import logout from 'assets/logout.svg';

export default [
  { name: 'Resumen', to: routes.home, icon: chartBar },
  { name: 'Órdenes', to: routes.orders, icon: receiptIcon },
  //{ name: 'Reportes', to: routes.report, icon: packageIcon },
  { name: 'Proveedores', to: routes.suppliers, icon: packageIcon },
  { name: 'Perfil', to: routes.profile, icon: userIcon },
  { name: 'Cerrar sesión', to: routes.logout, icon: logout },
];
 

  

export const linksAH = [
    {
        name:'Marketplace', 
        to: routes.marketplace, 
        icon: chartBar,
        list:[]
    },
    { name: 'Resumen', to: routes.home, icon: chartBar },
    { name: 'Órdenes', to: routes.orders, icon: receiptIcon },
    { name: 'Reportes', to: routes.report, icon: report },
    { name: 'Productos', to: routes.products, icon: products },
    { name: 'Documentos', to: routes.documents, icon: products },
    { name: 'Proveedores', to: routes.suppliers, icon: packageIcon },
    { name: 'Perfil', to: routes.profile, icon: userIcon },
    { name: 'Cerrar sesión', to: routes.logout, icon: logout },

]

export const linksSH = [
    {
        name:'Marketplace', 
        to: routes.marketplace, 
        icon: market,
        list:[]
    },
    { name: 'Resumen', to: routes.home, icon: chartBar },
    { name: 'Órdenes', to: routes.orders, icon: receiptIcon },
    //{ name: 'Reportes', to: routes.report, icon: packageIcon },
    { name: 'Proveedores', to: routes.suppliers, icon: packageIcon },
    { name: 'Perfil', to: routes.profile, icon: userIcon },
    { name: 'Cerrar sesión', to: routes.logout, icon: logout },
]


export const linkADmin = [
    {
        name:'Marketplace', 
        to: routes.marketplace, 
        icon: market,
        list:[]
    },
  {
      name:'Resumen', 
      to: routes.home, 
      icon: chartBar,
      list:[]
  },
  {
      name: 'Mi local', 
      to: routes.orders,
      icon: receiptIcon,
      list:[
          {
             name:'Ordenes',
             to: routes.orders,
          },
          { 
            name: 'Cotizaciones', 
            to: routes.cotizaciones, icon: products
            
        }  ,
           {
            name: 'Proveedores', 
            to: routes.suppliers,
         },
         { 
            name: 'Documentos', 
            to: routes.documents, icon: products
            
        },
            
      ]
  },
  {
      name: 'Reportes', 
      to: routes.report,
      icon: report,
      list:[   
        // {
        //     name:'Analítica',
        //     to: routes.chart,
        //  },  
          
   {
      name:'Productos',
      to: routes.products,
   },
   {
       name: 'Compras', 
       to: routes.report
    },
          
   
          ]
   },
   { 
      name: 'Perfil', 
      to: routes.profile, 
      icon: userIcon,
      list:[]
  },
   { 
      name: 'Cerrar sesión', 
      to: routes.logout, 
      icon: logout ,
      list:[]
   },

]



export const linkEstandarH = [
    {
        name:'Marketplace', 
        to: routes.marketplace, 
        icon: market,
        list:[]
    },
    {
        name:'Resumen', 
        to: routes.home, 
        icon: chartBar,
        list:[]
    },
    {
        name: 'Mi local', 
        to: routes.orders,
        icon: receiptIcon,
        list:[
            
            {
               name:'Ordenes',
               to: routes.orders,
            },
            {
                name: 'Cotizaciones', 
                to: routes.cotizaciones, icon: products
            },
             {
              name: 'Proveedores', 
              to: routes.suppliers,
           },
        
        ]
    },
    {
        name: 'Reportes', 
        to: routes.report,
        icon: report,
        list:[    
                
     {
         name: 'Compras', 
         to: routes.report
      },
            
     
            ]
     },
     { 
        name: 'Perfil', 
        to: routes.profile, 
        icon: userIcon,
        list:[]
    },
     { 
        name: 'Cerrar sesión', 
        to: routes.logout, 
        icon: logout ,
        list:[]
     },
  
  ]