



export const total = ( products  )=>{

 

    for (let i = 0; i < products.length; i++) {

            if (products[i].confirmedQuantity === undefined ) {
             
                let reduce = products.reduce((acumulador, actual) => acumulador + actual.price * actual.requestedQuantity , 0);
               
            return reduce
            
            }else if (products[i].confirmedQuantity === null && products[i].deliveredQuantity === undefined) {
               
                let reduce = products[i].price * products[i].requestedQuantity
                return reduce.toFixed(2)
              } else if (products[i].confirmedQuantity === null &&  products[i].deliveredQuantity === null ) {
          
                let reduce = products.reduce((acumulador, actual) => acumulador + actual.price * actual.requestedQuantity , 0);
                return reduce.toFixed(2)
            }else if(products[i].confirmedQuantity != null && products[i].confirmedQuantity === products[i].requestedQuantity ) {
              
                let reduce = products.reduce((acumulador, actual) => acumulador + actual.price * actual.requestedQuantity , 0);
                return reduce.toFixed(2)
            }else if(products[i].confirmedQuantity === null &&  products[i].deliveredQuantity != products[i].requestedQuantity ) {
               
                let reduce = products.reduce((acumulador, actual) => acumulador + actual.price * actual.deliveredQuantity , 0);
                return reduce.toFixed(2)
            }else if (products[i].confirmedQuantity === null && products[i].deliveredQuantity === products[i].requestedQuantity) {
               

                let reduce = products.reduce((acumulador, actual) => acumulador + actual.price * actual.requestedQuantity , 0);

                return reduce

            }else if(products[i].deliveredQuantity != null && products[i].deliveredQuantity !== products[i].requestedQuantity && products[i].deliveredQuantity !== products[i].confirmedQuantity){

                let reduce = products.reduce((acumulador, actual) => acumulador + actual.price * actual.deliveredQuantity , 0);

                return reduce

            }else{
               
                let reduce = products.reduce((acumulador, actual) => acumulador + actual.price * actual.confirmedQuantity , 0);
                return reduce.toFixed(2)
            }
      
              
    }
  
  
}



export const calcularTotalOrden=(productos)=> {
    
    let total = 0;
    let subTotal = 0;
    let ivaTotal = 0;
    // Iterar sobre cada producto en el array
    productos.forEach(producto => {
        if (producto.confirmedQuantity === undefined ){
          
            const subtotal = producto.price * producto.requestedQuantity;
            const ivaPorProducto = subtotal * (producto.iva / 100);
            subTotal += subtotal
            ivaTotal += ivaPorProducto
            total += subtotal + ivaPorProducto;
           
        }else if (producto.confirmedQuantity === null && producto.deliveredQuantity === undefined){
           
            const subtotal = producto.price * producto.requestedQuantity;
            const ivaPorProducto = subtotal * (producto.iva / 100);
            subTotal += subtotal
            ivaTotal += ivaPorProducto
            total += subtotal + ivaPorProducto;
        }else if (producto.confirmedQuantity === null &&  producto.deliveredQuantity === null ){
           
            const subtotal = producto.price * producto.requestedQuantity;
            const ivaPorProducto = subtotal * (producto.iva / 100);
            subTotal += subtotal
            ivaTotal += ivaPorProducto
            total += subtotal + ivaPorProducto;
        }else if(producto.confirmedQuantity != null && producto.confirmedQuantity === producto.requestedQuantity ){
           
            const subtotal = producto.price * producto.requestedQuantity;
            const ivaPorProducto = subtotal * (producto.iva / 100);
            subTotal += subtotal
            ivaTotal += ivaPorProducto
            total += subtotal + ivaPorProducto;

        }else if(producto.confirmedQuantity === null &&  producto.deliveredQuantity != producto.requestedQuantity ){
           
            const subtotal = producto.price * producto.deliveredQuantity;
            const ivaPorProducto = subtotal * (producto.iva / 100);
            subTotal += subtotal
            ivaTotal += ivaPorProducto
            total += subtotal + ivaPorProducto;

        }else if (producto.confirmedQuantity === null && producto.deliveredQuantity === producto.requestedQuantity){

            const subtotal = producto.price * producto.requestedQuantity;
            const ivaPorProducto = subtotal * (producto.iva / 100);
            subTotal += subtotal
            ivaTotal += ivaPorProducto
            total += subtotal + ivaPorProducto;

        }else if(producto.deliveredQuantity != null && producto.deliveredQuantity !== producto.requestedQuantity && producto.deliveredQuantity !== producto.confirmedQuantity){
           
            const subtotal = producto.price * producto.deliveredQuantity;
            const ivaPorProducto = subtotal * (producto.iva / 100);
            subTotal += subtotal
            ivaTotal += ivaPorProducto
            total += subtotal + ivaPorProducto;


        }else{
           
            const subtotal = producto.price * producto.confirmedQuantity;
            const ivaPorProducto = subtotal * (producto.iva / 100);
            subTotal += subtotal
            ivaTotal += ivaPorProducto
            total += subtotal + ivaPorProducto;
        }
      // Calcular el subtotal por producto (precio * cantidad)
      
  
      // Calcular el monto de IVA por producto (subtotal * iva / 100)
    
  
      // Sumar el subtotal y el IVA al total
     
    });
   
    return  {
        subtotal:subTotal.toFixed(2),
        iva:ivaTotal.toFixed(2),
        total:total.toFixed(2)
    }  ;
  }