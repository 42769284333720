import React from 'react';
import { useLocation } from 'react-router-dom';
import { shouldHideNavbar } from 'components/Sidebar/helpers.js';
import styles from './banner.module.scss';


const BannerAnnouncement = ({ note }) => {
    const { pathname } = useLocation();

    if (shouldHideNavbar(pathname)) return null;


    return (
        <div className={styles.bannerA}>
            <p className={styles.text}>
                {note}
            </p>
        </div>
    );
};

export default BannerAnnouncement;
