import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getOrderById } from 'api/orders';
import PropTypes from 'prop-types';

import Loading from 'components/Loading';
import { toast } from 'react-hot-toast';

import { getDisplayDate } from 'utils/dates';

import { getArrayOfErrors } from 'utils/errors';

import CTA from 'components/CTA';

import styles from './confirmation.module.scss';

import useStore from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { HomeLayouts } from '../../../../components/layouts/HomeLayouts';
import { clearParamsO } from '../../../../redux/slices/paramsSlice';


const Confirmation = ({ state, resetProcess }) => {
  const { restaurantList, restaurant, user, token } = useSelector((state) => state.auth)
  const { orders_allowed } = useSelector((state) => state.orderReducer)
  const { stepO, stateO } = useSelector((state) => state.orderReducer)
  const dispatch = useDispatch()
  //const user = useStore((state) => state.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);


  const redirecc = () => {

    navigate('/ordenes/nueva')
    resetProcess();

  }

  useEffect(async () => {
    try {

      const order = await getOrderById(user.id, parseInt(restaurant), state.orderId === undefined ? state.id : state.orderId, token);

      setOrder(order);
    } catch (error) {
      if (error.response) {
        const errors = getArrayOfErrors(error.response.data);
        for (let i = 0; i < errors.length; i++) {
          toast.error(errors[i]);
        }
      } else {
        toast.error('Error interno. Inténtelo más tarde');
      }
    } finally {
      setLoading(false);
    }
  }, []);

  if (loading) return <HomeLayouts><Loading /></HomeLayouts>;
  if (!order) return null;

  const details = [
    {
      label: 'Proveedor: ',
      value: order.supplier.name,
    },
    {
      label: 'Vendedor: ',
      value: order.supplierUser.name,
    },
    {
      label: 'Fecha de entrega estimada: ',
      value: getDisplayDate(order.scheduleDate),
    },
  ];

  return (

    <HomeLayouts>
      <div className={styles.content}>
        <div className={styles.mainContainer}>

          {
            order.status === 'revisión' ?
              <>
                <h1 className={styles.title}>¡Orden Pendiente!</h1>
                <h2 className={styles.subtitle}>
                  Tu orden ha sido generada exitosamente. Tu aprobador recibirá una notificación
                  para revisar la orden y enviarla al proveedor.
                </h2>
              </>
              :
              <>
                <h1 className={styles.title}>¡Orden Enviada!</h1>
                <h2 className={styles.subtitle}>
                  Tu orden se ha enviado exitosamente. Recibirás una notificación en cuanto tu
                  proveedor acepte la solicitud.
                </h2>
              </>
          }
          <div className={styles.detailsContainer}>
            <h3 className={styles.orderNumber}>Orden # {order.code}</h3>
            {details.map((detail) => (
              <div className={styles.detail} key={detail.label}>
                <span className={styles.label}>{detail.label}</span>
                <span className={styles.value}>{detail.value}</span>
              </div>
            ))}
            <span
              className={styles.span}
              onClick={() => {
                navigate(
                  `/ordenes/detalle/${order.code}/${restaurant}`,
                );
              }}
            >
              Ver Detalle
            </span>
          </div>
        </div>
        <div className={styles.ctaContainer}>
          {user.roles[0].name !== 'Staff' && (
            <CTA
              text="Nueva Orden"
              isDisabled={orders_allowed?.status === true && orders_allowed.number <= 0}
              onClick={redirecc}
            />
          )}
          <CTA
            text="Ir a Mis Órdenes"
            isDisabled={false}
            onClick={() => {
              dispatch(clearParamsO())
              navigate('/ordenes');
              window.localStorage.setItem('state', JSON.stringify({}));
              window.localStorage.setItem('step', 1);
            }}
            color="secondary"
          />
        </div>
      </div>
    </HomeLayouts>

  );
};

Confirmation.propTypes = {
  state: PropTypes.object.isRequired,
  resetProcess: PropTypes.func.isRequired,
};

export default Confirmation;
