import axios from "axios";


// export const getInvoices = async ( params) => {

//     const { page = 1, limit = 5, restaurant, supplier='',product=''  } = params;
  
//     const response = await axios.get(`/invoices/`);
//     return response.data;
//   };

  export const getInvoices = async (data, token) => {
    const response = await axios.post(
      `${process.env.API_URL}/invoices/finds/documents`,
      data,
      {
        withCredentials:true,
       headers: {
           'Authorization': `Bearer ${token}`,
           'Accept': '*/*',
           'content-type': 'application/json'
           
       }
     }
    );
 
    return response.data;
  };