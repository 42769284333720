

import React, { useEffect, useState } from 'react'
import { HomeLayouts } from '../../components/layouts/HomeLayouts'
import { Box, Grid, Typography } from '@mui/material'

import { getSpendCategory, getSpendSupplier, getSpendbyProduct } from '../../api/chart'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { formatNumber } from '../../utils/numbers'
import { PieChart } from '../../components/PieChart/PieChart'




export const ChartS = () => {
  const { user, restaurant, subscription, token } = useSelector((state) => state.auth)
  const [spends, setspends] = useState(null)
  const [labelsL, setlabelsL] = useState(null)
  const [series, setSeries] = useState(null)
  const [labelsCat, setlabelsCat] = useState(null)
  const [seriesCat, setSeriesCat] = useState(null)
  const [labelsProd, setlabelsProduct] = useState(null)
  const [seriesProduct, setSeriesProduct] = useState(null)
  const [totalProvL, settotalProvL] = useState(null)
  const [totalCat, settotalCat] = useState(null)
  
 

  useEffect(() => {
   getData()
   SpendCategory()
   spendProduct()
  }, [restaurant])
  


const getData = async ()=>{

  const resp = await getSpendSupplier( restaurant, moment().format('MM'), token )

    if (resp.information.length < 1 ) {
          setlabelsL(null)
          setSeries(null)
    }else{
      settotalProvL(parseFloat(formatNumber(resp.total)) )
      const lab = resp.information.map((la)=>{
        return la.name
    })
    setlabelsL(lab)
    const ser = resp.information.map((ser)=>{
      return   parseFloat(formatNumber(ser.totalprov))      
  })
  setSeries(ser)

    }
 
}

const SpendCategory = async ()=>{

  const resp = await getSpendCategory( restaurant, moment().format('MM'), token )

    if (resp.length < 1 ) {
          setlabelsCat(null)
          setSeriesCat(null)
    }else{
      settotalCat(parseFloat(formatNumber(resp.total)) )
      const lab = resp.information.map((la)=>{
        return la.name
    })
    setlabelsCat(lab)
    const ser = resp.information.map((ser)=>{
      return   parseFloat(formatNumber(ser.sum))      
  })
  setSeriesCat(ser)
 
    }
 
}






const spendProduct = async ()=>{

   const data = await getSpendbyProduct(restaurant ,moment().format('MM'), token ) 

   if (data.length < 1 ) {
   


    //setlabelsProduct(null)
   // setSeriesProduct(null)
}else{
  const groupedData = data.reduce((acc, item) => {
    const { name, ...rest } = item;
    acc[name] = acc[name] || [];
    acc[name].push(rest);
    return acc;
  }, {});
  const groupedArray = Object.keys(groupedData).map((name) => ({
    name,
    data: groupedData[name],
  }));

  
  const labe = groupedArray.map((product)=>{
    return product.name
  })
  setlabelsProduct(labe)

  const ser = groupedArray.map((pro)=>{
    return pro.data
  })
 
//setSeriesProduct(ser)
}
}

 




 

  return (
    <HomeLayouts>
            <Box
                sx={{ paddingX:{md:18, xs:2} }}
                paddingY={5}
               
            >
                <Typography fontWeight={'bold'} fontSize={28} >Analítica</Typography>
            </Box>
            <Box
                sx={{ paddingX:{md:18, xs:2} }}            
               
            >
               

              <Grid
                container
                spacing={1}

              >
                 {
                  
                 labelsL && series ? 
                <Grid
                 
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <Box
                  border={0.3} borderRadius={3} borderColor='#EEEEEE'
                  padding={1}
                  >
                    <Box
                      
                      width={'100%'}
                                           
                      component={'div'}
                      
                    >
                  
                    <PieChart 
                      subtitle={`Total: $${totalProvL}`}
                      labels={labelsL}
                      series={series}
                      title={`Gastos del mes por Proveedor`}
                    />
                        
                   </Box>
               </Box>
                </Grid> : null
                  }
                {
                  labelsCat && seriesCat ? 
                  <Grid
                 
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <Box
                  border={0.3} borderRadius={3} borderColor='#EEEEEE'
                  padding={1}
                  >
                    <Box
                      
                      width={'100%'}
                     
                      component={'div'}
                      
                    >
                  <PieChart 
                    subtitle={`Total: $${totalCat}`}
                      labels={labelsCat}
                      series={seriesCat}
                      title={`Gastos del mes por Categoria`}    
                    />
       
 
                    </Box>
                          </Box>
                </Grid> : null
                }
                 {
                  labelsProd && seriesProduct ? 
                  <Grid
                 
                  item
                  xs={12}
                  sm={6}
                  md={6}
                >
                  <Box
                  border={0.3} borderRadius={3} borderColor='#EEEEEE'
                  padding={1}
                  >
                    <Box
                      
                      width={'100%'}
                     
                      component={'div'}
                      
                    >
                  <PieChart 
                      labels={labelsProd}
                      series={seriesProduct}
                      title='Gastos del mes por Producto'
                    />
       
 
                    </Box>
                          </Box>
                </Grid> : null
                }
               
              </Grid>
            </Box>

    </HomeLayouts>
  )
}
