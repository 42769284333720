import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styles from './pop-up.module.scss';

const PopUpP = ({ showModal, setShowModal, size, children }) => {
    const sizeStyles = {
        small: styles.smallSize,
        medium: styles.mediumSize,
        large: styles.largeSize,
    };

    const ref = useRef();

    const handleOverlayClick = (e) => {
        if (ref.current === e.target) {
            setShowModal(false);
            localStorage.removeItem('step');
            localStorage.removeItem('nameRestaurant');
            localStorage.removeItem('rucRestaurant');
            localStorage.removeItem('aliasRestaurant');
            localStorage.removeItem('nameUser');
            localStorage.removeItem('wspUser');
            localStorage.removeItem('emailUser');
        }
    };

    // Event listeners
    const keyPress = useCallback(
        (e) => {
            if (e.key === 'Escape' && showModal) {
                setShowModal(false);
            }
        },
        [showModal, setShowModal],
    );

    useEffect(() => {
        document.addEventListener('keydown', keyPress);
        return () => document.removeEventListener('keydown', keyPress);
    }, [keyPress]);

    // Render
    if (!showModal) return null;

    return (
        <div className={styles.overlay} onClick={handleOverlayClick} ref={ref}>
            <div className={`${styles.wrapper} ${sizeStyles[size] || sizeStyles.medium}`}>
                <div>{children}</div>
            </div>
        </div>
    );
};

PopUpP.propTypes = {
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    size: PropTypes.string,
};

export default PopUpP;
