import React from 'react';
import PropTypes from 'prop-types';

import plusIcon from 'assets/plus.svg';
import minusIcon from 'assets/minus.svg';

import { sumAndSubstract } from 'utils/numbers';

import styles from './quantityModifier.module.scss';
import { useDispatch } from 'react-redux';


const QuantityModifier = ({
  product,
  state,
  setState,
  quantityKey,
  shouldRemoveEmpty = false,
}) => {
  const handleKeyPress = (e) => {
    const regex = new RegExp(/^\d*\.?\d*$/);
    if (!regex.test(e.key) && e.key !== ',') {
      e.preventDefault();
    } else if (e.target.value.includes('.') && (e.key === '.' || e.key === ',')) {
      e.preventDefault();
    } else if (e.target.value.includes(',') && (e.key === ',' || e.key === '.')) {
      e.preventDefault();
    }
  };



  const dispatch = useDispatch()

  const modifyProducts = (id, quantity, product) => {




    id = Number(id);

    const copyArray = [...state.products];

    if (quantity === '') {
      copyArray.find((product) => product.id === id)[quantityKey] = '';
    }

    if (copyArray.find((product) => product.id === id && quantity)) {

      copyArray.find((product) => product.id === id)[quantityKey] = quantity;

    } else {
      if (quantity !== null && quantity > 0 && quantity !== '0' && quantity != '0.0') {

        const newProduct = { ...product, [quantityKey]: quantity };
        copyArray.push(newProduct);


      }
    }


    setState({ ...state, products: copyArray });
    const newProduct = { ...product, ['requestedQuantity']: quantity }



  };

  const handleBlur = (id, quantity, product) => {

    id = Number(id);

    const copyArray = [...state.products];

    // if (quantity.includes(',')) {

    //   const uu = quantity.replace(',', '.')
    //   const newProduct = { ...product, ['requestedQuantity']: uu }

    // }

    if (copyArray.find((product) => product.id === id)) {

      if (quantity === '' || quantity === 0 || quantity === '0' || quantity === '0.0' || quantity === '.') {

        copyArray.find((product) => product.id === id)[quantityKey] = 0;
        setState({ ...state, products: copyArray });
        const productsFil = state.products.filter((product) => {
          return product.requestedQuantity !== 0 && product.requestedQuantity !== '0.0' && product.requestedQuantity !== '0'
        })
        setState({ ...state, products: productsFil });

      }
      if (quantity === null || quantity <= 0 || quantity === '.' || quantity === ',') {

        if (shouldRemoveEmpty) {
          copyArray.splice(
            copyArray.findIndex((product) => product.id === id),
            1,
          );
          setState({ ...state, products: copyArray });

        }
      } else if (quantity.endsWith('.')) {
        copyArray.find((product) => product.id === id)[quantityKey] = Number(quantity);
        setState({ ...state, products: copyArray });

      } else if (quantity.includes(',') || quantity.startsWith('.') || quantity === '.') {
        copyArray.find((product) => product.id === id)[quantityKey] = Number(
          quantity.replace(',', '.'),
        );
        setState({ ...state, products: copyArray });

      }
    }
  };

  const modifyAmount = (operation, id, product) => {

    const input = document.querySelector(`input[name="${id}"]`);
    input.value = input.value.replace(/,/g, '.');
    let parsedValue = Number(input.value);

    if (operation === 'plus') {
      input.value = sumAndSubstract(parsedValue + 1);
      modifyProducts(id, sumAndSubstract(parsedValue + 1), product);
    }
    if (operation === 'minus') {
      if (parsedValue > 1) {
        input.value = sumAndSubstract(parsedValue - 1);
        modifyProducts(id, sumAndSubstract(parsedValue - 1), product);
      } else {
        input.value = '';
        handleBlur(id, 0);
      }
    }
  };

  const getInitialInputValue = (id) => {
    for (let i = 0; i < state.products.length; i++) {
      if (state.products[i].id === id && state.products[i].requestedQuantity !== 0 && state.products[i].requestedQuantity !== '0.0') {
        return state.products[i][quantityKey];
      }
    }
    return '';
  };

  const isProductInCart = (id) => {

    for (let i = 0; i < state.products.length; i++) {
      if (state.products[i].id === id && state.products[i].requestedQuantity !== 0 && state.products[i].requestedQuantity !== '0.0') {
        return true;
      }
    }
    return false;
  };

  return (
    <div className={styles.quantityContainer}>
      <div className={styles.inputContainer}>
        {isProductInCart(product.id) && (
          <img
            src={minusIcon}
            alt="minusLogo"
            className={`${styles.icon} ${styles.minus}`}
            onClick={() => {
              modifyAmount('minus', product.id, product);
            }}
          />
        )}

        <input
          className={styles.input}
          name={product.id}
          type="text"
          inputMode="decimal"
          value={getInitialInputValue(product.id)}
          onChange={(e) => modifyProducts(e.target.name, e.target.value, product)}
          onBlur={(e) =>
            setTimeout(() => {
              handleBlur(e.target.name, e.target.value, product);
            }, 250)
          }
          onKeyPress={(e) => handleKeyPress(e)}
          disabled={!isProductInCart(product.id)}
        />
        {isProductInCart(product.id) && <span>{product.measurementUnit}</span>}
      </div>
      <img
        src={plusIcon}
        alt="plusLogo"
        className={styles.icon}
        onClick={() => {
          modifyAmount('plus', product.id, product);
        }}
      />
    </div>
  );
};

QuantityModifier.propTypes = {
  product: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  quantityKey: PropTypes.string.isRequired,
  shouldRemoveEmpty: PropTypes.bool,
};

export default QuantityModifier;