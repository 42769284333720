

import { createSlice } from "@reduxjs/toolkit";
import { products } from "../../../views/Marketplace/data";




const initialState = {

    productsCar:[],
    
   
} 



 export const  marketSlice = createSlice({

    name:'productsCar',
    initialState:initialState,
    reducers:{
        
        addProductCar:(state, action)=>{           
            if (state.productsCar.length === 0 || state.productsCar.filter((item)=>item.id === action.payload.id).length === 0 ) {
                state.productsCar.push(action.payload)
            }
        },
        removeProductCar:(state, action)=>{    
            
       

            if ( state.productsCar.some((item)=> item.id === action.payload.id) ) {     



                const pp = state.productsCar.filter((item)=> item.id !== action.payload.id)

                state.productsCar = pp 

              //  return state.productsCar = state.productsCar.filter((item)=> item.id !== action.payload)
               
            }
        },
        addCant: (state , action) => {
   

            state.productsCar.map((item)=>{
    
                if(item.id === action.payload.id){
    
                    item.cantidad = action.payload.cantidad         
    
                }           
                 
            })
    
        },
    }
})

export const { addProductCar, removeProductCar, addCant } = marketSlice.actions


