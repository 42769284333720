//'https://api-stg.cado.ai/quotes/user/{userId}/restaurant/{restaurantId}?userId=129&restaurantId=47'

import axios from "axios";



export const quoteByRestaurant = async (token, userId, restaurantId, status='Solicitada', limit=10, page=1  )=>{

    

    const response = await axios.get( `${process.env.API_URL}/quotes/user/${userId}/restaurant/${restaurantId}?status=${status}&limit=${limit}&page=${page}`,
    {
     withCredentials:true,
    headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'
        
    }
      });

      if (response.status === 200) {
            return { ok:true,  data: response.data}
      }else{
        return { ok:false,  data:[] }
      }

  }


  //https://api-stg.cado.ai/quotes/5

  export const quoteForId = async (token, id )=>{

    

    const response = await axios.get( `${process.env.API_URL}/quotes/${id}`,
    {
     withCredentials:true,
    headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'
        
    }
      });

      if (response.status === 200) {
            return { ok:true,  data: response.data}
      }else{
        return { ok:false,  data:[] }
      }

  }

  export const quoteUpdate = async (token, id, data) => {


    console.log(data)
      const response = await axios.put(`${process.env.API_URL}/quotes/${id}/changeStatus`,
       data,
        {
          withCredentials: true,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Accept': '*/*',
            'content-type': 'application/json'
    
          }
        });
    
      if (response.status === 200) {
        return { ok: true, data: response.data }
      } else {
        return { ok: false, data: [] }
      }
    
    }