
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import React from 'react'
import { formatNumber } from '../../../utils/numbers'
import CheckIcon from '@mui/icons-material/Check';

export const TableHistoyMobil = ( { history, unidad, priceRef }) => {
  

    return (
     
        <Grid 
           sx={{ display:{ xs:'flex', md:'none'  }}}
           mt={1}
           container
           border={1}
           borderColor={'#E9E7E2'}
           borderRadius={2}
           >
                <Box  width='100%' >
           
                    {
                          history.length  && history.map((history, index )=>{

                                 const fec = new Date(history.dateAuthorization).toLocaleDateString()

                                 return (

                                    <Grid 
                                    key={index}
                                    border={1}
                                    borderColor={'#E9E7E2'}                                                   
                                    padding={1}
                                    container 
                                    display='flex'
                                    alignItems='center'
                                    flexDirection='row'
                                     justifyContent='center'
                                      
                                      
                                       >
                                           <Grid  item md={4}  xs={12}  >
                                                <Typography fontSize={15} fontWeight='bold' >{ history.name }</Typography>
                                           </Grid>
                                  
                                       <Grid  item padding={1} md={3}  xs={3}  >
                                           <Typography fontSize={14} noWrap   fontWeight='bold' >Documento:</Typography>  
                                           <Typography fontSize={13} noWrap   color='gray' >{fec} </Typography>
                                       </Grid>
                                       <Grid  item padding={1} md={3}  xs={3}  >
                                         <Typography fontSize={14} noWrap   fontWeight='bold' >Cantidad:</Typography>  
                                         <Typography fontSize={14}  >{ `${history.quantity} ${unidad } ` }</Typography> 
                                       </Grid>
                                       <Grid  item padding={1} md={3}  xs={3}  >
                                           <Typography fontSize={14} noWrap   fontWeight='bold' >Ultimo precio:</Typography>  
                                           <Typography fontSize={14} padding={0.5} borderRadius={1} width='55%' bgcolor={formatNumber(history.unitPrice) > formatNumber(priceRef) ? '#FAE3DE' :   '#DEECDC'  } >${ formatNumber(history.unitPrice)  }</Typography>
                                       </Grid>
                                       <Grid item padding={1} md={3} xs={3}>
                                       <Typography fontSize={14} noWrap   fontWeight='bold' >Descuento:</Typography>  
                                        {
                                                history.descuento ? 

                                            <CheckIcon sx={{ color : '#a2ce9c' }} /> : '-'
                                        }
                                        
                                    </Grid>
                                       <Grid padding={1}  item md={4}  xs={12} display='flex' flexDirection='row'  >
                                                <Typography fontSize={15} fontWeight='bold' >Total:</Typography>
                                                <Typography ml={0.5} fontSize={15} fontWeight='bold' >${  formatNumber(history.total)}</Typography>
                                           </Grid>
                                      
                                   </Grid>

                                 )

                          } )

                    }
                  
                </Box>
        </Grid>
    
    
      )


}
