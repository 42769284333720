import { Box, Collapse, List } from '@mui/material';
import React from 'react'
import styles from './sidebar.module.scss';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export const LinkMenu = ({ icon, name, links, restaurant }) => {

    const { pathname } = useLocation();

    const isCurrentPath = (route) => {
        if (route === '/') {
            return route === pathname;
        } else {
            return pathname.startsWith(route);
        }
    };

    const [isOpen, setIsOpen] = useState(false);

    const handleIsOpen = () => {
        setIsOpen((prev) => !prev);
    };



    useEffect(() => {

        setIsOpen(false)

    }, [restaurant])



    return (

        <List>
            <Link
                onClick={handleIsOpen}
                to={''}
                className={`${styles.link}`}
            >
                <img src={icon} className={styles.icons} />
                {name}
            </Link>
            {
                links.map((v, i) => (

                    <Collapse
                        key={i}
                        sx={{

                            height: 0,
                            margin: 0,
                            padding: 0,

                        }}
                        in={isOpen}>
                        <Box
                            display='flex'
                        >

                            <Box ml={2} display={'flex'} alignItems={'center'}  >
                                <Box
                                    ml={4}
                                    mr={1}
                                    borderRadius={50}
                                    bgcolor={'white'}
                                    height={6}
                                    width={6}
                                    mt={-1.2}>
                                </Box>
                                <Box
                                    width={190}
                                >
                                    <Link
                                        to={v.to}
                                        key={`${i}_${v.to}`}
                                        className={`${styles.link} ${isCurrentPath(v.to) ? styles.currentLink : ''
                                            }`}
                                    >
                                        {v.name}
                                    </Link>
                                </Box>

                            </Box>
                        </Box>
                    </Collapse>

                ))
            }
        </List>

    )
}
