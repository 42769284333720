/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';
import useStore from 'store';
import { getArrayOfErrors } from 'utils/errors';

import commonStyles from '../../../../pop-up.module.scss';
import styles from './restaurantList.module.scss';
import { useSelector } from 'react-redux';


const RestaurantList = ({ local, changeStep }) => {

    const { user } = useSelector((state) => state.auth)





    return (
        <div className={styles.contentLocal}>
            <h1 className={commonStyles.title}>Locales</h1>

            {
                user?.roles[0].name === 'Administrator' ? <></>
                    :
                    <button
                        className={styles.addRestaurantButton}
                        onClick={() => {
                            changeStep('next');
                        }}
                    >
                        Nuevo local
                    </button>
            }


            <h2 className={styles.sectionTitle}>Locales guardados</h2>
            {local.length > 0 &&
                local.map((val, i) => {
                    return (
                        <div key={i} className={styles.cardLocal}>
                            <div className={styles.cardTitleContent}>
                                <div className={styles.cardTitleLocal}>{val.name}</div>
                            </div>
                            <div>{`${val.address}`}</div>
                            <div>{`${val.city}, ${val.state}`}</div>
                        </div>
                    );
                })}
        </div>
    );
};

RestaurantList.propTypes = {
    local: PropTypes.array,
    changeStep: PropTypes.func.isRequired,
    setState: PropTypes.func.isRequired,
};

export default RestaurantList;
