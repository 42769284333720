/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';

import styles from './restaurantForm.module.scss';
import CTA from '../../../../../CTA';

const RestaurantForm = ({ changeStep, setRestaurantInfo }) => {
    const [name, setName] = useState(null);
    const [ruc, setRuc] = useState(null);
    const [alias, setAlias] = useState(null);

    useEffect(() => {
        const nameStorage = localStorage.getItem('nameRestaurant');
        const rucStorage = localStorage.getItem('rucRestaurant');
        const aliasStorage = localStorage.getItem('aliasRestaurant');

        if (nameStorage) {
            setName(nameStorage);
        }
        if (rucStorage) {
            setRuc(rucStorage);
        }
        if (aliasStorage) {
            setAlias(aliasStorage);
        }
    }, [])

    return (
        <div className={styles.contentLocal}>
            <div className={styles.header}>

                <h1 className={styles.title}>Nuevo local</h1>
            </div>
            <div className={styles.section} style={{ marginBottom: '30px' }}>
                <input
                    placeholder="Razón Social *"
                    className={`${styles.input}`}
                    onChange={(e) => {
                        setName(e.target.value)
                        localStorage.setItem('nameRestaurant', e.target.value);
                    }}
                    value={name || ''}
                />
                <input
                    placeholder="RUC *"
                    className={`${styles.input}`}
                    onChange={(e) => {
                        setRuc(e.target.value)
                        localStorage.setItem('rucRestaurant', e.target.value)
                    }}
                    value={ruc || ''}
                    maxLength={13}
                    minLength={13}
                    pattern="\d{13}"
                />
                <input
                    placeholder="Alias (ej., Café centro de ciudad)(opcional)"
                    className={`${styles.input}`}
                    onChange={(e) => {
                        setAlias(e.target.value)
                        localStorage.setItem('aliasRestaurant', e.target.value)
                    }}
                    value={alias || ''}
                />
            </div>
            <CTA text="Continuar" onClick={() => {
                changeStep('next');
                setRestaurantInfo({ name, ruc, alias })
            }} isDisabled={!name || !ruc || ruc.length < 13} />
        </div>
    );
};

RestaurantForm.propTypes = {
    changeStep: PropTypes.func.isRequired,
    setState: PropTypes.func.isRequired,
    setRestaurantInfo: PropTypes.func.isRequired,
};

export default RestaurantForm;
