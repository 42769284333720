import React from 'react';
import logo from 'assets/logo_name.png';
import styles from './auth-logo.module.scss';

const AuthLogo = () => {
  return (
    <div className={styles.container}>
      <img src={logo} alt="cado logo" className={styles.logo} />
    </div>
  );
};

export default AuthLogo;
