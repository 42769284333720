/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';

import PhoneInput from 'react-phone-number-input';
import styles from './userForm.module.scss';
import CTA from '../../../../../CTA';

const UserForm = ({ changeStep, setUserInfo }) => {
    const [name, setName] = useState(null);
    const [wsp, setWsp] = useState(null);
    const [email, setEmail] = useState(null);

    useEffect(() => {
        const nameStorage = localStorage.getItem('nameUser');
        const wspStorage = localStorage.getItem('wspUser');
        const emailStorage = localStorage.getItem('emailUser');

        if (nameStorage) {
            setName(nameStorage);
        }
        if (wspStorage) {
            setWsp(wspStorage);
        }
        if (emailStorage) {
            setEmail(emailStorage);
        }
    }, []);

    return (
        <div className={styles.contentLocal}>
            <div className={styles.header}>
                {/* <Back className={styles.back} onClick={() => changeStep('prev')} /> */}
                <h1 className={styles.title}>Agregar Usuario</h1>
            </div>
            <div className={styles.section} style={{ marginBottom: '30px' }}>
                <input
                    placeholder="Nombre *"
                    className={`${styles.input}`}
                    onChange={(e) => {
                        setName(e.target.value);
                        localStorage.setItem('nameUser', e.target.value);
                    }}
                    value={name || ''}
                />
                <div
                    className={styles.individualInputContainer}
                >
                    <PhoneInput
                        type="text"
                        value={wsp || ''}
                        name="phone"
                        placeholder="Teléfono"
                        onChange={(e) => {
                            setWsp(e)
                            localStorage.setItem('wspUser', e);
                        }}
                        className={styles.input}
                        required
                        style={{ padding: '16px 16px', height: '54px' }}
                        defaultCountry="EC"
                        autoComplete="tel"
                        autoCorrect="off"
                    />
                </div>
                <input
                    placeholder="Correo Electrónico (opcional)"
                    className={`${styles.input}`}
                    onChange={(e) => {
                        setEmail(e.target.value)
                        localStorage.setItem('emailUser', e.target.value);
                    }}
                    value={email || ''}
                />
            </div>
            <CTA text="Continuar" onClick={() => {
                changeStep('next');
                setUserInfo({ name, wsp, email })
            }} isDisabled={!name || !wsp} />
        </div>
    );
};

UserForm.propTypes = {
    changeStep: PropTypes.func.isRequired,
    setState: PropTypes.func.isRequired,
    setUserInfo: PropTypes.func.isRequired,
};

export default UserForm;
