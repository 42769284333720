import moment from "moment";
import { number } from "prop-types";


const monthNames = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
export const month = moment().format('MM')
export const monthF = moment().format('MMMM')
export const year = moment().format('YYYY') 

function getMonthName(month) {
    
    return monthNames[month];
  }

  function padZero(num) {
    return num.toString().padStart(2, '0');
  }

export const years = ( )=>{
    
    const years = [] 

    for (let i = 2020; i <= year; i++) {
        years.push(i ); // Agregar cada año a la matriz
      }



    return  years
}

export const monthsList = ( yearl )=>{
   
   

    const currentMonth = new Date().getMonth(); // Obtener el mes actual
    const months = []; // Crear un objeto vacío para almacenar los meses

    if (yearl != year) {
      for (let month = 0; month <= monthNames.length; month++) {
      
        months.push({
            value: padZero(month + 1), // Agregar el valor del mes como un número con ceros a la izquierda
            name: getMonthName(month) // Agregar el nombre del mes como una cadena
          });
    }
     return months
    } else {
      for (let month = 0; month <= currentMonth; month++) {
      
        months.push({
            value: padZero(month + 1), // Agregar el valor del mes como un número con ceros a la izquierda
            name: getMonthName(month) // Agregar el nombre del mes como una cadena
          });
    }
          return  months
    }
      
    
}