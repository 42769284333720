import React from 'react';
import PropTypes from 'prop-types';
import { getDisplayDate } from 'utils/dates';
import './printComponent.scss';
import { useQRCode } from 'next-qrcode';
import { useSelector } from 'react-redux';
import { Divider } from '@mui/material';
// eslint-disable-next-line react/display-name
const PrintComponent = React.forwardRef(({ orders }, ref) => {
  const { user, restaurant } = useSelector((state) => state.auth)
  const { Canvas } = useQRCode();




  return (
    <div className="print-container" id="content-to-print" ref={ref}>
      <div>
        <div>
          {orders.map((day) => {
            return day.orders.map((order, i) => {
              const date = order.deliveryDate
                ? getDisplayDate(order.deliveryDate)
                : getDisplayDate(order.scheduleDate);
              if (order.status !== 'cancelada') {
                return (
                  <div key={`${i}_${order.id}`} className="print-entry">
                    <div className="print-entry-header">
                      <div className="print-header-info">
                        <span>
                          <label className="print-label">Fecha de entrega:</label> {date}
                        </span>
                        <span>
                          <label className="print-label">Proveedor: </label>
                          {order.supplier.name}
                        </span>
                        <span>
                          <label className="print-label">Código:</label> {order.code}
                        </span>
                      </div>
                      {/* <Canvas
                        text={`https://app.cado.ai/ordenes/detalle/${order.code
                          }/${restaurant}`}
                        options={{
                          type: 'image/jpeg',
                          quality: 0.8,
                          level: 'M',
                          margin: 1,
                          scale: 4,
                          width: 75,
                          color: {
                            dark: '#000000',
                            light: '#FFFFFF',
                          },
                        }}
                      /> */}
                    </div>
                    <div className="print-entry-body">
                      <table className="print-table">
                        <thead>
                          <tr>
                            <th className="print-column">Producto</th>
                            <th className="print-column">Medida</th>
                            <th className="print-column">Cantidad</th>
                          </tr>
                        </thead>
                        <tbody>
                          {order.products.map((product) => {
                            return (
                              <tr key={product.id}>
                                <td className="print-column">{product.name}</td>
                                <td className="print-column">
                                  {product.measurementUnit}
                                </td>
                                <td className="print-column">
                                  {product.deliveredQuantity
                                    ? product.deliveredQuantity
                                    : product.confirmedQuantity
                                      ? product.confirmedQuantity
                                      : product.requestedQuantity}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>



                  </div>
                );
              }
            });
          })}
        </div>
      </div>
    </div>
  );
});

PrintComponent.propTypes = {
  orders: PropTypes.array,
};

export default PrintComponent;
