import axios from "axios";

export const getSummary = async (params, token) => {
  const { from, to, offset, id_user, id_restaurant } = params;
       

  const response = await axios.get( `${process.env.API_URL}/orders/summary/users/${id_user}/restaurants/${id_restaurant}?fromDate=${from}&toDate=${to}&offset=${offset}`,
 
  {
   withCredentials:true,
  headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': '*/*',
      'content-type': 'application/json'
      
  }
});
return response.data;
}

export const getOrderById = async (id_user, idRestaurant, id, token) => {


  const response = await axios.get( `${process.env.API_URL}/orders/users/${id_user}/restaurants/${idRestaurant}/code/${id}`,
 
  {
   withCredentials:true,
  headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': '*/*',
      'content-type': 'application/json'
      
  }
});
  return response.data;

};

export const markOrderAsDelivered = async (idRestaurant, id, products, token, user_id ) => {
  const response = await axios.post(`${process.env.API_URL}/orders/restaurants/${idRestaurant}/order/${id}/users/${user_id}/delivery`, products,
  
  
  {
    withCredentials:true,
   headers: {
       'Authorization': `Bearer ${token}`,
       'Accept': '*/*',
       'content-type': 'application/json'
       
   }
 } 
  
  );
  return response.data;
};


export const getOrders = async (params, userId, token) => {
  const { page = 1, limit = 10, status, query = '', idRestaurant } = params;
  const { data } = await axios.get(
    `${process.env.API_URL}/orders/users/${userId}/restaurants/${idRestaurant}?limit=${limit}&page=${page}&query=${query}${
      status ? `&status=${status}` : ''}`,
    {
      withCredentials:true,
     headers: {
         'Authorization': `Bearer ${token}`,
         'Accept': '*/*',
         'content-type': 'application/json'
         
     }
   } 

  );
  
  return data;
};


export const cancelOrder = async (idRestaurant, id, token, user_id) => {
  const response = await axios.post(`${process.env.API_URL}/orders/restaurants/${idRestaurant}/order/${id}/cancelation/${user_id}`,{},
  
  {
    withCredentials:true,
   headers: {
       'Authorization': `Bearer ${token}`,
       'Accept': '*/*',
       'content-type': 'application/json'
       
   }
 } );
  return response.data;
};


export const getFavorites = async (restaurantId, supplierId, limit, page, query, token) => {
  const response = await axios.get(
    `${process.env.API_URL}/orders/restaurants/${restaurantId}/suppliers/${supplierId}/favorites?limit=${limit}&page=${page}&query=${query}`,
    {
      withCredentials:true,
     headers: {
         'Authorization': `Bearer ${token}`,
         'Accept': '*/*',
         'content-type': 'application/json'
         
     }
   }
  );
  return response.data;
};



export const createOrder = async (order, token, id_restaurant, id_user) => {
  const response = await axios.post(`${process.env.API_URL}/orders/restaurants/${id_restaurant}/user/${id_user} `,
  order,

   {
      withCredentials:true,
     headers: {
         'Authorization': `Bearer ${token}`,
         'Accept': '*/*',
         'content-type': 'application/json'
         
     }
   }
  
  );
  return response.data;
};


export const approvedO = async (idRestaurant, id, data, user_id, token) => {


  const response = await axios.post(`${process.env.API_URL}/orders/restaurants/${idRestaurant}/order/${id}/users/${user_id}/approved`, data,
  
  {
    withCredentials:true,
   headers: {
       'Authorization': `Bearer ${token}`,
       'Accept': '*/*',
       'content-type': 'application/json'
       
   }
 }
  
  );
  return response.data;
};










































// export const getSummary = async (params) => {
//   const { from, to, offset, id } = params;
//   const response = await axios.get(
//     `/orders/summary/${id}?fromDate=${from}&toDate=${to}&offset=${offset}`,
//   );
//   return response.data;
// };









